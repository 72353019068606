import React, { Component } from "react";
import { connect } from "react-redux";
import {
  checkDate,
  showTimes
} from "../landing_page/header/openingTime/DateFunctions";
import { FaHouseDamage, FaWalking, FaCarSide } from "react-icons/fa";
import { FiClock } from "react-icons/fi";

export class RestaurantInformation extends Component {
  state = {
    weekdayNomen: null,
    weekdayAdverb: null,
    opened: false,
    willOpen: false,
    hasOpened: false,
    isOpenOnAnotherDay: false
  };

  componentWillMount() {
    let dates = checkDate(this.props.restaurant.openingTime);
    this.setState((state, props) => {
      return {
        weekdayNomen: dates.weekdayNomen,
        weekdayAdverb: dates.weekdayAdverb,
        opened: dates.currOpen,
        willOpen: dates.isOpeningToday,
        hasOpened: dates.hasOpenedToday,
        isOpenOnAnotherDay: dates.isOpeningAnOtherDay
      };
    });
  }

  render() {
    let { restaurant } = this.props;
    return (
      <div className="restaurant-info">
        <div className="opening-time-part">
          <h4 className="opening-time-heading">
            <FiClock className="clock-icon" color="rgb(207,207,207)" />{" "}
            Küchenzeiten
          </h4>
          {showTimes(restaurant.openingTime).map((day, i) => {
            return (
              <p className="kitchen-times" key={i}>
                {day}
              </p>
            );
          })}
        </div>
        <div className="adress-part">
          <div className="service-information">
            {restaurant.service.indoor && (
              <FaHouseDamage color={"rgb(195,195,195)"} size={25} />
            )}
            {restaurant.service.driveIn && (
              <FaWalking color={"rgb(195,195,195)"} size={25} />
            )}
            {restaurant.service.delivery && (
              <FaCarSide color={"rgb(195,195,195)"} size={25} />
            )}
          </div>
          <p className="restaurant-cart-adress">{restaurant.phone}</p>
          <p className="restaurant-cart-adress">
            {restaurant.adress.street} {restaurant.adress.houseNr}
          </p>
          <p className="restaurant-cart-adress">
            {restaurant.adress.zip} {restaurant.adress.city}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  restaurant: state.restaurant
});

export default connect(mapStateToProps)(RestaurantInformation);
