import React, { Component } from "react";

import LogInScreen from "./LogInScreen";
import SignUpScreen from "./SignUpScreen";

export class VisibleMenuScreen extends Component {
  render() {
    const { visible, loggedIn } = this.props;
    return (
      <React.Fragment>
        {visible === "logIn" && (
          <LogInScreen visible={true} loggedIn={loggedIn} />
        )}
        {visible === "signUp" && <SignUpScreen visible={true} />}
      </React.Fragment>
    );
  }
}

export default VisibleMenuScreen;
