import { checkLength, findAvailableStatus } from "../components/helpfunctions";

export const setDishModal = toggleState => {
  return {
    type: "SET_MODAL",
    payload: toggleState
  };
};

export const setDish = dish => {
  return {
    type: "SET_DISH",
    payload: dish
  };
};

/**
 * change after click on a new topping: controls if the item is already in, the double-state, the doubleable-state and set the component-state toppings value to the new object
 * @param item the current item from ChangeTopping-Component
 * @param tops the choosen toppings depending on already changed toppings or standard-toppings from singleDish
 * @param singledish the current singledish from state
 */
export const editToppings = (item, singledish) => {
  let newToppings = [...singledish.choosenToppings],
    // the length as int from newToppings array
    fullLength = checkLength(newToppings);
  // check if the newToppings Array contains an object with the name of the new item
  let isInBool = newToppings.some(topping => topping.name === item.name);
  for (let i = 0; i < newToppings.length; i++) {
    // control if item is already in new topping array
    if (item.name === newToppings[i].name) {
      // delete the item which should be doubled in the dish
      newToppings.splice(newToppings.indexOf(newToppings[i]), 1);
      if (
        !item.doubled && // if the item isnt already doubled
        (!singledish.editable.howMuchMaxToppings || // and there is no add-limit
          singledish.editable.howMuchMaxToppings > fullLength) && // or the add-limit is higher than the current number of toppings
        findAvailableStatus(singledish, item) // and the item is potentially doubleable
      ) {
        // then set the double-status of the item to true
        newToppings.push({
          name: item.name,
          price: item.price,
          doubled: true
        });
      } else {
        // if item is doubled and the full length after above splicing min. = 1, then delete the doubled item. if it is the last item (fulllength = 1), make doubled false
        if (!(newToppings.length >= 1)) {
          newToppings.push({
            name: item.name,
            price: item.price,
            doubled: false
          });
        }
      }
    }
  }
  // if the item isn't in the list yet, add it (with doubled = false) after the check, if there is no limit for maxToppings || the actual length is smaller than the max length
  if (
    !isInBool &&
    (!singledish.editable.howMuchMaxToppings ||
      singledish.editable.howMuchMaxToppings > fullLength)
  ) {
    newToppings.push({
      name: item.name,
      price: item.price,
      doubled: false
    });
  }
  return {
    type: "EDIT_DISH",
    payload: newToppings
  };
};

export const resetToppings = singledish => {
  return {
    type: "RESET_TOPPINGS",
    payload: singledish
  };
};
