export const setLogSign = bool => {
  return {
    type: "SET_LOG_SIGN",
    payload: bool
  };
};

export const setTypeVal = str => {
  return {
    type: "SET_TYPE_VAL",
    payload: str
  };
};

export const setTimeVal = str => {
  return {
    type: "SET_TIME_VAL",
    payload: str
  };
};
