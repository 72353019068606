import React, { Component } from "react";
import Navbar from "../navigation/Navbar";

export class AGB extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="footer-texts">
          <h1>Allgemeine Gesch&auml;ftsbedingungen mit Kundeninformationen</h1>

          <h2>Inhaltsverzeichnis</h2>
          <ol className="footer-ol-list">
            <li>
              <a href="#geltungsbereich">Geltungsbereich</a>
            </li>
            <li>
              <a href="#vertragsschluss">Vertragsschluss</a>
            </li>
            <li>
              <a href="#widerrufsrecht">Widerrufsrecht</a>
            </li>
            <li>
              <a href="#preise">Preise und Zahlungsbedingungen</a>
            </li>
            <li>
              <a href="#liefer">Liefer- und Versandbedingungen</a>
            </li>
            <li>
              <a href="#eigentumsvorbehalt">Eigentumsvorbehalt</a>
            </li>
            <li>
              <a href="#mangelhaftung">
                M&auml;ngelhaftung (Gew&auml;hrleistung)
              </a>
            </li>
            <li>
              <a href="#anwendbares">Anwendbares Recht</a>
            </li>
            <li>
              <a href="#alternatives">Alternative Streitbeilegung</a>
            </li>
          </ol>

          <h2 id="geltungsbereich">1&#41; Geltungsbereich</h2>

          <p>
            <b>1.1</b>&thinsp;Diese Allgemeinen Gesch&auml;ftsbedingungen
            (nachfolgend &quot;AGB&quot;) des Janick Wagner (nachfolgend
            &quot;Verk&auml;ufer&quot;), gelten f&uuml;r alle Vertr&auml;ge
            &uuml;ber die Lieferung von Waren, die ein Verbraucher oder
            Unternehmer (nachfolgend &bdquo;Kunde&ldquo;) mit dem Verk&auml;ufer
            hinsichtlich der vom Verk&auml;ufer in seinem Online-Shop
            dargestellten Waren abschlie&szlig;t. Hiermit wird der Einbeziehung
            von eigenen Bedingungen des Kunden widersprochen, es sei denn, es
            ist etwas anderes vereinbart.
          </p>

          <p>
            <b>1.2</b>&thinsp;Verbraucher im Sinne dieser AGB ist jede
            nat&uuml;rliche Person, die ein Rechtsgesch&auml;ft zu Zwecken
            abschlie&szlig;t, die &uuml;berwiegend weder ihrer gewerblichen noch
            ihrer selbst&auml;ndigen beruflichen T&auml;tigkeit zugerechnet
            werden k&ouml;nnen. Unternehmer im Sinne dieser AGB ist eine
            nat&uuml;rliche oder juristische Person oder eine rechtsf&auml;hige
            Personengesellschaft, die bei Abschluss eines Rechtsgesch&auml;fts
            in Aus&uuml;bung ihrer gewerblichen oder selbst&auml;ndigen
            beruflichen T&auml;tigkeit handelt.
          </p>

          <h2 id="vertragsschluss">2&#41; Vertragsschluss</h2>

          <p>
            <b>2.1</b>&thinsp;Die im Online-Shop des Verk&auml;ufers enthaltenen
            Produktbeschreibungen stellen keine verbindlichen Angebote seitens
            des Verk&auml;ufers dar, sondern dienen zur Abgabe eines
            verbindlichen Angebots durch den Kunden.
          </p>

          <p>
            <b>2.2</b>&thinsp;Der Kunde kann das Angebot &uuml;ber das in den
            Online-Shop des Verk&auml;ufers integrierte Online-Bestellformular
            abgeben. Dabei gibt der Kunde, nachdem er die ausgew&auml;hlten
            Waren in den virtuellen Warenkorb gelegt und den elektronischen
            Bestellprozess durchlaufen hat, durch Klicken des den Bestellvorgang
            abschlie&szlig;enden Buttons ein rechtlich verbindliches
            Vertragsangebot in Bezug auf die im Warenkorb enthaltenen Waren ab.
          </p>

          <p>
            <b>2.3</b>&thinsp;Der Verk&auml;ufer kann das Angebot des Kunden
            innerhalb von f&uuml;nf Tagen annehmen,
          </p>

          <ul className="datstz-list">
            <li>
              indem er dem Kunden eine schriftliche Auftragsbest&auml;tigung
              oder eine Auftragsbest&auml;tigung in Textform (Fax oder E-Mail)
              &uuml;bermittelt, wobei insoweit der Zugang der
              Auftragsbest&auml;tigung beim Kunden ma&szlig;geblich ist, oder
            </li>
            <li>
              indem er dem Kunden die bestellte Ware liefert, wobei insoweit der
              Zugang der Ware beim Kunden ma&szlig;geblich ist, oder
            </li>
            <li>
              indem er den Kunden nach Abgabe von dessen Bestellung zur Zahlung
              auffordert.
            </li>
          </ul>

          <p>
            Liegen mehrere der vorgenannten Alternativen vor, kommt der Vertrag
            in dem Zeitpunkt zustande, in dem eine der vorgenannten Alternativen
            zuerst eintritt. Die Frist zur Annahme des Angebots beginnt am Tag
            nach der Absendung des Angebots durch den Kunden zu laufen und endet
            mit dem Ablauf des f&uuml;nften Tages, welcher auf die Absendung des
            Angebots folgt. Nimmt der Verk&auml;ufer das Angebot des Kunden
            innerhalb vorgenannter Frist nicht an, so gilt dies als Ablehnung
            des Angebots mit der Folge, dass der Kunde nicht mehr an seine
            Willenserkl&auml;rung gebunden ist.
          </p>

          <p>
            <b>2.4</b>&thinsp;Bei der Abgabe eines Angebots &uuml;ber das
            Online-Bestellformular des Verk&auml;ufers wird der Vertragstext
            nach dem Vertragsschluss vom Verk&auml;ufer gespeichert und dem
            Kunden nach Absendung von dessen Bestellung in Textform (z. B.
            E-Mail, Fax oder Brief) &uuml;bermittelt. Eine dar&uuml;ber
            hinausgehende Zug&auml;nglichmachung des Vertragstextes durch den
            Verk&auml;ufer erfolgt nicht. Sofern der Kunde vor Absendung seiner
            Bestellung ein Nutzerkonto im Online-Shop des Verk&auml;ufers
            eingerichtet hat, werden die Bestelldaten auf der Website des
            Verk&auml;ufers archiviert und k&ouml;nnen vom Kunden &uuml;ber
            dessen passwortgesch&uuml;tztes Nutzerkonto unter Angabe der
            entsprechenden Login-Daten kostenlos abgerufen werden.
          </p>

          <p>
            <b>2.5</b>&thinsp;Vor verbindlicher Abgabe der Bestellung &uuml;ber
            das Online-Bestellformular des Verk&auml;ufers kann der Kunde
            m&ouml;gliche Eingabefehler durch aufmerksames Lesen der auf dem
            Bildschirm dargestellten Informationen erkennen. Ein wirksames
            technisches Mittel zur besseren Erkennung von Eingabefehlern kann
            dabei die Vergr&ouml;&szlig;erungsfunktion des Browsers sein, mit
            deren Hilfe die Darstellung auf dem Bildschirm vergr&ouml;&szlig;ert
            wird. Seine Eingaben kann der Kunde im Rahmen des elektronischen
            Bestellprozesses so lange &uuml;ber die &uuml;blichen Tastatur- und
            Mausfunktionen korrigieren, bis er den den Bestellvorgang
            abschlie&szlig;enden Button anklickt.
          </p>

          <p>
            <b>2.6</b>&thinsp;F&uuml;r den Vertragsschluss steht
            ausschlie&szlig;lich die deutsche Sprache zur Verf&uuml;gung.
          </p>

          <p>
            <b>2.7</b>&thinsp;Die Bestellabwicklung und Kontaktaufnahme finden
            in der Regel per E-Mail und automatisierter Bestellabwicklung statt.
            Der Kunde hat sicherzustellen, dass die von ihm zur
            Bestellabwicklung angegebene E-Mail-Adresse zutreffend ist, so dass
            unter dieser Adresse die vom Verk&auml;ufer versandten E-Mails
            empfangen werden k&ouml;nnen. Insbesondere hat der Kunde bei dem
            Einsatz von SPAM-Filtern sicherzustellen, dass alle vom
            Verk&auml;ufer oder von diesem mit der Bestellabwicklung
            beauftragten Dritten versandten E-Mails zugestellt werden
            k&ouml;nnen.
          </p>

          <h2 id="widerrufsrecht">3&#41; Widerrufsrecht</h2>

          <p>
            <b>3.1</b>&thinsp;Verbrauchern steht grunds&auml;tzlich ein
            Widerrufsrecht zu.
          </p>

          <p>
            <b>3.2</b>&thinsp;N&auml;here Informationen zum Widerrufsrecht
            ergeben sich aus der Widerrufsbelehrung des Verk&auml;ufers.
          </p>

          <h2 id="preise">4&#41; Preise und Zahlungsbedingungen</h2>

          <p>
            <b>4.1</b>&thinsp;Sofern sich aus der Produktbeschreibung des
            Verk&auml;ufers nichts anderes ergibt, handelt es sich bei den
            angegebenen Preisen um Gesamtpreise, die die gesetzliche
            Umsatzsteuer enthalten. Gegebenenfalls zus&auml;tzlich anfallende
            Liefer- und Versandkosten werden in der jeweiligen
            Produktbeschreibung gesondert angegeben.
          </p>

          <p>
            <b>4.2</b>&thinsp;Die Zahlungsm&ouml;glichkeit/en wird/werden dem
            Kunden im Online-Shop des Verk&auml;ufers mitgeteilt.
          </p>

          <h2 id="liefer">5&#41; Liefer- und Versandbedingungen</h2>

          <p>
            <b>5.1</b>&thinsp;Die Lieferung von Waren erfolgt auf dem Versandweg
            an die vom Kunden angegebene Lieferanschrift, sofern nichts anderes
            vereinbart ist. Bei der Abwicklung der Transaktion ist die in der
            Bestellabwicklung des Verk&auml;ufers angegebene Lieferanschrift
            ma&szlig;geblich.
          </p>

          <p>
            <b>5.2</b>&thinsp;Sendet das Transportunternehmen die versandte Ware
            an den Verk&auml;ufer zur&uuml;ck, da eine Zustellung beim Kunden
            nicht m&ouml;glich war, tr&auml;gt der Kunde die Kosten f&uuml;r den
            erfolglosen Versand. Dies gilt nicht, wenn der Kunde den Umstand,
            der zur Unm&ouml;glichkeit der Zustellung gef&uuml;hrt hat, nicht zu
            vertreten hat oder wenn er vor&uuml;bergehend an der Annahme der
            angebotenen Leistung verhindert war, es sei denn, dass der
            Verk&auml;ufer ihm die Leistung eine angemessene Zeit vorher
            angek&uuml;ndigt hatte. Ferner gilt dies im Hinblick auf die Kosten
            f&uuml;r die Hinsendung nicht, wenn der Kunde sein Widerrufsrecht
            wirksam aus&uuml;bt. F&uuml;r die R&uuml;cksendekosten gilt bei
            wirksamer Aus&uuml;bung des Widerrufsrechts durch den Kunden die in
            der Widerrufsbelehrung des Verk&auml;ufers hierzu getroffene
            Regelung.
          </p>

          <p>
            <b>5.3</b>&thinsp;Bei Selbstabholung informiert der Verk&auml;ufer
            den Kunden zun&auml;chst per E-Mail dar&uuml;ber, dass die von ihm
            bestellte Ware zur Abholung bereit steht. Nach Erhalt dieser E-Mail
            kann der Kunde die Ware nach Absprache mit dem Verk&auml;ufer am
            Sitz des Verk&auml;ufers abholen. In diesem Fall werden keine
            Versandkosten berechnet.
          </p>

          <h2 id="eigentumsvorbehalt">6&#41; Eigentumsvorbehalt</h2>

          <p>
            Tritt der Verk&auml;ufer in Vorleistung, beh&auml;lt er sich bis zur
            vollst&auml;ndigen Bezahlung des geschuldeten Kaufpreises das
            Eigentum an der gelieferten Ware vor.
          </p>

          <h2 id="mangelhaftung">
            7&#41; M&auml;ngelhaftung (Gew&auml;hrleistung)
          </h2>

          <p>
            <b>7.1</b>&thinsp;Ist die Kaufsache mangelhaft, gelten die
            Vorschriften der gesetzlichen M&auml;ngelhaftung.
          </p>

          <p>
            <b>7.2</b>&thinsp;Der Kunde wird gebeten, angelieferte Waren mit
            offensichtlichen Transportsch&auml;den bei dem Zusteller zu
            reklamieren und den Verk&auml;ufer hiervon in Kenntnis zu setzen.
            Kommt der Kunde dem nicht nach, hat dies keinerlei Auswirkungen auf
            seine gesetzlichen oder vertraglichen M&auml;ngelanspr&uuml;che.
          </p>

          <h2 id="anwendbares">8&#41; Anwendbares Recht</h2>

          <p>
            F&uuml;r s&auml;mtliche Rechtsbeziehungen der Parteien gilt das
            Recht der Bundesrepublik Deutschland unter Ausschluss der Gesetze
            &uuml;ber den internationalen Kauf beweglicher Waren. Bei
            Verbrauchern gilt diese Rechtswahl nur insoweit, als nicht der
            gew&auml;hrte Schutz durch zwingende Bestimmungen des Rechts des
            Staates, in dem der Verbraucher seinen gew&ouml;hnlichen Aufenthalt
            hat, entzogen wird.
          </p>

          <h2 id="alternative">9&#41; Alternative Streitbeilegung</h2>

          <p>
            <b>9.1</b>&thinsp;Die EU-Kommission stellt im Internet unter
            folgendem Link eine Plattform zur Online-Streitbeilegung bereit:
            https://ec.europa.eu/consumers/odr
          </p>

          <p>
            Diese Plattform dient als Anlaufstelle zur au&szlig;ergerichtlichen
            Beilegung von Streitigkeiten aus Online-Kauf- oder
            Dienstleistungsvertr&auml;gen, an denen ein Verbraucher beteiligt
            ist.
          </p>

          <p>
            <b>9.2</b>&thinsp;Der Verk&auml;ufer ist zur Teilnahme an einem
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            nicht verpflichtet, hierzu jedoch grunds&auml;tzlich bereit.
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default AGB;
