import React, { Component } from "react";
import { connect } from "react-redux";
import { logInStore } from "../../actions/user";
import logInUser from "../../images/user2.png";
import logInGastro from "../../images/chef.png";
import AuthContext from "../../context/auth-context";
import { resetPassword } from "../fetcher";
import { Dots } from "react-activity";

export class LogInScreen extends Component {
  constructor(props) {
    super(props);
    this.userMail = React.createRef();
    this.userPassword = React.createRef();
    this.gastroMail = React.createRef();
    this.gastroPassword = React.createRef();
  }

  state = {
    loadingUser: false,
    loadingGastro: false,
    userError: null,
    gastroError: null,
    resetSuccess: false,
  };

  static contextType = AuthContext;

  logIn = (e, type) => {
    e.preventDefault();
    if (type === "user") {
      this.setState({ loadingUser: true }, () => {
        fetch(`${process.env.REACT_APP_URL}`, {
          method: "POST",
          body: JSON.stringify({
            query: `
                        query {
                            login(
                                email: "${this.userMail.current.value}", 
                                password: "${this.userPassword.current.value}"
                                ) 
                            {
                                user {
                                  firstName
                                  lastName
                                  email
                                  phone
                                  adress {
                                    city
                                    zip
                                    street
                                    houseNr
                                  }
                                }
                                userId
                                token
                                tokenExpiration
                            }
                        }
                    `,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((resData) => {
            this.setState({ loadingUser: false });
            if (resData.hasOwnProperty("errors")) {
              this.setState({ userError: resData.errors });
              return false;
            }
            if (resData.data.login.token) {
              this.context.login(
                resData.data.login.userId,
                resData.data.login.token,
                resData.data.login.tokenExpiration,
                false
              );
              document.body.style.overflow = "";
              this.props.logInStore(resData.data.login.user);
              this.props.loggedIn();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    } else if (type === "gastro") {
      this.setState({ loadingGastro: true }, () => {
        fetch(`${process.env.REACT_APP_URL}`, {
          method: "POST",
          body: JSON.stringify({
            query: `
                      query {
                          loginPartner2(
                              email: "${this.gastroMail.current.value}",
                              password: "${this.gastroPassword.current.value}"
                          )
                          {
                              userId
                              token
                              tokenExpiration
                          }
                      }
                  `,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((resData) => {
            this.setState({ loadingGastro: false });
            if (resData.hasOwnProperty("errors")) {
              this.setState({ gastroError: resData.errors });
              return false;
            }
            if (resData.data.loginPartner2.token) {
              this.context.login(
                resData.data.loginPartner2.userId,
                resData.data.loginPartner2.token,
                resData.data.loginPartner2.tokenExpiration,
                true
              );
              this.props.loggedIn();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  reset = (e, email) => {
    e.preventDefault();
    this.setState({ loadingUser: true });
    fetch(`${process.env.REACT_APP_URL}`, {
      method: "POST",
      body: JSON.stringify({
        query: resetPassword(email),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((resData) => {
        if (!resData.hasOwnProperty("errors")) {
          this.setState({
            loadingUser: false,
            userError: null,
            resetSuccess: true,
          });
        } else {
          this.setState({
            userError: ["Das Passwort konnte nicht zurückgesetzt werden."],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { visible } = this.props;
    if (visible) {
      return (
        <div style={{ position: "absolute" }} className="logSignScreen active">
          <h1>doiGastro Login</h1>
          <div className="logSignForms row col-12">
            <div className="logSignUser col-lg-6 col-12">
              <img
                src={logInUser}
                alt="Ein schemenhaftes Profilbild eines Nutzers"
              />
              <div className="">
                <h3>Einloggen als Nutzer</h3>
                <form>
                  <input
                    type="email"
                    name="email_user_logIn"
                    placeholder="E-Mail-Adresse"
                    ref={this.userMail}
                    required
                  />
                  <input
                    type="password"
                    name="password_user_logIn"
                    placeholder="Passwort"
                    ref={this.userPassword}
                    required
                  />
                  {this.state.loadingUser ? (
                    <div className="dots">
                      <Dots color="white" />
                    </div>
                  ) : (
                    <input
                      type="submit"
                      name="submit_user_logIn"
                      value="Einloggen"
                      onClick={(e) => this.logIn(e, "user")}
                    />
                  )}
                  {this.state.resetSuccess && (
                    <div className="success-msg">
                      <p>
                        Das Passwort wurde erfolgreich zurückgesetzt. Wir haben
                        Ihnen eine Mail mit weiteren Informationen gesendet.
                      </p>
                    </div>
                  )}
                  {this.state.userError && (
                    <div className="error-message-box">
                      <p className="error-message">
                        {this.state.userError[0].message}
                      </p>
                      {this.state.userError[0].message ===
                        "Das Passwort ist nicht korrekt." && (
                        <button
                          onClick={(e) => {
                            this.reset(e, this.userMail.current.value);
                          }}
                          className="reset-password"
                        >
                          Passwort zurücksetzen
                        </button>
                      )}
                    </div>
                  )}
                </form>
              </div>
            </div>
            <div className="logSignUser col-lg-6 col-12">
              <img
                src={logInGastro}
                alt="Ein schemenhaftes Profilbild eines Gastronoms"
              />
              <div className="">
                <h3 className="gastro-heading">Einloggen als Gastronom</h3>
                <form>
                  <input
                    type="email"
                    name="email_rest_logIn"
                    placeholder="E-Mail-Adresse"
                    ref={this.gastroMail}
                    required
                  />
                  <input
                    type="password"
                    name="password_rest_logIn"
                    placeholder="Passwort"
                    ref={this.gastroPassword}
                    required
                  />
                  {this.state.loadingGastro ? (
                    <div className="dots">
                      <Dots color="white" />
                    </div>
                  ) : (
                    <input
                      type="submit"
                      name="submit_rest_logIn"
                      value="Einloggen"
                      onClick={(e) => this.logIn(e, "gastro")}
                    />
                  )}
                  {this.state.gastroError && (
                    <div className="error-message-box">
                      <p className="error-message">
                        {this.state.gastroError[0].message}
                      </p>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div className="logSignScreen" />;
    }
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { logInStore })(LogInScreen);
