import React, { Component } from "react";

export class ChangeSalads extends Component {
  state = {
    value: ""
  };

  componentDidMount() {
    const { salads } = this.props;
    if (salads[0].price) {
      this.props.checkSalad(salads[0]);
    }
  }

  handleChange = e => {
    const { salads } = this.props;
    this.setState({ value: e.target.value });
    for (let i = 0; i < salads.length; i++) {
      if (e.target.value === salads[i].name) {
        this.props.checkSalad(salads[i]);
      }
    }
  };

  papaSaidChangeSalad = () => {
    this.setState({ value: "" });
  };

  renderOptions = salads => {
    return salads.map(salad => (
      <option key={salad.name} value={salad.name}>
        {salad.name}
      </option>
    ));
  };

  render() {
    const { salads } = this.props;
    if (salads[0].price === 0 || salads[0].price !== null) {
      return (
        <>
          <h3 className="changers-subheading">Salat bearbeiten</h3>
          <select
            className="select-list"
            value={this.state.value}
            onChange={this.handleChange}
          >
            {this.renderOptions(salads)}
          </select>
        </>
      );
    } else {
      return null;
    }
  }
}

export default ChangeSalads;
