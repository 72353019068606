import React, { Component } from 'react';

import MenuSchema from './MenuSchema';

export class ToppingComponent extends Component {
    state = {
        fixedToppings: 1,
        choosableToppings: 1
    };

    renderToppings = word => {
        if (word === 'fixed') {
            let fixedToppings = [];
            for (let i = 1; i <= this.state.fixedToppings; i++) {
                fixedToppings.push(
                    <MenuSchema
                        key={i}
                        number={i}
                        info="fixedToppings"
                        name="Fixiertes Topping"
                        doubleState="doubled"
                        doubleState_string="Doppelt?"
                        dishNumber={this.props.number}
                        catNumber={this.props.catNumber}
                    />
                );
            }
            return fixedToppings;
        } else {
            let choosableToppings = [];
            for (let i = 1; i <= this.state.choosableToppings; i++) {
                choosableToppings.push(
                    <MenuSchema
                        key={i}
                        number={i}
                        name="Wählbares Topping"
                        info="choosableToppings"
                        doubleState="doubleable"
                        doubleState_string="Verdoppelbar?"
                        dishNumber={this.props.number}
                        catNumber={this.props.catNumber}
                    />
                );
            }
            return choosableToppings;
        }
    };

    addFixedToppings = e => {
        e.preventDefault();
        if (e.target.name === 'addChoosable') {
            this.setState({
                choosableToppings: this.state.choosableToppings + 1
            });
        } else if (e.target.name === 'deleteChoosable') {
            this.setState({
                choosableToppings: this.state.choosableToppings - 1
            });
        } else if (e.target.name === 'add') {
            this.setState({ fixedToppings: this.state.fixedToppings + 1 });
        } else {
            if (this.state.fixedToppings > 0) {
                this.setState({ fixedToppings: this.state.fixedToppings - 1 });
            }
        }
    };

    render() {
        const {
            number,
            catNumber,
            choosableTopicsString,
            choosableTopics,
            maxAddToppingsString,
            maxAddToppings,
            prefactored,
            changeState,
            setAddableToppings
        } = this.props;
        return (
            <React.Fragment>
                <div className="dish-propertie-check">
                    <div className="mx-auto">
                        {this.renderToppings('fixed')}
                        <button
                            className="offset-3 dish-btn col-3"
                            name="add"
                            onClick={this.addFixedToppings}
                        >
                            + Topping
                        </button>
                        <button
                            className="cat-btn col-3"
                            name="delete"
                            onClick={this.addFixedToppings}
                        >
                            - Topping
                        </button>
                    </div>
                </div>
                <div className="dish-propertie-check">
                    <p className="weekday_true_false">
                        Kann man darüber hinaus Toppings auswählen?
                    </p>
                    <p className="label-example">
                        Kann man neben den bereits vorhandenen Toppings aus
                        einer gewissen Auswahl (obig angegebene oder
                        abweichende) Toppings auswählen?
                    </p>
                    <input
                        type="radio"
                        id={`catno-${catNumber}-dishno-${number}-choosableToppings-true`}
                        name={`prefactoredToppings`}
                        value="true"
                        onChange={changeState}
                    />
                    <label
                        className="label_true_false"
                        htmlFor={`catno-${catNumber}-dishno-${number}-choosableToppings-true`}
                    >
                        {' '}
                        Ja
                    </label>
                    <input
                        type="radio"
                        id={`catno-${catNumber}-dishno-${number}-choosableToppings-false`}
                        name={`prefactoredToppings`}
                        onChange={changeState}
                        value=""
                        defaultChecked
                    />
                    <label
                        className="label_true_false"
                        htmlFor={`catno-${catNumber}-dishno-${number}-choosableToppings-false`}
                    >
                        {' '}
                        Nein
                    </label>
                    {prefactored && (
                        <div className="dish-propertie-check">
                            <p className="weekday_true_false">
                                Sollen die vorgefertigten Toppings genutzt
                                werden?
                            </p>
                            <input
                                type="radio"
                                id={`catno-${catNumber}-dishno-${number}-choosableToppingsPrefactored-true`}
                                name={`${choosableTopicsString}`}
                                value="true"
                                onChange={changeState}
                                defaultChecked
                            />
                            <label
                                className="label_true_false"
                                htmlFor={`catno-${catNumber}-dishno-${number}-choosableToppingsPrefactored-true`}
                            >
                                {' '}
                                Ja
                            </label>
                            <input
                                type="radio"
                                id={`catno-${catNumber}-dishno-${number}-choosableToppingsPrefactored-false`}
                                name={`${choosableTopicsString}`}
                                onChange={changeState}
                                value=""
                            />
                            <label
                                className="label_true_false"
                                htmlFor={`catno-${catNumber}-dishno-${number}-choosableToppingsPrefactored-false`}
                            >
                                {' '}
                                Nein
                            </label>
                            {!choosableTopics && (
                                <div className="dish-propertie-check">
                                    <div className="mx-auto">
                                        {this.renderToppings('choosable')}
                                        <button
                                            className="offset-3 dish-btn col-3"
                                            name="addChoosable"
                                            onClick={this.addFixedToppings}
                                        >
                                            + Topping
                                        </button>
                                        <button
                                            className="cat-btn col-3"
                                            name="deleteChoosable"
                                            onClick={this.addFixedToppings}
                                        >
                                            - Topping
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="dish-propertie-check">
                    <p className="weekday_true_false">
                        Können beliebig viele Toppings ergänzt werden?
                    </p>
                    <p className="label-example">
                        Gibt es eine Obergrenze an Toppings oder können erst
                        weitere Toppings hinzugefügt werden, wenn vorher "alte"
                        entfernt wurden?
                    </p>
                    <input
                        type="radio"
                        id={`catno-${catNumber}-dishno-${number}-maxToppings-true`}
                        name={`${maxAddToppingsString}`}
                        value="true"
                        onChange={changeState}
                        defaultChecked
                    />
                    <label
                        className="label_true_false"
                        htmlFor={`catno-${catNumber}-dishno-${number}-maxToppings-true`}
                    >
                        {' '}
                        Ja
                    </label>
                    <input
                        type="radio"
                        id={`catno-${catNumber}-dishno-${number}-maxToppings-false`}
                        name={`${maxAddToppingsString}`}
                        onChange={changeState}
                        value=""
                    />
                    <label
                        className="label_true_false"
                        htmlFor={`catno-${catNumber}-dishno-${number}-maxToppings-false`}
                    >
                        {' '}
                        Nein
                    </label>
                    {!maxAddToppings && (
                        <div className="propertie-check-final">
                            <p className="weekday_true_false">
                                Wie viele Toppings können maximal hinzugefügt
                                werden?
                            </p>
                            <p className="label-info">
                                Die Frage an sich sollte selbsterklärend sein,
                                falls nur so viele Toppings hinzugefügt werden
                                können, wie vorher rausgelöscht wurden, bitte
                                "xx" eingeben (ohne Anführungszeichen)
                            </p>
                            <p className="label-example">
                                Beispiel: In der Linde können bei Pizzen
                                beispielhaft fünf weitere Toppings ergänzt
                                werden, bei Krustis hingegen nur so viele, wie
                                vorher gelöscht wurden: Pizza Salami: 5 Krusti:
                                xx
                            </p>
                            <input
                                type="text"
                                id={`catno-${catNumber}-dishno-${number}-maxToppings-val`}
                                name={`${setAddableToppings}-val`}
                                placeholder="Maximale-Anzahl oder xx eingeben"
                            />
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default ToppingComponent;
