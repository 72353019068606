import React, { Component } from 'react';

import MenuSchema from './MenuSchema';

export class GarnishComponent extends Component {
    state = {
        choosableGarnishes: 1
    };

    renderGarnishes = () => {
        let choosableGarnishes = [];
        for (let i = 1; i <= this.state.choosableGarnishes; i++) {
            choosableGarnishes.push(
                <MenuSchema
                    key={i}
                    number={i}
                    name={`Wählbare Beilage ${i}`}
                    info="choosableGarnishes"
                    doubleState="doubleable"
                    doubleState_string="Verdoppelbar?"
                    dishNumber={this.props.number}
                    catNumber={this.props.catNumber}
                />
            );
        }
        return choosableGarnishes;
    };

    addGarnishes = e => {
        e.preventDefault();
        if (e.target.name === 'add') {
            this.setState({
                choosableGarnishes: this.state.choosableGarnishes + 1
            });
        } else {
            if (this.state.choosableGarnishes > 0) {
                this.setState({
                    choosableGarnishes: this.state.choosableGarnishes - 1
                });
            }
        }
    };

    render() {
        const {
            number,
            catNumber,
            choosableTopicsString,
            choosableTopics,
            prefactored,
            changeState
        } = this.props;
        return (
            <React.Fragment>
                <div className="dish-propertie-check">
                    <div className="mx-auto">
                        <MenuSchema
                            number={0}
                            name="Beilage"
                            info="fixedGarnishes"
                            doubleState="doubleable"
                            doubleState_string="Verdoppelbar?"
                            dishNumber={this.props.number}
                            catNumber={this.props.catNumber}
                        />
                    </div>
                </div>
                <div className="dish-propertie-check">
                    <p className="weekday_true_false">
                        Kann man darüber hinaus Beilagen auswählen?
                    </p>
                    <p className="label-example">
                        Kann man neben den bereits vorhandenen Beilagen aus
                        einer gewissen Auswahl (obig angegebene oder
                        abweichende) Beilagen auswählen?
                    </p>
                    <input
                        type="radio"
                        id={`catno-${catNumber}-dishno-${number}-choosableGarnishes-true`}
                        name={`prefactoredGarnishes`}
                        value="true"
                        onChange={changeState}
                    />
                    <label
                        className="label_true_false"
                        htmlFor={`catno-${catNumber}-dishno-${number}-choosableGarnishes-true`}
                    >
                        {' '}
                        Ja
                    </label>
                    <input
                        type="radio"
                        id={`catno-${catNumber}-dishno-${number}-choosableGarnishes-false`}
                        name={`prefactoredGarnishes`}
                        onChange={changeState}
                        value=""
                        defaultChecked
                    />
                    <label
                        className="label_true_false"
                        htmlFor={`catno-${catNumber}-dishno-${number}-choosableGarnishes-false`}
                    >
                        {' '}
                        Nein
                    </label>
                    {prefactored && (
                        <div className="dish-propertie-check">
                            <p className="weekday_true_false">
                                Sollen die vorgefertigten Beilagen genutzt
                                werden?
                            </p>
                            <input
                                type="radio"
                                id={`catno-${catNumber}-dishno-${number}-choosableGarnishesPrefactored-true`}
                                name={`${choosableTopicsString}`}
                                value="true"
                                onChange={changeState}
                                defaultChecked
                            />
                            <label
                                className="label_true_false"
                                htmlFor={`catno-${catNumber}-dishno-${number}-choosableGarnishesPrefactored-true`}
                            >
                                {' '}
                                Ja
                            </label>
                            <input
                                type="radio"
                                id={`catno-${catNumber}-dishno-${number}-choosableGarnishesPrefactored-false`}
                                name={`${choosableTopicsString}`}
                                onChange={changeState}
                                value=""
                            />
                            <label
                                className="label_true_false"
                                htmlFor={`catno-${catNumber}-dishno-${number}-choosableGarnishesPrefactored-false`}
                            >
                                {' '}
                                Nein
                            </label>
                            {!choosableTopics && (
                                <div className="dish-propertie-check">
                                    <div className="mx-auto">
                                        {this.renderGarnishes()}
                                        <button
                                            className="offset-3 dish-btn col-3"
                                            name="add"
                                            onClick={this.addGarnishes}
                                        >
                                            + Beilage
                                        </button>
                                        <button
                                            className="cat-btn col-3"
                                            name="delete"
                                            onClick={this.addGarnishes}
                                        >
                                            - Beilage
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default GarnishComponent;
