import React, { Component } from "react";
import { Dots } from "react-activity";

import logInUser from "../../images/user2.png";
import logInGastro from "../../images/chef.png";
import "react-activity/dist/react-activity.css";

export class SignUpScreen extends Component {
  constructor(props) {
    super(props);
    this.telcheck = React.createRef();
  }

  state = {
    loadingUser: false,
    loadingGastro: false,
    registerSuccess: false,
    error: false,
    errorMessage: "",
    restaurantRegisterSuccess: false,
    restaurantError: false,
    restaurantErrorMessage: "",
    userFirstName: "",
    userLastName: "",
    userEmail: "",
    userPhoneNumber: "",
    userZip: "",
    userCity: "",
    userStreet: "",
    userHouseNr: "",
    userPassword: "",
    userPasswordValidation: "",
    gastroFirstName: "",
    gastroLastName: "",
    gastroEmail: "",
    gastroPhone: "",
    restaurantName: "",
    restaurantZip: "",
    restaurantCity: "",
    restaurantStreet: "",
    restaurantHouseNr: "",
    restaurantPassword: "",
    restaurantPasswordValidation: ""
  };

  newError = errorMessage => {
    this.setState({ error: true, errorMessage });
  };

  signIn = (e, type) => {
    const {
      userFirstName,
      userLastName,
      userEmail,
      userPhoneNumber,
      userZip,
      userCity,
      userStreet,
      userHouseNr,
      userPassword,
      userPasswordValidation,
      gastroFirstName,
      gastroLastName,
      gastroEmail,
      gastroPhone,
      restaurantName,
      restaurantZip,
      restaurantCity,
      restaurantStreet,
      restaurantHouseNr,
      restaurantPassword,
      restaurantPasswordValidation
    } = this.state;
    e.preventDefault();
    if (type === "user") {
      if (!this.telcheck.current.checked) {
        // Wenn die Checkbox nicht abgehakt wurde, gibt es einen Error mit der passenden Error-Meldung
        this.newError(
          "Bitte akzeptieren Sie die Dienstleistungsvereinbarungen."
        );
        return;
      }
      if (!userPassword || !userPasswordValidation) {
        // Wenn die Passwörter nicht übereinstimmen, gibt es einen Error mit der passenden Error-Meldung
        this.newError("Bitte füllen Sie beide Passwortfelder aus.");
        return;
      }
      if (!userZip || !userCity || !userStreet || !userHouseNr) {
        // Wenn die Passwörter nicht übereinstimmen, gibt es einen Error mit der passenden Error-Meldung
        this.newError(
          "Bitte geben Sie eine gültige Adresse an, an welche die Restaurants ggf. liefern können."
        );
        return;
      }
      if (userPassword !== userPasswordValidation) {
        // Wenn die Passwörter nicht übereinstimmen, gibt es einen Error mit der passenden Error-Meldung
        this.newError("Die Passwörter stimmen nicht überein.");
        return;
      }
      this.setState({ loadingUser: true }, () => {
        fetch(`${process.env.REACT_APP_URL}`, {
          method: "POST",
          body: JSON.stringify({
            query: `
                    mutation {
                        createUser(userInput: {
                            firstName: "${userFirstName}"
                            lastName: "${userLastName}"
                            email: "${userEmail}"
                            password: "${userPassword}"
                            phone: "${userPhoneNumber}"
                            adress: {
                                city: "${userCity}"
                                zip: "${userZip}"
                                street: "${userStreet}"
                                houseNr: "${userHouseNr}"
                        }
                        }) {
                            _id
                            firstName
                            lastName
                        }
                    }
                    `
          }),
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(res => res.json())
          .then(resData => {
            if (!resData.hasOwnProperty("errors")) {
              this.telcheck.current.checked = false;
              this.setState({
                userFirstName: "",
                userLastName: "",
                userEmail: "",
                userPhoneNumber: "",
                userZip: "",
                userCity: "",
                userStreet: "",
                userHouseNr: "",
                userPassword: "",
                userPasswordValidation: "",
                registerSuccess: true,
                error: false
              });
            } else {
              // Wenn der Nutzer bereits existiert, wird eine passende Error-Meldung ausgegeben.
              this.newError(
                "Ein Nutzer mit dieser E-Mail-Adresse existiert bereits."
              );
            }
            this.setState({ loadingUser: false });
            return;
          })
          .catch(err => {
            console.log(err);
          });
      });
    } else if (type === "gastro") {
      if (restaurantPassword !== restaurantPasswordValidation) {
        this.setState({
          restaurantError: true,
          restaurantErrorMessage: "Die Passwörter stimmen nicht überein."
        });
        return;
      }
      this.setState({ loadingGastro: true }, () => {
        fetch(`${process.env.REACT_APP_URL}`, {
          method: "POST",
          body: JSON.stringify({
            query: `
                    mutation {
                        createRestaurantPartner(restPartnerInput: {
                            gastroFirstNameInput: "${gastroFirstName}"
                            gastroLastNameInput: "${gastroLastName}"
                            gastroEmailInput: "${gastroEmail}"
                            gastroPhoneInput: "${gastroPhone}"
                            restaurantNameInput: "${restaurantName}"
                            restaurantZipInput: "${restaurantZip}"
                            restaurantCityInput: "${restaurantCity}"
                            restaurantStreetInput: "${restaurantStreet}"
                            restaurantHouseNrInput: "${restaurantHouseNr}"
                            restaurantPasswordInput: "${restaurantPassword}"
                        }) {
                            gastroFirstName
                        }
                    }
                    `
          }),
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(res => res.json())
          .then(resData => {
            if (!resData.hasOwnProperty("errors")) {
              this.setState({
                restaurantRegisterSuccess: true,
                restaurantError: false
              });
            } else {
              this.setState({
                restaurantError: true,
                restaurantErrorMessage:
                  "Ein Restaurantpartner mit dieser E-Mail-Adresse existiert bereits."
              });
            }
            this.setState({ loadingGastro: false });
            return;
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
    e.preventDefault();
  };

  render() {
    const { visible } = this.props;
    const {
      userFirstName,
      userLastName,
      userEmail,
      userPhoneNumber,
      userZip,
      userCity,
      userStreet,
      userHouseNr,
      userPassword,
      userPasswordValidation,
      gastroFirstName,
      gastroLastName,
      gastroEmail,
      gastroPhone,
      restaurantName,
      restaurantZip,
      restaurantCity,
      restaurantStreet,
      restaurantHouseNr,
      restaurantPassword,
      restaurantPasswordValidation
    } = this.state;
    if (visible) {
      return (
        <div style={{ position: "absolute" }} className="logSignScreen active">
          <h1>doiGastro Registrierung</h1>
          <p>
            Du kannst dich bei doiGastro als Nutzer oder als Gastronom
            registrieren.
          </p>
          <div className="logSignForms row col-12">
            <div className="logSignUser col-lg-6 col-12">
              <img
                src={logInUser}
                alt="Ein schemenhaftes Profilbild eines Nutzers"
              />
              <div>
                <h3>Als Nutzer registrieren</h3>
                <form
                  onKeyDown={() => this.setState({ registerSuccess: false })}
                >
                  <input
                    type="text"
                    name="firstname_user_register"
                    placeholder="Vorname&#42;"
                    value={userFirstName}
                    onChange={e =>
                      this.setState({ userFirstName: e.target.value })
                    }
                    required
                  />
                  <input
                    type="text"
                    name="lastname_user_register"
                    placeholder="Nachname&#42;"
                    value={userLastName}
                    onChange={e =>
                      this.setState({ userLastName: e.target.value })
                    }
                    required
                  />
                  <input
                    type="email"
                    name="email_user_register"
                    placeholder="E-Mail Adresse&#42;"
                    value={userEmail}
                    onChange={e => this.setState({ userEmail: e.target.value })}
                    required
                  />
                  <input
                    type="text"
                    name="phone_user_register"
                    placeholder="Telefonnummer"
                    value={userPhoneNumber}
                    onChange={e =>
                      this.setState({ userPhoneNumber: e.target.value })
                    }
                  />
                  <input
                    type="number"
                    name="zip_user_register"
                    value={userZip}
                    onChange={e => this.setState({ userZip: e.target.value })}
                    placeholder="Postleitzahl"
                  />
                  <input
                    type="text"
                    name="city_user_register"
                    value={userCity}
                    onChange={e => this.setState({ userCity: e.target.value })}
                    placeholder="Wohnort"
                  />
                  <input
                    type="text"
                    name="street_user_register"
                    placeholder="Straße"
                    value={userStreet}
                    onChange={e =>
                      this.setState({ userStreet: e.target.value })
                    }
                  />
                  <input
                    type="text"
                    name="housenr_user_register"
                    placeholder="Adresszusatz/Hausnr."
                    value={userHouseNr}
                    onChange={e =>
                      this.setState({ userHouseNr: e.target.value })
                    }
                  />
                  <input
                    type="password"
                    name="password_user_register"
                    placeholder="Passwort&#42;"
                    value={userPassword}
                    onChange={e =>
                      this.setState({ userPassword: e.target.value })
                    }
                    required
                  />
                  <input
                    type="password"
                    name="password_user_register_validate"
                    placeholder="Passwort bestätigen&#42;"
                    value={userPasswordValidation}
                    onChange={e =>
                      this.setState({ userPasswordValidation: e.target.value })
                    }
                    required
                  />
                  <div className="label-div leftText">
                    <span>&#42; Pflichtfelder</span>
                  </div>
                  <div className="label-div">
                    <input id="telcheck" type="checkbox" ref={this.telcheck} />
                    <label htmlFor="telcheck">
                      Ich bin damit einverstanden, dass meine E-Mail-Adresse und
                      meine Telefonnummer an die jeweiligen Dienstleister
                      weitergegeben wird, damit der Dienstleister vor der
                      Zustellung der Ware zum Zwecke der Abwicklung per E-Mail
                      oder Telefon Kontakt mit mir aufnehmen bzw.
                      Statusinformationen zur Lieferungszustellung übermitteln
                      kann. Meine diesbezüglich erteilte Einwilligung kann ich
                      jederzeit widerrufen.
                    </label>
                  </div>
                  <div className="label-div">
                    Mit Ihrer Registrierung erklären Sie sich mit unseren
                    Allgemeinen Geschäftsbedingungen einverstanden. Bitte lesen
                    Sie unsere Datenschutzerklärung, unsere Hinweise zu Cookies
                    und unsere Hinweise zu interessenbasierter Werbung.
                  </div>
                  {this.state.error && (
                    <div className="error-msg">
                      <p>{this.state.errorMessage}</p>
                    </div>
                  )}
                  {this.state.registerSuccess && (
                    <div className="success-msg">
                      <p>
                        Vielen Dank, die Registrierung war erfolgreich. Wir
                        haben Ihnen weitere Informationen per Mail geschickt.
                      </p>
                    </div>
                  )}
                  {this.state.loadingUser ? (
                    <div className="dots">
                      <Dots color="white" />
                    </div>
                  ) : (
                    <input
                      type="submit"
                      name="submit_user_register"
                      value="Registrieren"
                      onClick={e => this.signIn(e, "user")}
                    />
                  )}
                </form>
              </div>
            </div>
            <div className="logSignUser col-lg-6 col-12">
              <img
                src={logInGastro}
                alt="Ein schemenhaftes Profilbild eines Gastronoms"
              />
              <div className="">
                <h3 className="gastro-heading">Als Gastronom registrieren</h3>
                <form
                  onKeyDown={() =>
                    this.setState({
                      restaurantRegisterSuccess: false
                    })
                  }
                >
                  <div>
                    <h4 className="leftText">Ansprechpartner</h4>
                    <input
                      type="text"
                      name="firstname_gastro_register"
                      placeholder="Vorname&#42;"
                      value={gastroFirstName}
                      onChange={e =>
                        this.setState({ gastroFirstName: e.target.value })
                      }
                      required
                    />
                    <input
                      type="text"
                      name="lastname_gastro_register"
                      placeholder="Nachname&#42;"
                      value={gastroLastName}
                      onChange={e =>
                        this.setState({ gastroLastName: e.target.value })
                      }
                      required
                    />
                    <input
                      type="email"
                      name="email_gastro_register"
                      placeholder="E-Mail Adresse&#42;"
                      value={gastroEmail}
                      onChange={e =>
                        this.setState({ gastroEmail: e.target.value })
                      }
                      required
                    />
                    <input
                      type="text"
                      name="phone_gastro_register"
                      placeholder="Telefonnummer&#42;"
                      value={gastroPhone}
                      onChange={e =>
                        this.setState({ gastroPhone: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div>
                    <h4 className="leftText">Restaurantinformation</h4>
                    <input
                      type="text"
                      name="restaurantname_gastro_register"
                      placeholder="Restaurantname&#42;"
                      value={restaurantName}
                      onChange={e =>
                        this.setState({ restaurantName: e.target.value })
                      }
                      required
                    />
                    <input
                      type="number"
                      name="zip_gastro_register"
                      placeholder="Postleitzahl&#42;"
                      value={restaurantZip}
                      onChange={e =>
                        this.setState({ restaurantZip: e.target.value })
                      }
                      required
                    />
                    <input
                      type="text"
                      name="city_gastro_register"
                      placeholder="Ort&#42;"
                      value={restaurantCity}
                      onChange={e =>
                        this.setState({ restaurantCity: e.target.value })
                      }
                      required
                    />
                    <input
                      type="text"
                      name="street_gastro_register"
                      placeholder="Straße&#42;"
                      value={restaurantStreet}
                      onChange={e =>
                        this.setState({ restaurantStreet: e.target.value })
                      }
                      required
                    />
                    <input
                      type="text"
                      name="housenr_gastro_register"
                      placeholder="Adresszusatz/Hausnr.&#42;"
                      value={restaurantHouseNr}
                      onChange={e =>
                        this.setState({ restaurantHouseNr: e.target.value })
                      }
                      required
                    />
                    <input
                      type="password"
                      name="password_gastro_register"
                      placeholder="Passwort&#42;"
                      value={restaurantPassword}
                      onChange={e =>
                        this.setState({ restaurantPassword: e.target.value })
                      }
                      required
                    />
                    <input
                      type="password"
                      name="password_gastro_register_validate"
                      placeholder="Passwort bestätigen&#42;"
                      value={restaurantPasswordValidation}
                      onChange={e =>
                        this.setState({
                          restaurantPasswordValidation: e.target.value
                        })
                      }
                      required
                    />
                  </div>
                  <div className="label-div leftText">
                    <span>&#42; Pflichtfelder</span>
                  </div>
                  <div className="label-div">
                    Mit Ihrer Registrierung erklären Sie sich mit unseren
                    Allgemeinen Geschäftsbedingungen einverstanden. Bitte lesen
                    Sie unsere Datenschutzerklärung, unsere Hinweise zu Cookies
                    und unsere Hinweise zu interessenbasierter Werbung.
                  </div>
                  {this.state.restaurantError && (
                    <div className="error-msg">
                      <p>{this.state.restaurantErrorMessage}</p>
                    </div>
                  )}
                  {this.state.restaurantRegisterSuccess && (
                    <div className="success-msg">
                      <p>
                        Sie haben sich erfolgreich registriert. Wir haben Ihnen
                        weitere Informationen per Mail geschickt.
                      </p>
                    </div>
                  )}
                  {this.state.loadingGastro ? (
                    <div className="dots">
                      <Dots color="white" />
                    </div>
                  ) : (
                    <input
                      type="submit"
                      name="submit_gastro_register"
                      value="Registrieren"
                      onClick={e => this.signIn(e, "gastro")}
                    />
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div className="logSignScreen" />;
    }
  }
}

export default SignUpScreen;
