import React, { Component } from "react";

import "./gastro-css.css";
import WelcomeGastro from "./WelcomeGastro";
import LiveOrders from "./LiveOrders";

export class GastroSite extends Component {
  state = {
    partner: null,
  };

  componentDidMount() {
    fetch(`${process.env.REACT_APP_URL}`, {
      method: "POST",
      body: JSON.stringify({
        query: `
                    query 
                          {
                            showSinglePartner(_id: "${this.props.id}") {
                              name
                              owner {firstName}
                              service {delivery {duration}}
                            }
                          }
                `,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((resData) => {
        this.setState({
          partner: resData.data.showSinglePartner,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { partner } = this.state;

    if (!partner) {
      return <p>Lädt...</p>;
    }
    return (
      <div className="gastro-div">
        <WelcomeGastro
          restaurantName={partner.name}
          partnerName={partner.owner.firstName}
        />
        <LiveOrders partnerId={this.props.id} partner={partner} />
      </div>
    );
  }
}

export default GastroSite;
