import React, { Component } from "react";
import "./Cart.scss";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SingleDish from "./SingleDish";
import ornament from "../../../images/menu-subheading-bottom.png";
import { toggleComponents } from "../../../actions/toggleActions";
import { setDish } from "../../../actions/single-dish-actions";

export class CartTemplate extends Component {
  state = {
    checkoutItems: []
  };

  setCart = cartItem => {
    let { checkoutItems } = this.state;
    if (this.state.checkoutItems.length > 0) {
      for (let i = 0; i < this.state.checkoutItems.length; i++) {
        if (
          cartItem.name === checkoutItems[i].name &&
          cartItem.toppings === checkoutItems[i].toppings &&
          cartItem.sauce === checkoutItems[i].sauce &&
          cartItem.salad === checkoutItems[i].salad &&
          cartItem.garnish === checkoutItems[i].garnish &&
          cartItem.size === checkoutItems[i].size
        ) {
          let newcheckoutItems = checkoutItems.slice();
          newcheckoutItems[i].quantity =
            parseInt(newcheckoutItems[i].quantity) +
            parseInt(cartItem.quantity);
          this.setState({ checkoutItems: newcheckoutItems }, () => {
            localStorage.setItem(
              "savedState",
              JSON.stringify({
                checkoutItems: this.state.checkoutItems
              })
            );
          });
          return false;
        } else {
          this.setState(
            (state, props) => {
              return {
                checkoutItems: [...this.state.checkoutItems, cartItem]
              };
            },
            () => {
              localStorage.setItem(
                "savedState",
                JSON.stringify({
                  checkoutItems: this.state.checkoutItems
                })
              );
            }
          );
        }
      }
    } else {
      this.setState(
        (state, props) => {
          return {
            checkoutItems: [...this.state.checkoutItems, cartItem]
          };
        },
        () => {
          localStorage.setItem(
            "savedState",
            JSON.stringify({
              checkoutItems: this.state.checkoutItems
            })
          );
        }
      );
    }
  };

  render() {
    let { menu, restaurant } = this.props;
    return (
      <div className="cart-template">
        <div className="cart-top-info">
          <p>
            Für weitere Informationen und Bearbeitungsmöglichkeiten bitte das
            jeweilige Gericht antippen
          </p>
        </div>
        <div className="dishlist-outter-box row">
          {menu.map((dish, i) => (
            <div key={i} className="dishlist-overview col-12 col-sm-5 col-md-5">
              <h3>{dish.name}</h3>
              <img
                src={ornament}
                alt="Untere Verzierung der Gerichtsüberschrift"
              />
              <div>
                {dish.dishlist.map((singledish, i) => {
                  let dishname = singledish.name.replace(/\s+/g, "");
                  return (
                    <Link
                      key={i}
                      className="choose-single-dish"
                      onClick={() => {
                        this.props.setDish(singledish);
                      }}
                      to={{
                        pathname: `/${
                          restaurant.url
                        }/dish/${dishname.toLowerCase()}`,
                        state: singledish
                      }}
                    >
                      <SingleDish singledish={singledish} />
                    </Link>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  restaurant: state.restaurant,
  menu: state.restaurant.menu.dishes
});

export default connect(
  mapStateToProps,
  { toggleComponents, setDish }
)(CartTemplate);
