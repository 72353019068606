import React, { Component } from "react";
import "./Checkout.scss";
import AuthContext from "../../../context/auth-context";
import ShoppingCheckOut from "./ShoppingCheckOut";
import { connect } from "react-redux";
import {
  resetCheckOut,
  decrement,
  removeCheckOutItem
} from "../../../actions/cart-actions";

export class CheckOut extends Component {
  state = {
    data: [{ url: "" }],
    loaded: false,
    checkoutItems: this.props.checkout
  };

  static contextType = AuthContext;

  componentWillUnmount() {
    localStorage.setItem("checkout", JSON.stringify(this.props.checkout));
  }

  componentWillMount() {
    let items = JSON.parse(localStorage.getItem("checkout"));
    this.setState({ checkoutItems: items });
  }

  deleteCheckOut = () => {
    this.setState({ checkoutItems: [] }, () => {
      localStorage.setItem(
        "savedState",
        JSON.stringify({
          data: [{ url: "" }],
          loaded: false,
          checkoutItems: []
        })
      );
    });
  };

  setCheckOut = checkoutItem => {
    let { checkoutItems } = this.state;
    if (this.state.checkoutItems.length > 0) {
      for (let i = 0; i < this.state.checkoutItems.length; i++) {
        if (
          checkoutItem.name === checkoutItems[i].name &&
          checkoutItem.toppings === checkoutItems[i].toppings &&
          checkoutItem.sauce === checkoutItems[i].sauce &&
          checkoutItem.salad === checkoutItems[i].salad &&
          checkoutItem.garnish === checkoutItems[i].garnish &&
          checkoutItem.size === checkoutItems[i].size
        ) {
          let newCheckOutItems = checkoutItems.slice();
          newCheckOutItems[i].quantity =
            parseInt(newCheckOutItems[i].quantity) +
            parseInt(checkoutItem.quantity);
          this.setState({ checkoutItems: newCheckOutItems }, () => {
            localStorage.setItem("savedState", JSON.stringify(this.state));
          });
          return false;
        } else {
          this.setState(
            (state, props) => {
              return {
                checkoutItems: [...this.state.checkoutItems, checkoutItem]
              };
            },
            () => {
              localStorage.setItem("savedState", JSON.stringify(this.state));
            }
          );
        }
      }
    } else {
      this.setState(
        (state, props) => {
          return {
            checkoutItems: [...this.state.checkoutItems, checkoutItem]
          };
        },
        () => {
          localStorage.setItem("savedState", JSON.stringify(this.state));
        }
      );
    }
  };

  showCheckOut = () => {
    this.child.showCheckOut();
  };

  render() {
    return (
      <ShoppingCheckOut
        deleteCheckOut={() => this.deleteCheckOut()}
        ref={fct => (this.child = fct)}
        handleSucessfullMsg={msg => this.props.handleSucessfullMsg(msg)}
        fromWhere={this.props.fromWhere}
      />
    );
  }
}

const mapStateToProps = state => ({
  checkout: state.checkout
});

export default connect(
  mapStateToProps,
  { resetCheckOut, decrement, removeCheckOutItem }
)(CheckOut);
