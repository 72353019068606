import React, { Component } from "react";
import Navbar from "../navigation/Navbar";

export class Impressum extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="footer-texts">
          <h1 className="datenschutz">Impressum</h1>

          <p>Lucas Staus</p>
          <p>Zum Sportplatz 4</p>
          <p>35080 Bottenhorn</p>
          <p>Deutschland</p>
          <br />
          <p>E-Mail: mail@doigastro.de</p>
          <br />
          <p>
            Plattform der EU-Kommission zur Online-Streitbeteiligung:{" "}
            <a href="https://ec.europa.eu/odr">https://ec.europa.eu/odr</a>
          </p>
          <br />
          <p id="info-verbraucherschlichtungsstelle">
            Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle nicht verpflichtet, hierzu jedoch
            grundsätzlich bereit.
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default Impressum;
