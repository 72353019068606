import React, { Component } from "react";
import "./navbar.css";
import { connect } from "react-redux";
import { setLogSign } from "../../actions/basic";
import HiddenNavbar from "./HiddenNavbar";

class Navbar extends Component {
  state = {
    hideNavbar: false,
    toggled: null,
    secondClass: "toggleMenu"
  };

  loggedIn = () => {
    this.setState({ toggled: null });
  };

  // Funktion erwartet ein event, welches den Namen signUp oder logIn trägt, um herauszufinden welches von beiden geklickt wurde.
  detectClicked = e => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    let targetValue = e.target.name;
    if (this.state.toggled !== targetValue) {
      document.body.style.overflow = "hidden";
      this.props.setLogSign(true);
      this.setState({ toggled: targetValue });
    } else {
      document.body.style.overflow = "";
      this.props.setLogSign(false);
      this.setState({ toggled: null });
    }
  };

  render() {
    const { toggled } = this.state;
    const { gastroSite, cartContent, restaurantNavbar } = this.props;
    return (
      <HiddenNavbar
        toggled={toggled}
        detectClicked={this.detectClicked}
        loggedIn={this.loggedIn}
        gastroSite={gastroSite}
        cartItem={restaurantNavbar}
        cartContent={cartContent}
        toggleComponents={state => this.props.toggleComponents(state)}
      />
    );
  }
}

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  { setLogSign }
)(Navbar);
