import React, { Component } from "react";
import { connect } from "react-redux";
import { logInStore } from "../../actions/user";
import VisibleMenuScreen from "./VisibleMenuScreen";
import AuthContext from "../../context/auth-context";
import logo from "../../images/logo.png";
import doiLogo from "../../images/doiApp.png";

export class HiddenNavbar extends Component {
  static contextType = AuthContext;

  render() {
    let { toggled, loggedIn, gastroSite, user, logInStore } = this.props;
    return (
      <AuthContext.Consumer>
        {(context) => (
          <React.Fragment>
            <div className="navbar">
              <div className="hiddenNavbar">
                <h1 className="coverNco">
                  <a href="/" className="cover">
                    <img
                      src={doiLogo}
                      alt="doiGastro Schriftzug im Umriss des Landkreises Marburg-Biedenkopf"
                      className="cover-logo"
                    />
                  </a>
                  {gastroSite && <p>| RestaurantArea</p>}
                </h1>
                <div className="user_buttons">
                  {!context.token && (
                    <React.Fragment>
                      <button
                        className="user_button"
                        name="logIn"
                        onClick={this.props.detectClicked}
                      >
                        {toggled !== "logIn" && "Anmelden"}
                        {toggled === "logIn" && "Schließen"}
                      </button>
                      <button
                        className="user_button"
                        name="signUp"
                        onClick={this.props.detectClicked}
                      >
                        {toggled !== "signUp" && "Registrieren"}
                        {toggled === "signUp" && "Schließen"}
                      </button>
                    </React.Fragment>
                  )}
                  {user && (
                    <a className="profileLink" href="/user">
                      Profil
                    </a>
                  )}
                  {context.token && (
                    <button
                      className="user_button"
                      name="logOut"
                      onClick={() => {
                        this.context.logout();
                        logInStore(null);
                      }}
                    >
                      Abmelden
                    </button>
                  )}
                </div>
              </div>
            </div>
            <VisibleMenuScreen visible={toggled} loggedIn={loggedIn} />
          </React.Fragment>
        )}
      </AuthContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { logInStore })(HiddenNavbar);
