import React, { useState } from "react";
import "./ActivationComponent.scss";
import { ClimbingBoxLoader } from "react-spinners";

export default function ActivationComponent(props) {
  const { token } = props.match.params;
  const [loaded, setLoadingState] = useState(true);
  const [error, setErrorState] = useState(null);
  const [success, setSuccessState] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVal, setPasswordVal] = useState("");

  const startResetting = e => {
    e.preventDefault();
    setLoadingState(false);
    setErrorState(null);
    setSuccessState(null);
    fetch(`${process.env.REACT_APP_URL}`, {
      method: "POST",
      body: JSON.stringify({
        query: `
                mutation {
                    resetPassword(input: {
                        email: "${email}",
                        token: "${token}",
                        newPassword: "${password}",
                        newPasswordVal: "${passwordVal}"
                        }) 
                    {
                        firstName
                    }
                }
            `
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(resData => {
        if (!resData.hasOwnProperty("errors")) {
          setErrorState(null);
          setEmail("");
          setPassword("");
          setPasswordVal("");
          setLoadingState(true);
          setSuccessState(
            `Hallo ${resData.data.resetPassword.firstName}! Dein Passwort wurde erfolgreich zurückgesetzt.`
          );
        } else {
          setErrorState(resData.errors[0].message);
        }
        return;
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div className="activation-component">
      <h3>Passwort zurücksetzen</h3>
      {!loaded && (
        <div className="climbing">
          <ClimbingBoxLoader />
        </div>
      )}
      {loaded && (
        <form className="resetfields">
          <input
            type="email"
            placeholder="E-Mail Adresse"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Neues Passwort"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <input
            type="password"
            placeholder="Neues Passwort bestätigen"
            value={passwordVal}
            onChange={e => setPasswordVal(e.target.value)}
          />
          <input
            type="submit"
            onSubmit={e => startResetting(e)}
            onClick={e => startResetting(e)}
            value="Zurücksetzen"
          />
        </form>
      )}
      {(error || success) && (
        <div className="message">
          <p className={error ? "error" : "success"}>
            {error ? error : success}
          </p>
        </div>
      )}
    </div>
  );
}
