import React, { Component } from "react";

/*
  Next Steps: combine check-out and new-cart.css;
*/
import "./Checkout.scss";
import "./shoppingCheckout.scss";
import CheckOutItem from "./CheckOutItem";
import {
  getTimes,
  checkDate
} from "../../landing_page/header/openingTime/DateFunctions";
import EmptyCart from "./EmptyCart";
import { connect } from "react-redux";
import { renderTotalPrice } from "../../helpfunctions";
import { toggleCheckoutModal } from "../../../actions";
import { setTypeVal, setTimeVal } from "../../../actions/basic";

export class ShoppingCheckOut extends Component {
  constructor(props) {
    super(props);
    this.chooseTime = React.createRef();
  }

  state = {
    checkoutItems: this.props.checkout.checkoutItems,
    total: [],
    orderTypeVal: "Liefern lassen",
    orderTimeVal: "Direktbestellung",
    visibleCart: true,
    opened: false,
    openShareModal: false,
    wishTime: new Date().toLocaleTimeString().substring(0, 5),
    wrongTime: ""
  };

  checkOrderClick = () => {
    const { restaurant } = this.props;
    let times = getTimes(restaurant.openingTime);
    let time = this.chooseTime.current;
    console.log(this.state.wishTime);
    if (!times) {
      this.setState({
        wrongTime: `${restaurant.name} hat heute geschlossen.`
      });
      return;
    }
    if (time && time.value) {
      let timeVal = parseInt(time.value.replace(":", ""));
      function returnIntTime(nonIntTime) {
        return parseInt(nonIntTime.replace(":", ""));
      }
      let timeMinInt = returnIntTime(times.min);
      let timeMaxInt = returnIntTime(times.max);
      if (
        !this.state.wrongTime &&
        (timeVal > timeMaxInt || timeVal < timeMinInt + 30)
      ) {
        this.setState({
          wrongTime:
            "Bitte Zeit zwischen " +
            times.min.replace("00", "30") +
            " Uhr und " +
            times.max +
            " Uhr auswählen!"
        });
        return;
      } else if (
        this.state.wrongTime.includes("Bitte Zeit zwischen") &&
        (timeVal > timeMaxInt || timeVal < timeMinInt + 30)
      ) {
        return;
      } else if (timeVal < timeMaxInt && timeVal > timeMinInt) {
        let timeValStr = timeVal.toString();
        const rehydrate = JSON.parse(localStorage.getItem("cartState"));
        localStorage.setItem(
          "cartState",
          JSON.stringify({
            ...rehydrate,
            wishTime: timeValStr.substr(0, 2) + ":" + timeValStr.substr(2)
          })
        );
        this.setState({ wrongTime: "" });
      }
    }
    window.open(`/${restaurant.url}/closing`, "_self");
  };

  componentWillUnmount() {
    localStorage.setItem(
      "cartState",
      JSON.stringify({
        total: this.state.total,
        orderTypeVal: this.state.orderTypeVal,
        orderTimeVal: this.state.orderTimeVal,
        visibleCart: this.state.visibleCart
      })
    );
  }

  componentWillMount() {
    const { openingTime } = this.props.restaurant;
    const rehydrate = JSON.parse(localStorage.getItem("cartState"));
    this.setState(rehydrate);
    let dates = checkDate(openingTime);
    this.setState((state, props) => {
      return {
        opened: dates.currOpen
      };
    });
  }

  deleteCart = () => {
    this.props.deleteCart();
  };

  handleChange = (e, stateName) => {
    console.log("änderung incoming");
    this.setState({ [stateName]: e.target.value }, () => {
      localStorage.setItem(
        "cartState",
        JSON.stringify({
          total: this.state.total,
          orderTypeVal: this.state.orderTypeVal,
          orderTimeVal: this.state.orderTimeVal,
          visibleCart: this.state.visibleCart,
          wishTime:
            this.state.orderTimeVal === "Vorbestellung"
              ? new Date().toLocaleTimeString().substring(0, 5)
              : null
        })
      );
    });
  };

  renderCart = () => {
    const { checkoutItems } = this.props.checkout;
    return (
      <React.Fragment>
        {checkoutItems.map((item, i) => {
          let size;
          item.size !== "Normal" ? (size = "(" + item.size + ")") : (size = "");
          return (
            <CheckOutItem
              key={i}
              number={i}
              size={size}
              item={item}
              returnPrice={priceObj => renderTotalPrice(priceObj)}
            />
          );
        })}
      </React.Fragment>
    );
  };

  shareCart = () => {
    this.setState({ openShareModal: !this.state.openShareModal });
  };

  showCart = typeVal => {
    this.setState({ visibleCart: true, orderTypeVal: typeVal });
  };

  showCartAgain = () => {
    this.setState({ visibleCart: true });
    this.child.deleteOrderDoneInfo();
  };

  render() {
    const {
      border,
      checkout,
      deliveryInfo,
      restaurant,
      toggleCheckoutModal
    } = this.props;
    const { h } = border;
    let { service } = this.props.restaurant;
    let { checkoutItems, total } = checkout;
    let { wrongTime, opened, orderTimeVal, orderTypeVal } = this.state;
    let deliveryCost =
      orderTypeVal === "Liefern lassen" ? deliveryInfo.cost : 0;
    let wishOrderTime = "";
    let times = getTimes(restaurant.openingTime);
    let date = new Date();
    let currTime = date.toLocaleTimeString();
    console.log(orderTimeVal);
    if (this.state.orderTimeVal === "Vorbestellung") {
      const rehydrate = JSON.parse(localStorage.getItem("cartState"));
      wishOrderTime = (
        <div className="render-order-type">
          <input
            type="time"
            name="wishTime"
            min={times.min}
            max={times.max}
            defaultValue={currTime.slice(0, -3)}
            onChange={e => {
              localStorage.setItem(
                "cartState",
                JSON.stringify({
                  ...rehydrate,
                  orderTimeVal: "Vorbestellung",
                  wishTime: e.target.value
                })
              );
              this.setState({ wishTime: e.target.value });
            }}
            ref={this.chooseTime}
          />
        </div>
      );
    }
    if (checkoutItems.length === 0) {
      return (
        <EmptyCart
          handleSucessfullMsg={msg => this.props.handleSucessfullMsg(msg)}
        />
      );
    }
    let minWorth = null;
    if (service.minOrderWorth) {
      if (this.state.orderTypeVal === "Liefern lassen") {
        let minWorthInfo = "";
        if (total < service.minOrderWorth) {
          minWorthInfo = "wrong";
        }
        minWorth = (
          <p className={`min-worth-order ${minWorthInfo}`}>
            Mindestbestellwert{" "}
            {service.minOrderWorth.toFixed(2).replace(".", ",")}€
          </p>
        );
      }
    }
    let newArray = Array.from(checkoutItems, item => item.name);
    let nameArray = [...new Set(newArray)];
    return (
      <div className={`check-out ${h <= 812 ? "smart" : ""}`}>
        <div className="check-out-body">
          {h > 812 && (
            <>
              <div className="checkout-items">{this.renderCart()}</div>
              <div className="checkout-total">
                <p>
                  Gesamt {(total + deliveryCost).toFixed(2).replace(".", ",")}€
                </p>
                {minWorth}
              </div>
            </>
          )}
          {h <= 812 && (
            <div className="smart-checkout-items">
              <p>
                Du hast {newArray.length} Gerichte für insgesamt{" "}
                {total.toFixed(2).replace(".", ",")}€ im Warenkorb
                {nameArray.length > 3 &&
                  `, darunter ${nameArray[0]}, ${
                    nameArray[1]
                  } und ${nameArray.length - 1} ${
                    nameArray.length - 1 > 1
                      ? "weitere Gerichte."
                      : "weiteres Gericht."
                  }`}
                {nameArray.length <= 3 && `: ${nameArray.join(", ")}.`}
              </p>
              <button
                onClick={() => toggleCheckoutModal(true)}
                className="set-modal-btn"
              >
                Hier klicken zum einsehen und bearbeiten des Warenkorbs
              </button>
            </div>
          )}
        </div>
        <div className={`check-out-end ${h <= 812 ? "smart" : ""}`}>
          <div className="render-checkout-select">
            {orderTypeVal === "Liefern lassen" && (
              <p>
                Die Lieferkosten betragen{" "}
                {deliveryInfo.cost.toFixed(2).replace(".", ",")}€
              </p>
            )}
          </div>
          <div className="order-type-val">
            {service.indoor && (
              <button
                className={
                  this.state.orderTypeVal === "Vor Ort essen" ? "active" : ""
                }
                key={"indoor"}
                value={"Vor Ort essen"}
                onClick={e => {
                  this.handleChange(e, "orderTypeVal");
                  this.props.setTypeVal("Vor Ort essen");
                }}
              >
                Vor Ort essen
              </button>
            )}
            {service.driveIn && (
              <button
                className={
                  this.state.orderTypeVal === "Zum Mitnehmen" ? "active" : ""
                }
                key={"driveIn"}
                value="Zum Mitnehmen"
                onClick={e => {
                  this.handleChange(e, "orderTypeVal");
                  this.props.setTypeVal("Zum Mitnehmen");
                }}
              >
                Zum Mitnehmen
              </button>
            )}
            {service.delivery && (
              <button
                className={
                  this.state.orderTypeVal === "Liefern lassen" ? "active" : ""
                }
                key={"delivery"}
                value={"Liefern lassen"}
                onClick={e => {
                  this.handleChange(e, "orderTypeVal");
                  this.props.setTypeVal("Liefern lassen");
                }}
              >
                Liefern lassen
              </button>
            )}
          </div>
          <div className="order-type-val">
            <button
              className={orderTimeVal === "Direktbestellung" ? "active" : ""}
              onClick={e => {
                this.handleChange(e, "orderTimeVal");
                this.props.setTimeVal("Direktbestellung");
              }}
              value="Direktbestellung"
            >
              Direktbestellung
            </button>
            <button
              className={orderTimeVal === "Vorbestellung" ? "active" : ""}
              onClick={e => {
                this.handleChange(e, "orderTimeVal");
                this.props.setTimeVal("Vorbestellung");
              }}
              value="Vorbestellung"
            >
              Vorbestellung
            </button>
          </div>
          {wishOrderTime}
          {((orderTimeVal === "Direktbestellung" && !opened) ||
            (orderTypeVal === "Liefern lassen" &&
              service.minOrderWorth >= total)) && (
            <React.Fragment>
              <div className="checkout-next-step-button">
                <button onClick={() => {}} disabled>
                  Bestellen
                </button>
                {!(orderTimeVal === "Vorbestellung") && !opened && (
                  <p>
                    Außerhalb der Öffnungszeiten kann keine Bestellung getätigt
                    werden. Dein Warenkorb bleibt gespeichert, bis geöffnet
                    wird!
                  </p>
                )}
                {orderTypeVal === "Liefern lassen" &&
                  !(service.minOrderWorth <= total) && (
                    <p>Der Mindestbestellwert wurde nicht erreicht.</p>
                  )}
              </div>
            </React.Fragment>
          )}
          {((orderTimeVal === "Direktbestellung" && opened) ||
            !(orderTimeVal === "Direktbestellung")) &&
            ((orderTypeVal === "Liefern lassen" &&
              service.minOrderWorth <= total) ||
              (orderTypeVal === "Zum Mitnehmen" ||
                orderTypeVal === "Vor Ort essen")) && (
              <div className="checkout-next-step-button open">
                <button
                  onClick={() => {
                    this.checkOrderClick(times.min, times.max);
                  }}
                >
                  Bestellen
                </button>
                {orderTimeVal === "Vorbestellung" && wrongTime && (
                  <p>{wrongTime}</p>
                )}
              </div>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  restaurant: state.restaurant,
  checkout: state.checkout,
  deliveryInfo: state.restaurant.deliveryInfo,
  border: state.border
});

export default connect(
  mapStateToProps,
  { toggleCheckoutModal, setTypeVal, setTimeVal }
)(ShoppingCheckOut);
