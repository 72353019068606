import React, { Component } from "react";
import { Mutation } from "react-apollo";

import { updateDoneState } from "../queries";

const UPDATE_DONE_STATE = updateDoneState;

export class SingleOrder extends Component {
  state = {
    doneState: this.props.order.done,
    whenDone: null,
    whereDone: null,
    processed: 0,
    showInfo: false,
    showMessageField: false,
    userMessage: "",
    showPhone: false,
  };

  calculateTime = (timeNow, whenDone) => {
    let date = new Date();
    let hrsMins = timeNow
      ? timeNow.split(":")
      : `${date.getHours}:${date.getMinutes}`.split(":");
    let addValue = parseInt(
      whenDone
        ? whenDone.replace("ca. ", "")
        : this.state.whenDone
        ? this.state.whenDone.replace("ca. ", "")
        : 0
    );
    date.setHours(hrsMins[0]);
    date.setMinutes(hrsMins[1]);
    let newDateObj = new Date(date.getTime() + addValue * 60000);
    return `${newDateObj.getHours()}:${
      newDateObj.getMinutes() < 10
        ? "0" + newDateObj.getMinutes()
        : newDateObj.getMinutes()
    }`;
  };

  sendMessage = (id, message) => {
    const { doneState } = this.state;

    if (message.trim() === "") {
      alert("Du kannst keine Nachricht ohne Inhalt senden!");
      return;
    }
    if (!doneState) {
      this.setState({ ...this.state, doneState: true });
    }
    fetch(`${process.env.REACT_APP_URL}`, {
      method: "POST",
      body: JSON.stringify({
        query: `
                  mutation { 
                    sendMessage(
                      orderId: "${id}"
                      message: "${message}"
                    ) 
                  }
                `,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resData) => {
        this.setState({ userMessage: "" });
        return;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  changeDoneState = (e, callback) => {
    const { doneState, whereDone, whenDone, processed } = this.state;
    if (!doneState) {
      if (whenDone !== e.target.value) {
        this.setState({
          doneState: true,
          whenDone: e.target.value,
          processed: processed + 1,
        });
      } else {
        this.setState({
          doneState: false,
          whenDone: null,
        });
      }
    } else {
      return;
    }
    callback();
  };

  renderTimeInformation = (order) => {
    if (order.timeType === "Direktbestellung") return;
    else return `- Vorbestellung für ${order.wishTime} Uhr`;
  };

  renderTypeInformation = (type, adress) => {
    if (type === "Lieferung")
      return `${type} an: ${adress.street} ${adress.houseNr}, ${adress.zip} ${adress.city}`;
    else return `${type}`;
  };

  render() {
    const { order, partner } = this.props;
    const { doneState, whenDone, processed, showInfo } = this.state;
    let doneStyle, doneMark;
    if (!doneState) {
      doneStyle = "not-done";
      doneMark = "!";
    } else {
      doneStyle = "done";
      doneMark = "✓";
    }
    let date = new Date();
    let day = date.getDate(),
      month = date.getMonth() + 1,
      year = date.getFullYear();
    let datestring = `${day < 10 ? "0" + day : day}.${
      month < 10 ? "0" + month : month
    }.${year}`;

    console.log(order);

    if (
      partner.name == "Da Giovanni" &&
      doneState &&
      !order.date.includes(datestring)
    ) {
      return null;
    }
    return (
      <Mutation mutation={UPDATE_DONE_STATE}>
        {(doOrder, { data }) => (
          <div className={`singleOrder ${doneStyle}`}>
            <div className="singleOrderHeader">
              <p>
                Bestellung von {order.orderer.firstName}{" "}
                {order.orderer.lastName}
              </p>
              {doneState &&
                (order.orderTime.timeType !== "Vorbestellung" ? (
                  <p>
                    {this.calculateTime(order.timeHrs, order.whenDone) !==
                    "NaN:NaN"
                      ? "Fertig: " +
                        this.calculateTime(order.timeHrs, order.whenDone) +
                        " Uhr"
                      : ""}
                  </p>
                ) : (
                  <p>Vorbestellung: {order.orderTime.wishTime} Uhr</p>
                ))}
            </div>
            {order.orderer.phone ? (
              <>
                {this.state.showPhone && (
                  <p className="phone-number">
                    Telefonnummer: {order.orderer.phone}
                  </p>
                )}
                <button
                  className="button-style"
                  onClick={() =>
                    this.setState({ showPhone: !this.state.showPhone })
                  }
                >
                  Telefonnummer{" "}
                  {this.state.showPhone ? "verbergen" : "anzeigen"}
                </button>
              </>
            ) : (
              <p className="phone-number">Keine Telefonnummer angegeben</p>
            )}
            {this.state.showMessageField ? (
              <div className="order-message">
                <textarea
                  value={this.state.userMessage}
                  rows="4"
                  cols="50"
                  onChange={(e) =>
                    this.setState({ userMessage: e.target.value })
                  }
                />
                <div>
                  <button
                    onClick={(e) => {
                      this.sendMessage(order._id, this.state.userMessage);
                      this.setState({
                        showMessageField: !this.state.showMessageField,
                      });
                      !this.state.doneState &&
                        this.changeDoneState(e, () =>
                          processed === 0
                            ? doOrder({
                                variables: {
                                  type: order._id,
                                  time: "k.A.",
                                },
                              })
                            : null
                        );
                    }}
                  >
                    Nachricht an {order.orderer.firstName}
                  </button>
                  <button
                    onClick={() =>
                      this.setState({
                        showMessageField: !this.state.showMessageField,
                      })
                    }
                  >
                    Zuklappen
                  </button>
                </div>
              </div>
            ) : (
              <button
                className="button-style"
                onClick={() =>
                  this.setState({
                    showMessageField: !this.state.showMessageField,
                  })
                }
              >
                Nachricht an {order.orderer.firstName}
              </button>
            )}

            <div className="order-date-and-details">
              <p>
                Bestellt am {order.date} - {order.timeHrs}
              </p>
              <div>
                <button
                  onClick={() =>
                    fetch(`${process.env.REACT_APP_URL}`, {
                      method: "POST",
                      body: JSON.stringify({
                        query: `
                        mutation {
                          printOrder(_id: "${order._id}")
                        }
                                `,
                      }),
                      headers: {
                        "Content-Type": "application/json",
                      },
                    })
                      .then((res) => res.json())
                      .then((resData) => {
                        console.log(resData);
                      })
                      .catch((err) => {
                        console.log(err);
                      })
                  }
                >
                  Erneut drucken
                </button>
                <button
                  onClick={() =>
                    this.setState({ showInfo: !this.state.showInfo })
                  }
                >
                  Details
                </button>
              </div>
            </div>
            {order.orderTime.timeType !== "Vorbestellung" &&
              partner.service.delivery.duration && (
                <div className="time-done-box">
                  {partner.service.delivery.duration.map((time) => (
                    <button
                      onClick={(e) => {
                        console.log(e.target.value);
                        console.log(order);
                        this.changeDoneState(
                          e,
                          () =>
                            !doneState &&
                            doOrder({
                              variables: {
                                type: order._id,
                                time: "ca. " + e.target.value,
                              },
                            })
                        );
                      }}
                      key={time}
                      value={time}
                      className={`time-done-btn done-btn ${doneStyle}`}
                    >
                      {`ca. ${time} Min `}
                      {(order.whenDone &&
                        order.whenDone.substring(4) == time) ||
                      whenDone === time
                        ? doneStyle === "done"
                          ? doneMark
                          : null
                        : null}
                    </button>
                  ))}
                </div>
              )}
            {!doneState && order.orderTime.timeType === "Vorbestellung" && (
              <div className="pre-order-date-and-details">
                <p>Vorbestellung für {order.orderTime.wishTime} Uhr</p>
                {!this.state.showMessageField && (
                  <>
                    <button
                      onClick={(e) => {
                        this.changeDoneState(
                          e,
                          () =>
                            !doneState &&
                            doOrder({
                              variables: {
                                type: order._id,
                                time: "Bestätigte",
                              },
                            })
                        );
                      }}
                      className={`time-done-btn done-btn ${doneStyle}`}
                    >
                      Zeit bestätigen
                    </button>

                    <button
                      onClick={(e) => {
                        this.setState({
                          showMessageField: true,
                        });
                      }}
                      className={`time-done-btn done-btn ${doneStyle}`}
                    >
                      Nachricht an {order.orderer.firstName}
                    </button>
                  </>
                )}
              </div>
            )}
            {showInfo && (
              <div className="order-informations">
                <h2>Bestelldetails</h2>
                <p>
                  {this.renderTypeInformation(order.type, order.orderer)}{" "}
                  {this.renderTimeInformation(order.orderTime)}
                </p>
                {order.orderedProducts.map((product, i) => {
                  console.log(product);
                  return (
                    <div key={i} className="order-details-single-order">
                      <p className="name">
                        {product.quantity}x {product.name}
                      </p>
                      {product.customizations
                        .filter(
                          (o) =>
                            o.name !== "Größe" || o.selectedOption !== "Normal"
                        )
                        .map((o) => (
                          <p>
                            {o.name}: {o.selectedOption}
                          </p>
                        ))}
                      <p>
                        Einzelpreis:{" "}
                        {parseFloat(product.price).toFixed(2).replace(".", ",")}
                        €
                      </p>
                    </div>
                  );
                })}
                <p className="final-price">
                  Gesamtpreis: {order.total.toFixed(2).replace(".", ",")}€
                </p>
                {order.orderer.moreInformation && (
                  <p>
                    {order.orderer.firstName} hat folgende Mitteilung beigefügt:{" "}
                    {order.orderer.moreInformation}
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default SingleOrder;
