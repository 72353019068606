export const addCheckOutItem = item => {
  return {
    type: "SET_CHECKOUT",
    payload: item
  };
};

export const deleteCheckOut = () => {
  return {
    type: "DELETE_CHECKOUT"
  };
};

export const resetCheckOut = item => {
  return {
    type: "REHYDRATE_CHECKOUT",
    payload: item
  };
};

export const increment = item => {
  return {
    type: "INCREMENT",
    payload: item
  };
};

export const decrement = item => {
  return {
    type: "DECREMENT",
    payload: item
  };
};

export const removeCheckOutItem = item => {
  return {
    type: "FILTER_CHECKOUT",
    payload: item
  };
};
