import { gql } from "apollo-boost";

const showAllRestaurants = () => {
  return gql`
    query {
      showAllRestaurants {
        name
        phone
        image
        service {
          indoor
          driveIn
          delivery
          minOrderWorth
        }
        openingTime {
          sunday {
            start
            end
            breakstart
            breakend
            special {
              minDate
              maxDate
              time {
                start
                end
                breakstart
                breakend
              }
            }
          }
          monday {
            start
            end
            breakstart
            breakend
            special {
              minDate
              maxDate
              time {
                start
                end
                breakstart
                breakend
              }
            }
          }
          tuesday {
            start
            end
            breakstart
            breakend
            special {
              minDate
              maxDate
              time {
                start
                end
                breakstart
                breakend
              }
            }
          }
          wednesday {
            start
            end
            breakstart
            breakend
            special {
              minDate
              maxDate
              time {
                start
                end
                breakstart
                breakend
              }
            }
          }
          thursday {
            start
            end
            breakstart
            breakend
            special {
              minDate
              maxDate
              time {
                start
                end
                breakstart
                breakend
              }
            }
          }
          friday {
            start
            end
            breakstart
            breakend
            special {
              minDate
              maxDate
              time {
                start
                end
                breakstart
                breakend
              }
            }
          }
          saturday {
            start
            end
            breakstart
            breakend
            special {
              minDate
              maxDate
              time {
                start
                end
                breakstart
                breakend
              }
            }
          }
        }
      }
    }
  `;
};

const queryAllOrders = (restaurantName) => gql`
  query {
    showAllOrders(restaurant: "${restaurantName}") {
      _id
      type
      restaurantName
      firstNameOrderer
      lastNameOrderer
      eMailOrderer
      orderTime {
        timeType
        wishTime
      }
      phone
      adress {
        zip
        city
        street
        houseNr
      }
      orderBody {
        orderedDishes {
          quantity
          name
          toppings
          garnish
          secondGarnish
          salad
          sauce
          type
          drink
          spice
          size
          price
          changes
          preMain {
            name 
            garnishes {name price doubled required}
            secondGarnishes {name price doubled required}
            info
            price
          }
          main {
            name 
            garnishes {name price doubled required}
            secondGarnishes {name price doubled required}
            info
            price
          }
          postMain {
            name 
            garnishes {name price doubled required}
            secondGarnishes {name price doubled required}
            info
            price
          }
        }
        total
      }
      done
      whenDone
      date
      timeHrs
      orderMessage
    }
  }
`;

const updateDoneState = gql`
  mutation doneOrder($type: ID, $time: String) {
    doneOrder(_id: $type, time: $time)
  }
`;

export { queryAllOrders, showAllRestaurants, updateDoneState };
