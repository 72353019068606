import React, { Component } from 'react';
import './insert-restaurant.css';

import Navbar from '../navigation/Navbar';
import InsertWeekday from './InsertWeekday';
import InsertMenu from './InsertMenu';

export class InsertRestaurant extends Component {
    constructor(props) {
        super(props);
        this.restaurantName = React.createRef();
        this.restaurantUrl = React.createRef();
        this.restaurantShortDescription = React.createRef();
        this.indoor = React.createRef();
    }

    state = {
        indoorValue: '',
        driveInValue: '',
        deliveryValue: '',
        monday: false,
        mondaySpecial: false,
        tuesday: false,
        tuesdaySpecial: false,
        wednesday: false,
        wednesdaySpecial: false,
        thursday: false,
        thursdaySpecial: false,
        friday: false,
        fridaySpecial: false,
        saturday: false,
        saturdaySpecial: false,
        sunday: false,
        sundaySpecial: false
    };

    checkValue = e => {
        if (e.target.name === 'indoor_service') {
            this.setState({
                indoorValue: e.target.value
            });
        } else if (e.target.name === 'driveIn_service') {
            this.setState({
                driveInValue: e.target.value
            });
        } else {
            this.setState({
                deliveryValue: e.target.value
            });
        }
    };

    checkWeekday = e => {
        let bool = Boolean(e.target.value);
        this.setState({ [e.target.name]: bool });
    };

    checkSpecialWeekday = e => {
        let bool = Boolean(e.target.value);
        this.setState({ [e.target.name]: bool });
    };

    render() {
        const {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
            mondaySpecial,
            tuesdaySpecial,
            wednesdaySpecial,
            thursdaySpecial,
            fridaySpecial,
            saturdaySpecial,
            sundaySpecial
        } = this.state;
        return (
            <React.Fragment>
                <Navbar />
                <div className="insert-restaurant">
                    <h1>Restaurant einfügen</h1>
                    <form>
                        <div className="insert-group">
                            <label htmlFor="restaurant-name">
                                Restaurant-Name
                            </label>
                            <input
                                type="text"
                                placeholder="Restaurant-Name"
                                ref={this.restaurantName}
                                className="col-10 col-md-6 col-lg-5"
                            />
                            <p className="label-info col-10 col-md-6 col-lg-5">
                                Bitte den vollständigen Restaurantnamen
                                eingeben.
                            </p>
                            <p className="label-example col-10 col-md-6 col-lg-5">
                                Beispiel: Gaststätte zur Linde
                            </p>
                        </div>
                        <div className="insert-group">
                            <label htmlFor="restaurant-name">URL</label>
                            <input
                                type="text"
                                placeholder="Geplante URL eingeben"
                                ref={this.restaurantUrl}
                                className="col-10 col-md-6 col-lg-5"
                            />
                            <p className="label-info col-10 col-md-6 col-lg-5">
                                Bitte eine taugliche, noch nicht vergebene URL
                                angeben und dabei auf Sonderzeichen o.ä.
                                verzichen. Bitte außerdem darauf achten, dass
                                die Worte(sofern mehrere Worte gewählt werden
                                sollen) klein- und zusammengeschrieben werden.{' '}
                            </p>
                            <p className="label-example col-10 col-md-6 col-lg-5">
                                Beispiel: www.doigastro.de/zurlinde ist der
                                URL-Name einzig: zurlinde
                            </p>
                        </div>
                        <div className="insert-group">
                            <label htmlFor="restaurant-name">
                                Kurzbeschreibung
                            </label>
                            <input
                                type="text"
                                placeholder="Kurzbeschreibung eingeben"
                                ref={this.restaurantShortDescription}
                                className="col-10 col-md-6 col-lg-5"
                            />
                            <p className="label-info col-10 col-md-6 col-lg-5">
                                Bitte einen Einzeiler eingeben, mit welchem Du
                                das Restaurant beschreiben würdest (oder das
                                Restaurant sich selbst beschreiben würde).
                            </p>
                            <p className="label-example col-10 col-md-6 col-lg-5">
                                Beispiel der Gaststätte zur Linde: Pizzeria,
                                kroatische &amp; deutsche Spezialitäten
                            </p>
                        </div>
                        <div className="insert-group">
                            <label htmlFor="restaurant-name">
                                Telefonnummer
                            </label>
                            <input
                                type="text"
                                placeholder="Telefonnummer eingeben"
                                ref={this.restaurantPhone}
                                className="col-10 col-md-6 col-lg-5"
                            />
                            <p className="label-info col-10 col-md-6 col-lg-5">
                                Bitte die Telefonnummer des Restaurants
                                eingeben.
                            </p>
                            <p className="label-example col-10 col-md-6 col-lg-5">
                                Beispiel-Format: 06464/387
                            </p>
                        </div>
                        <div className="insert-group">
                            <label htmlFor="restaurant-name">
                                Restaurantbild
                            </label>
                            <input
                                type="text"
                                placeholder="Link zu Restaurant-Bild eingeben"
                                ref={this.restaurantImage}
                                className="col-10 col-md-6 col-lg-5"
                            />
                            <p className="label-info col-10 col-md-6 col-lg-5">
                                Bitte den Link zum Bild des Restaurants
                                eingeben. Dieses Bild wird zunächst in den
                                Firebase-Datenstore hochgeladen und der Link
                                wird von da gewählt. Unwissenden muss dies
                                zunächst erklärt werden.
                            </p>
                            <p className="label-example col-10 col-md-6 col-lg-5">
                                Beispiel:
                            </p>
                        </div>
                        <div className="insert-group">
                            <label htmlFor="restaurant-name">
                                Nationalitäts-Spezialitäten
                            </label>
                            <input
                                type="text"
                                placeholder="Link zu Restaurant-Bild eingeben"
                                ref={this.restaurantFlags}
                                className="col-10 col-md-6 col-lg-5"
                            />
                            <p className="label-info col-10 col-md-6 col-lg-5">
                                Bitte die Länderkürzel innerhalb von " "
                                einfügen und jeweils mit Komma trennen. Solltest
                                du nicht wissen welches Länderkürzel gefragt
                                ist, kannst du{' '}
                                <a href="http://www.dornfinder.org/laenderkuerzel-nach-iso-3166-2.html">
                                    hier
                                </a>{' '}
                                nachschauen.
                            </p>
                            <p className="label-example col-10 col-md-6 col-lg-5">
                                Beispiel: Die Gaststätte zur Linde hat
                                Kroatische, italienische und deutsche
                                Spezialitäten (festgemacht an z.B. Cevapcici,
                                Pizza und Schnitzel). <br /> Eingegeben werden
                                müsste also: "HR", "IT", "DE" (inklusive der
                                Gänsefüßchen und Kommas dazwischen!)
                            </p>
                        </div>
                        <div className="insert-group">
                            <label htmlFor="restaurant-name">
                                Adresse des Restaurants
                            </label>
                            <input
                                type="text"
                                placeholder="Stadt/Ort eingeben"
                                ref={this.restaurantCity}
                                className="col-10 col-md-6 col-lg-5"
                            />
                            <input
                                type="text"
                                placeholder="Postleitzahl eingeben"
                                ref={this.restaurantZip}
                                className="col-10 col-md-6 col-lg-5"
                            />
                            <input
                                type="text"
                                placeholder="Straße eingeben"
                                ref={this.restaurantStreet}
                                className="col-10 col-md-6 col-lg-5"
                            />
                            <input
                                type="text"
                                placeholder="Hausnummer eingeben"
                                ref={this.restaurantHouseNr}
                                className="col-10 col-md-6 col-lg-5"
                            />
                            <p className="label-info col-10 col-md-6 col-lg-5">
                                Ich denke die Platzhalter der jeweiligen
                                Textfelder sind selbsterklärend.
                            </p>
                            <p className="label-example col-10 col-md-6 col-lg-5">
                                Beispiel: <br />
                                Stadt/Ort: Bottenhorn <br />
                                PLZ: 35080 <br />
                                Straße: Kirchstraße <br />
                                Hausnummer: 9b <br />
                            </p>
                        </div>
                        <div className="insert-group">
                            <label htmlFor="restaurant-name">
                                Service-Angebot
                            </label>
                            <p className="label-overwrite col-10 col-md-6 col-lg-5">
                                Bietet das Restaurant den Nutzern die
                                Möglichkeit, Bestellungen über doiGastro vor Ort
                                zu essen?
                            </p>
                            <fieldset className="label-example col-10 col-md-6 col-lg-5">
                                <input
                                    type="radio"
                                    id="indoor_true"
                                    name="indoor_service"
                                    ref={this.indoor}
                                    value="true"
                                    onChange={this.checkValue}
                                />
                                <label
                                    className="label_true_false"
                                    htmlFor="indoor_true"
                                >
                                    {' '}
                                    Ja
                                </label>
                                <br />
                                <input
                                    type="radio"
                                    id="indoor_false"
                                    name="indoor_service"
                                    ref={this.indoor}
                                    value="false"
                                    onChange={this.checkValue}
                                />
                                <label
                                    className="label_true_false"
                                    htmlFor="indoor_false"
                                >
                                    {' '}
                                    Nein
                                </label>
                            </fieldset>
                            <p className="label-overwrite col-10 col-md-6 col-lg-5">
                                Bietet das Restaurant den Nutzern die
                                Möglichkeit, Bestellungen über doiGastro
                                abzuholen?
                            </p>
                            <fieldset className="label-example col-10 col-md-6 col-lg-5">
                                <input
                                    type="radio"
                                    id="driveIn_true"
                                    name="driveIn_service"
                                    ref={this.driveIn}
                                    value="true"
                                />
                                <label
                                    className="label_true_false"
                                    htmlFor="driveIn_true"
                                >
                                    {' '}
                                    Ja
                                </label>
                                <br />
                                <input
                                    type="radio"
                                    id="driveIn_false"
                                    name="driveIn_service"
                                    ref={this.driveIn}
                                    value="false"
                                />
                                <label
                                    className="label_true_false"
                                    htmlFor="driveIn_false"
                                >
                                    {' '}
                                    Nein
                                </label>
                            </fieldset>
                            <p className="label-overwrite col-10 col-md-6 col-lg-5">
                                Bietet das Restaurant den Nutzern von doiGastro
                                einen Lieferdienst für die Gerichte an?
                            </p>
                            <fieldset className="label-example col-10 col-md-6 col-lg-5">
                                <input
                                    type="radio"
                                    id="delivery_true"
                                    name="delivery_service"
                                    ref={this.delivery}
                                    value="true"
                                />
                                <label
                                    className="label_true_false"
                                    htmlFor="delivery_true"
                                >
                                    {' '}
                                    Ja
                                </label>
                                <br />
                                <input
                                    type="radio"
                                    id="delivery_false"
                                    name="delivery_service"
                                    ref={this.delivery}
                                    value="false"
                                />
                                <label
                                    className="label_true_false"
                                    htmlFor="delivery_false"
                                >
                                    {' '}
                                    Nein
                                </label>
                            </fieldset>
                            <p className="label-info col-10 col-md-6 col-lg-5">
                                Ich denke diese Felder sind selbsterklärend.
                            </p>
                            <p className="label-example col-10 col-md-6 col-lg-5">
                                Beispiel: Ich denke diese Felder sind
                                selbsterklärend.
                            </p>
                        </div>
                        <div className="insert-group weekdays">
                            <label htmlFor="restaurant-name">
                                Öffnungszeiten
                            </label>
                            <InsertWeekday
                                day="monday"
                                day_state={monday}
                                special_state={mondaySpecial}
                                checkWeekday={this.checkWeekday}
                                checkSpecialWeekday={this.checkSpecialWeekday}
                                germanWords={['Montags', 'Montag']}
                            />
                            <InsertWeekday
                                day="tuesday"
                                day_state={tuesday}
                                special_state={tuesdaySpecial}
                                checkWeekday={this.checkWeekday}
                                checkSpecialWeekday={this.checkSpecialWeekday}
                                germanWords={['Dienstags', 'Dienstag']}
                            />
                            <InsertWeekday
                                day="wednesday"
                                day_state={wednesday}
                                special_state={wednesdaySpecial}
                                checkWeekday={this.checkWeekday}
                                checkSpecialWeekday={this.checkSpecialWeekday}
                                germanWords={['Mittwochs', 'Mittwoch']}
                            />
                            <InsertWeekday
                                day="thursday"
                                day_state={thursday}
                                special_state={thursdaySpecial}
                                checkWeekday={this.checkWeekday}
                                checkSpecialWeekday={this.checkSpecialWeekday}
                                germanWords={['Donnerstags', 'Donnerstag']}
                            />
                            <InsertWeekday
                                day="friday"
                                day_state={friday}
                                special_state={fridaySpecial}
                                checkWeekday={this.checkWeekday}
                                checkSpecialWeekday={this.checkSpecialWeekday}
                                germanWords={['Freitags', 'Freitag']}
                            />
                            <InsertWeekday
                                day="saturday"
                                day_state={saturday}
                                special_state={saturdaySpecial}
                                checkWeekday={this.checkWeekday}
                                checkSpecialWeekday={this.checkSpecialWeekday}
                                germanWords={['Samstags', 'Samstag']}
                            />
                            <InsertWeekday
                                day="sunday"
                                day_state={sunday}
                                special_state={sundaySpecial}
                                checkWeekday={this.checkWeekday}
                                checkSpecialWeekday={this.checkSpecialWeekday}
                                germanWords={['Sonntags', 'Sonntag']}
                            />
                        </div>
                        <InsertMenu />
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default InsertRestaurant;
