import React, { Component } from 'react';

import DishCategories from './menu-cart/DishCategories';

export class InsertMenu extends Component {
    render() {
        return (
            <div className="insert-group menu">
                <h1 htmlFor="restaurant-name">Speisekarte</h1>
                <DishCategories />
            </div>
        );
    }
}

export default InsertMenu;
