import React, { Component } from "react";
import Navbar from "../navigation/Navbar";

export class Datenschutz extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="footer-texts">
          <h1 className="datenschutz">Datenschutzerkl&auml;rung</h1>

          <h2>
            1&#41; Information &uuml;ber die Erhebung personenbezogener Daten
            und Kontaktdaten des Verantwortlichen
          </h2>

          <p>
            <b>1.1</b>&thinsp;Wir freuen uns, dass Sie unsere Website besuchen
            und bedanken uns f&uuml;r Ihr Interesse. Im Folgenden informieren
            wir Sie &uuml;ber den Umgang mit Ihren personenbezogenen Daten bei
            der Nutzung unserer Website. Personenbezogene Daten sind hierbei
            alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden
            k&ouml;nnen.
          </p>

          <p>
            <b>1.2</b>&thinsp;Verantwortlicher f&uuml;r die Datenverarbeitung
            auf dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO)
            ist Janick Wagner, Zum Sportplatz 4, 35080 Bottenhorn, Deutschland,
            Tel.: <a href="tel:+491723402813">0172 3402813</a>, E-Mail:{" "}
            <a href="mailto:mail@doigastro.de">mail@doigastro.de</a>. Der
            f&uuml;r die Verarbeitung von personenbezogenen Daten
            Verantwortliche ist diejenige nat&uuml;rliche oder juristische
            Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke
            und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
          </p>

          <p>
            <b>1.3</b>&thinsp;Diese Website nutzt aus Sicherheitsgr&uuml;nden
            und zum Schutz der &Uuml;bertragung personenbezogener Daten und
            anderer vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an
            den Verantwortlichen) eine SSL-bzw. TLS-Verschl&uuml;sselung. Sie
            k&ouml;nnen eine verschl&uuml;sselte Verbindung an der Zeichenfolge
            &bdquo;https://&ldquo; und dem Schloss-Symbol in Ihrer Browserzeile
            erkennen.
          </p>

          <h2>2&#41; Datenerfassung beim Besuch unserer Website</h2>

          <p>
            Bei der blo&szlig; informatorischen Nutzung unserer Website, also
            wenn Sie sich nicht registrieren oder uns anderweitig Informationen
            &uuml;bermitteln, erheben wir nur solche Daten, die Ihr Browser an
            unseren Server &uuml;bermittelt (sog.
            &bdquo;Server-Logfiles&ldquo;). Wenn Sie unsere Website aufrufen,
            erheben wir die folgenden Daten, die f&uuml;r uns technisch
            erforderlich sind, um Ihnen die Website anzuzeigen:
          </p>

          <ul className="datstz-list">
            <li>Unsere besuchte Website</li>
            <li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</li>
            <li>Menge der gesendeten Daten in Byte</li>
            <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
            <li>Verwendeter Browser</li>
            <li>Verwendetes Betriebssystem</li>
            <li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
          </ul>

          <p>
            Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO
            auf Basis unseres berechtigten Interesses an der Verbesserung der
            Stabilit&auml;t und Funktionalit&auml;t unserer Website. Eine
            Weitergabe oder anderweitige Verwendung der Daten findet nicht
            statt. Wir behalten uns allerdings vor, die Server-Logfiles
            nachtr&auml;glich zu &uuml;berpr&uuml;fen, sollten konkrete
            Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.
          </p>

          <h2>3&#41; Cookies</h2>

          <p>
            Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung
            bestimmter Funktionen zu erm&ouml;glichen, verwenden wir auf
            verschiedenen Seiten sogenannte Cookies. Hierbei handelt es sich um
            kleine Textdateien, die auf Ihrem Endger&auml;t abgelegt werden.
            Einige der von uns verwendeten Cookies werden nach dem Ende der
            Browser-Sitzung, also nach Schlie&szlig;en Ihres Browsers, wieder
            gel&ouml;scht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf
            Ihrem Endger&auml;t und erm&ouml;glichen uns oder unseren
            Partnerunternehmen (Cookies von Drittanbietern), Ihren Browser beim
            n&auml;chsten Besuch wiederzuerkennen (sog. persistente Cookies).
            Werden Cookies gesetzt, erheben und verarbeiten diese im
            individuellen Umfang bestimmte Nutzerinformationen wie Browser- und
            Standortdaten sowie IP-Adresswerte. Persistente Cookies werden
            automatisiert nach einer vorgegebenen Dauer gel&ouml;scht, die sich
            je nach Cookie unterscheiden kann.
          </p>

          <p>
            Teilweise dienen die Cookies dazu, durch Speicherung von
            Einstellungen den Bestellprozess zu vereinfachen (z.B. Merken des
            Inhalts eines virtuellen Warenkorbs f&uuml;r einen sp&auml;teren
            Besuch auf der Website). Sofern durch einzelne von uns eingesetzte
            Cookies auch personenbezogene Daten verarbeitet werden, erfolgt die
            Verarbeitung gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO entweder
            zur Durchf&uuml;hrung des Vertrages oder gem&auml;&szlig; Art. 6
            Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten Interessen an
            der bestm&ouml;glichen Funktionalit&auml;t der Website sowie einer
            kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs.
          </p>

          <p>
            Wir arbeiten unter Umst&auml;nden mit Werbepartnern zusammen, die
            uns helfen, unser Internetangebot f&uuml;r Sie interessanter zu
            gestalten. Zu diesem Zweck werden f&uuml;r diesen Fall bei Ihrem
            Besuch unserer Website auch Cookies von Partnerunternehmen auf Ihrer
            Festplatte gespeichert (Cookies von Drittanbietern). Wenn wir mit
            vorbenannten Werbepartnern zusammenarbeiten, werden Sie &uuml;ber
            den Einsatz derartiger Cookies und den Umfang der jeweils erhobenen
            Informationen innerhalb der nachstehenden Abs&auml;tze individuell
            und gesondert informiert.
          </p>

          <p>
            Bitte beachten Sie, dass Sie Ihren Browser so einstellen
            k&ouml;nnen, dass Sie &uuml;ber das Setzen von Cookies informiert
            werden und einzeln &uuml;ber deren Annahme entscheiden oder die
            Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell
            ausschlie&szlig;en k&ouml;nnen. Jeder Browser unterscheidet sich in
            der Art, wie er die Cookie-Einstellungen verwaltet. Diese ist in dem
            Hilfemen&uuml; jedes Browsers beschrieben, welches Ihnen
            erl&auml;utert, wie Sie Ihre Cookie-Einstellungen &auml;ndern
            k&ouml;nnen. Diese finden Sie f&uuml;r die jeweiligen Browser unter
            den folgenden Links:
          </p>

          <p>
            Internet Explorer:{" "}
            <a href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies">
              https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
            <br />
            Firefox:{" "}
            <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen">
              https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
            </a>
            <br />
            Chrome:{" "}
            <a href="https://support.google.com/chrome/answer/95647?hl=de&amp;hlrm=en">
              https://support.google.com/chrome/answer/95647?hl=de&amp;hlrm=en
            </a>
            <br />
            Safari:{" "}
            <a href="https://support.apple.com/de-de/guide/safari/sfri11471/mac">
              https://support.apple.com/de-de/guide/safari/sfri11471/mac
            </a>
            <br />
            Opera:{" "}
            <a href="https://help.opera.com/en/latest/web-preferences/#cookies">
              https://help.opera.com/en/latest/web-preferences/#cookies
            </a>
          </p>

          <p>
            Bitte beachten Sie, dass bei Nichtannahme von Cookies die
            Funktionalit&auml;t unserer Website eingeschr&auml;nkt sein kann.
          </p>

          <h2>4&#41; Kontaktaufnahme</h2>

          <p>
            Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder
            E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle
            eines Kontaktformulars erhoben werden, ist aus dem jeweiligen
            Kontaktformular ersichtlich. Diese Daten werden ausschlie&szlig;lich
            zum Zweck der Beantwortung Ihres Anliegens bzw. f&uuml;r die
            Kontaktaufnahme und die damit verbundene technische Administration
            gespeichert und verwendet. Rechtsgrundlage f&uuml;r die Verarbeitung
            dieser Daten ist unser berechtigtes Interesse an der Beantwortung
            Ihres Anliegens gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO. Zielt
            Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist
            zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung Art. 6
            Abs. 1 lit. b DSGVO. Ihre Daten werden nach abschlie&szlig;ender
            Bearbeitung Ihrer Anfrage gel&ouml;scht. Dies ist der Fall, wenn
            sich aus den Umst&auml;nden entnehmen l&auml;sst, dass der
            betroffene Sachverhalt abschlie&szlig;end gekl&auml;rt ist und
            sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
          </p>

          <h2>
            5&#41; Datenverarbeitung bei Er&ouml;ffnung eines Kundenkontos und
            zur Vertragsabwicklung
          </h2>

          <p>
            Gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO werden personenbezogene
            Daten weiterhin erhoben und verarbeitet, wenn Sie uns diese zur
            Durchf&uuml;hrung eines Vertrages oder bei der Er&ouml;ffnung eines
            Kundenkontos mitteilen. Welche Daten erhoben werden, ist aus den
            jeweiligen Eingabeformularen ersichtlich. Eine L&ouml;schung Ihres
            Kundenkontos ist jederzeit m&ouml;glich und kann durch eine
            Nachricht an die o.g. Adresse des Verantwortlichen erfolgen. Wir
            speichern und verwenden die von Ihnen mitgeteilten Daten zur
            Vertragsabwicklung. Nach vollst&auml;ndiger Abwicklung des Vertrages
            oder L&ouml;schung Ihres Kundenkontos werden Ihre Daten mit
            R&uuml;cksicht auf steuer- und handelsrechtliche
            Aufbewahrungsfristen gesperrt und nach Ablauf dieser Fristen
            gel&ouml;scht, sofern Sie nicht ausdr&uuml;cklich in eine weitere
            Nutzung Ihrer Daten eingewilligt haben oder eine gesetzlich erlaubte
            weitere Datenverwendung von unserer Seite vorbehalten wurde,
            &uuml;ber die wir Sie nachstehend entsprechend informieren.
          </p>

          <h2>6&#41; Datenverarbeitung zur Bestellabwicklung</h2>

          <p>
            <b>6.1</b>&thinsp;Zur Abwicklung Ihrer Bestellung arbeiten wir mit
            dem / den nachstehenden Dienstleistern zusammen, die uns ganz oder
            teilweise bei der Durchf&uuml;hrung geschlossener Vertr&auml;ge
            unterst&uuml;tzen. An diese Dienstleister werden nach Ma&szlig;gabe
            der folgenden Informationen gewisse personenbezogene Daten
            &uuml;bermittelt.
          </p>

          <p>
            Die von uns erhobenen personenbezogenen Daten werden im Rahmen der
            Vertragsabwicklung an das mit der Lieferung beauftragte
            Transportunternehmen weitergegeben, soweit dies zur Lieferung der
            Ware erforderlich ist. Ihre Zahlungsdaten geben wir im Rahmen der
            Zahlungsabwicklung an das beauftragte Kreditinstitut weiter, sofern
            dies f&uuml;r die Zahlungsabwicklung erforderlich ist. Sofern
            Zahlungsdienstleister eingesetzt werden, informieren wir Sie
            hier&uuml;ber nachstehend explizit. Rechtsgrundlage f&uuml;r die
            Weitergabe der Daten ist Art. 6 Abs. 1 lit. b DSGVO.
          </p>

          <p>
            <b>6.2</b>&thinsp;Zur Erf&uuml;llung unserer vertraglichen Pflichten
            unseren Kunden gegen&uuml;ber arbeiten wir mit externen
            Versandpartnern zusammen. Wir geben Ihren Namen sowie Ihre
            Lieferadresse ausschlie&szlig;lich zu Zwecken der Warenlieferung
            Art. 6 Abs. 1 lit. b DSGVO an einen von uns ausgew&auml;hlten
            Versandpartner weiter.
          </p>

          <h2>7&#41; Tools und Sonstiges</h2>

          <p>Google Web Fonts</p>

          <p>
            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
            genannte Web Fonts die von der Google Ireland Limited, Gordon House,
            4 Barrow St, Dublin, D04 E5W5, Irland (&bdquo;Google&ldquo;)
            bereitgestellt werden. Beim Aufruf einer Seite l&auml;dt Ihr Browser
            die ben&ouml;tigten Web Fonts in ihren Browser-Cache, um Texte und
            Schriftarten korrekt anzuzeigen.
          </p>

          <p>
            Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
            den Servern von Google aufnehmen. Hierbei kann es auch zu einer
            &Uuml;bermittlung von personenbezogenen Daten an die Server der
            Google LLC. in den USA kommen. Auf diese Weise erlangt Google
            Kenntnis dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse unsere Website
            aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im
            Interesse einer einheitlichen und ansprechenden Darstellung unserer
            Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von
            Art. 6 Abs. 1 lit. f DSGVO dar. Wenn Ihr Browser Web Fonts nicht
            unterst&uuml;tzt, wird eine Standardschrift von Ihrem Computer
            genutzt.
          </p>

          <p>
            F&uuml;r den Fall der &Uuml;bermittlung von personenbezogenen Daten
            an die Google LLC. mit Sitz in den USA, hat sich Google LLC.
            f&uuml;r das us-europ&auml;ische Datenschutz&uuml;bereinkommen
            &bdquo;Privacy Shield&ldquo; zertifiziert, welches die Einhaltung
            des in der EU geltenden Datenschutzniveaus gew&auml;hrleistet. Ein
            aktuelles Zertifikat kann hier eingesehen werden:
            https://www.privacyshield.gov/list
          </p>

          <p>
            Weitere Informationen zu Google Web Fonts finden Sie unter
            https://developers.google.com/fonts/faq und in der
            Datenschutzerkl&auml;rung von Google:
            https://www.google.com/policies/privacy/
          </p>

          <h2>8&#41; Rechte des Betroffenen</h2>

          <p>
            <b>8.1</b>&thinsp;Das geltende Datenschutzrecht gew&auml;hrt Ihnen
            gegen&uuml;ber dem Verantwortlichen hinsichtlich der Verarbeitung
            Ihrer personenbezogenen Daten umfassende Betroffenenrechte
            (Auskunfts- und Interventionsrechte), &uuml;ber die wir Sie
            nachstehend informieren:
          </p>

          <ul className="datstz-list">
            <li>
              Auskunftsrecht gem&auml;&szlig; Art. 15 DSGVO: Sie haben
              insbesondere ein Recht auf Auskunft &uuml;ber Ihre von uns
              verarbeiteten personenbezogenen Daten, die Verarbeitungszwecke,
              die Kategorien der verarbeiteten personenbezogenen Daten, die
              Empf&auml;nger oder Kategorien von Empf&auml;ngern, gegen&uuml;ber
              denen Ihre Daten offengelegt wurden oder werden, die geplante
              Speicherdauer bzw. die Kriterien f&uuml;r die Festlegung der
              Speicherdauer, das Bestehen eines Rechts auf Berichtigung,
              L&ouml;schung, Einschr&auml;nkung der Verarbeitung, Widerspruch
              gegen die Verarbeitung, Beschwerde bei einer
              Aufsichtsbeh&ouml;rde, die Herkunft Ihrer Daten, wenn diese nicht
              durch uns bei Ihnen erhoben wurden, das Bestehen einer
              automatisierten Entscheidungsfindung einschlie&szlig;lich
              Profiling und ggf. aussagekr&auml;ftige Informationen &uuml;ber
              die involvierte Logik und die Sie betreffende Tragweite und die
              angestrebten Auswirkungen einer solchen Verarbeitung, sowie Ihr
              Recht auf Unterrichtung, welche Garantien gem&auml;&szlig; Art. 46
              DSGVO bei Weiterleitung Ihrer Daten in Drittl&auml;nder bestehen;
            </li>
            <li>
              Recht auf Berichtigung gem&auml;&szlig; Art. 16 DSGVO: Sie haben
              ein Recht auf unverz&uuml;gliche Berichtigung Sie betreffender
              unrichtiger Daten und/oder Vervollst&auml;ndigung Ihrer bei uns
              gespeicherten unvollst&auml;ndigen Daten;
            </li>
            <li>
              Recht auf L&ouml;schung gem&auml;&szlig; Art. 17 DSGVO: Sie haben
              das Recht, die L&ouml;schung Ihrer personenbezogenen Daten bei
              Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu
              verlangen. Dieses Recht besteht jedoch insbesondere dann nicht,
              wenn die Verarbeitung zur Aus&uuml;bung des Rechts auf freie
              Meinungs&auml;u&szlig;erung und Information, zur Erf&uuml;llung
              einer rechtlichen Verpflichtung, aus Gr&uuml;nden des
              &ouml;ffentlichen Interesses oder zur Geltendmachung,
              Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen
              erforderlich ist;
            </li>
            <li>
              Recht auf Einschr&auml;nkung der Verarbeitung gem&auml;&szlig;
              Art. 18 DSGVO: Sie haben das Recht, die Einschr&auml;nkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen, solange
              die von Ihnen bestrittene Richtigkeit Ihrer Daten
              &uuml;berpr&uuml;ft wird, wenn Sie eine L&ouml;schung Ihrer Daten
              wegen unzul&auml;ssiger Datenverarbeitung ablehnen und stattdessen
              die Einschr&auml;nkung der Verarbeitung Ihrer Daten verlangen,
              wenn Sie Ihre Daten zur Geltendmachung, Aus&uuml;bung oder
              Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigen, nachdem wir
              diese Daten nach Zweckerreichung nicht mehr ben&ouml;tigen oder
              wenn Sie Widerspruch aus Gr&uuml;nden Ihrer besonderen Situation
              eingelegt haben, solange noch nicht feststeht, ob unsere
              berechtigten Gr&uuml;nde &uuml;berwiegen;
            </li>
            <li>
              Recht auf Unterrichtung gem&auml;&szlig; Art. 19 DSGVO: Haben Sie
              das Recht auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung
              der Verarbeitung gegen&uuml;ber dem Verantwortlichen geltend
              gemacht, ist dieser verpflichtet, allen Empf&auml;ngern, denen die
              Sie betreffenden personenbezogenen Daten offengelegt wurden, diese
              Berichtigung oder L&ouml;schung der Daten oder Einschr&auml;nkung
              der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als
              unm&ouml;glich oder ist mit einem
              unverh&auml;ltnism&auml;&szlig;igen Aufwand verbunden. Ihnen steht
              das Recht zu, &uuml;ber diese Empf&auml;nger unterrichtet zu
              werden.
            </li>
            <li>
              Recht auf Daten&uuml;bertragbarkeit gem&auml;&szlig; Art. 20
              DSGVO: Sie haben das Recht, Ihre personenbezogenen Daten, die Sie
              uns bereitgestellt haben, in einem strukturierten, g&auml;ngigen
              und maschinenlesebaren Format zu erhalten oder die
              &Uuml;bermittlung an einen anderen Verantwortlichen zu verlangen,
              soweit dies technisch machbar ist;
            </li>
            <li>
              Recht auf Widerruf erteilter Einwilligungen gem&auml;&szlig; Art.
              7 Abs. 3 DSGVO: Sie haben das Recht, eine einmal erteilte
              Einwilligung in die Verarbeitung von Daten jederzeit mit Wirkung
              f&uuml;r die Zukunft zu widerrufen. Im Falle des Widerrufs werden
              wir die betroffenen Daten unverz&uuml;glich l&ouml;schen, sofern
              eine weitere Verarbeitung nicht auf eine Rechtsgrundlage zur
              einwilligungslosen Verarbeitung gest&uuml;tzt werden kann. Durch
              den Widerruf der Einwilligung wird die Rechtm&auml;&szlig;igkeit
              der aufgrund der Einwilligung bis zum Widerruf erfolgten
              Verarbeitung nicht ber&uuml;hrt;
            </li>
            <li>
              Recht auf Beschwerde gem&auml;&szlig; Art. 77 DSGVO: Wenn Sie der
              Ansicht sind, dass die Verarbeitung der Sie betreffenden
              personenbezogenen Daten gegen die DSGVO verst&ouml;&szlig;t, haben
              Sie - unbeschadet eines anderweitigen verwaltungsrechtlichen oder
              gerichtlichen Rechtsbehelfs - das Recht auf Beschwerde bei einer
              Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat Ihres
              Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des
              mutma&szlig;lichen Versto&szlig;es.
            </li>
          </ul>

          <p>
            <b>8.2</b>&thinsp;WIDERSPRUCHSRECHT
          </p>

          <p>
            WENN WIR IM RAHMEN EINER INTERESSENABW&Auml;GUNG IHRE
            PERSONENBEZOGENEN DATEN AUFGRUND UNSERES &Uuml;BERWIEGENDEN
            BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE
            RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION
            ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG F&Uuml;R
            DIE ZUKUNFT EINZULEGEN.
            <br />
            MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
            VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT
            ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE
            F&Uuml;R DIE VERARBEITUNG NACHWEISEN K&Ouml;NNEN, DIE IHRE
            INTERESSEN, GRUNDRECHTE UND GRUNDFREIHEITEN &Uuml;BERWIEGEN, ODER
            WENN DIE VERARBEITUNG DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER
            VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN DIENT.
          </p>

          <p>
            WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM
            DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT
            WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
            PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN.
            SIE K&Ouml;NNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUS&Uuml;BEN.
          </p>

          <p>
            MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
            VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.
          </p>

          <h2>9&#41; Dauer der Speicherung personenbezogener Daten</h2>

          <p>
            Die Dauer der Speicherung von personenbezogenen Daten bemisst sich
            anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und
            &ndash; sofern einschl&auml;gig &ndash; zus&auml;tzlich anhand der
            jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und
            steuerrechtliche Aufbewahrungsfristen).
          </p>

          <p>
            Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer
            ausdr&uuml;cklichen Einwilligung gem&auml;&szlig; Art. 6 Abs. 1 lit.
            a DSGVO werden diese Daten so lange gespeichert, bis der Betroffene
            seine Einwilligung widerruft.
          </p>

          <p>
            Existieren gesetzliche Aufbewahrungsfristen f&uuml;r Daten, die im
            Rahmen rechtsgesch&auml;ftlicher bzw.
            rechtsgesch&auml;fts&auml;hnlicher Verpflichtungen auf der Grundlage
            von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden diese
            Daten nach Ablauf der Aufbewahrungsfristen routinem&auml;&szlig;ig
            gel&ouml;scht, sofern sie nicht mehr zur Vertragserf&uuml;llung oder
            Vertragsanbahnung erforderlich sind und/oder unsererseits kein
            berechtigtes Interesse an der Weiterspeicherung fortbesteht.
          </p>

          <p>
            Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von
            Art. 6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert,
            bis der Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO
            aus&uuml;bt, es sei denn, wir k&ouml;nnen zwingende
            schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen,
            die die Interessen, Rechte und Freiheiten der betroffenen Person
            &uuml;berwiegen, oder die Verarbeitung dient der Geltendmachung,
            Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.
          </p>

          <p>
            Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der
            Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden
            diese Daten so lange gespeichert, bis der Betroffene sein
            Widerspruchsrecht nach Art. 21 Abs. 2 DSGVO aus&uuml;bt.
          </p>

          <p>
            Sofern sich aus den sonstigen Informationen dieser Erkl&auml;rung
            &uuml;ber spezifische Verarbeitungssituationen nichts anderes
            ergibt, werden gespeicherte personenbezogene Daten im &Uuml;brigen
            dann gel&ouml;scht, wenn sie f&uuml;r die Zwecke, f&uuml;r die sie
            erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr
            notwendig sind.
          </p>
        </div>
      </React.Fragment>
    );
  }
}

export default Datenschutz;
