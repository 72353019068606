import React, { Component } from "react";

export class NewestOrder extends Component {
  render() {
    const { data } = this.props;
    if (!data) {
      return (
        <h3 className="no_newOrders">
          Sobald neue Bestellungen eingehen wirst du hier live die aktuellste
          Bestellung sehen...
        </h3>
      );
    }
    return (
      <div className="newOrder">
        <h3>Neuste Bestellung:</h3>
        <p>
          Bestellung von {data.orderListChanged.firstNameOrderer}{" "}
          {data.orderListChanged.lastNameOrderer} <br />
          Bestellt am {data.orderListChanged.date}
        </p>
      </div>
    );
  }
}

export default NewestOrder;
