import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ChangeToppings.scss";

import AvailableTopping from "./AvailableTopping";
import { setDishModal } from "../../../actions/single-dish-actions";
import { checkLength } from "../../helpfunctions";

/**
 * returns the info-message for addable toppings
 * @param maxToppings the maxAddableToppings (prop)
 * @param fullLength the returned value from the current topping length (prop)
 * @param startToppings the number of the start toppings
 * @return the message in a p tag
 */
const RenderToppingInfo = (fullLength) => {
  const { howMuchMaxToppings } = useSelector(
    (state) => state.singledish.dish.editable
  );

  if (howMuchMaxToppings) {
    if (howMuchMaxToppings === fullLength) {
      return (
        <p className="new-add-more-toppings">
          Du kannst keine weiteren Toppings hinzufügen.
        </p>
      );
    } else {
      let diff = howMuchMaxToppings - fullLength;
      return (
        <p className="new-add-more-toppings">
          Du kannst noch {diff} Topping{diff > 1 ? "s" : ""} hinzufügen.
        </p>
      );
    }
  }
};

export default function ChangeToppings(props) {
  const singledish = useSelector((state) => state.singledish.dish);
  const width = useSelector((state) => state.border.w);
  const fullLength = checkLength(singledish.choosenToppings);
  const dispatch = useDispatch();
  if (
    singledish.availableToppings[0].price === 0 ||
    singledish.availableToppings[0].price !== null
  ) {
    return (
      <>
        <div
          className="change-toppings col-12 col-sm-6"
          style={{ height: width > 575 ? "100%" : "auto" }}
        >
          <div className="change-toppings-header">
            <h3>Beilagen bearbeiten</h3>
            {RenderToppingInfo(fullLength)}
          </div>
          <div className="change-toppings-body">
            {width > 575 &&
              singledish.availableToppings.map((available, key) => (
                <AvailableTopping key={key} available={available} />
              ))}
            {width <= 575 && (
              <button
                className="set-modal-btn"
                onClick={() => {
                  dispatch(setDishModal(true));
                  localStorage.setItem("dishModal", JSON.stringify(true));
                }}
              >
                Hier klicken zum hinzufügen, verdoppeln und entfernen von
                Beilagen
              </button>
            )}
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}
