let initialState = {
  checkoutItems: [],
  total: 0
};

let calculateTotalPrice = items => {
  let total = 0;
  items.forEach(item => {
    total += item.quantity * item.price;
  });
  return total;
};

export const checkout = (state = initialState, action) => {
  let checkoutItems;
  switch (action.type) {
    case "SET_CHECKOUT":
      checkoutItems = [...state.checkoutItems, action.payload];
      return { checkoutItems, total: calculateTotalPrice(checkoutItems) };
    case "REHYDRATE_CHECKOUT":
      if (!action.payload || action.payload === []) {
        return {
          checkoutItems: [],
          total: 0
        };
      }
      return {
        checkoutItems: action.payload.checkoutItems,
        total: calculateTotalPrice(action.payload.checkoutItems)
      };
    case "DELETE_CHECKOUT":
      return [];
    case "INCREMENT":
      let incr_index = state.checkoutItems.findIndex(function(element) {
        return element === action.payload;
      });
      state.checkoutItems[incr_index].quantity += 1;
      checkoutItems = [...state.checkoutItems];
      return { checkoutItems, total: calculateTotalPrice(checkoutItems) };
    case "DECREMENT":
      let decr_indec = state.checkoutItems.findIndex(function(element) {
        return element === action.payload[0];
      });
      if (action.number)
        state.checkoutItems[decr_indec].quantity -= action.number;
      else state.checkoutItems[decr_indec].quantity -= action.payload[1];
      checkoutItems = [...state.checkoutItems];
      return { checkoutItems, total: calculateTotalPrice(checkoutItems) };
    case "FILTER_CHECKOUT":
      let filteredArray = state.checkoutItems.filter(function(item) {
        return item !== action.payload;
      });
      localStorage.setItem(
        "checkout",
        JSON.stringify({
          checkoutItems: filteredArray,
          total: calculateTotalPrice(filteredArray)
        })
      );
      return {
        checkoutItems: filteredArray,
        total: calculateTotalPrice(filteredArray)
      };
    default:
      return state;
  }
};
