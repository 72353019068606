import React, { Component } from "react";

export class NewChangeSauce extends Component {
  state = {
    value: ""
  };

  handleChange = e => {
    const { sauces } = this.props;
    this.setState({ value: e.target.value });
    for (let i = 0; i < sauces.length; i++) {
      if (e.target.value === sauces[i].name) {
        this.props.checkSauce(sauces[i]);
      }
    }
  };

  papaSaidChangeSauce = () => {
    this.setState({ value: "" });
  };

  renderOptions = sauces => {
    if (sauces[0].price) {
      this.props.checkSauce(sauces[0]);
    }
    return sauces.map(sauce => (
      <option key={sauce.name} value={sauce.name}>
        {sauce.name}
      </option>
    ));
  };

  render() {
    const { sauces } = this.props;
    if (sauces[0].price === 0 || sauces[0].price !== null) {
      return (
        <>
          <h3 className="changers-subheading">Soße bearbeiten</h3>
          <select
            className="select-list"
            value={this.state.value}
            onChange={this.handleChange}
          >
            {this.renderOptions(sauces)}
          </select>
        </>
      );
    } else {
      return null;
    }
  }
}

export default NewChangeSauce;
