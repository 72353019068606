/**
 * checkLength calculate the relative topping length on a dish and takes into account possible duplications
 * @params toppings the topping object
 * @return length as number
 */
export const checkLength = toppings => {
  let length = 0;
  for (let i = 0; i < toppings.length; i++) {
    // If the topping is doubled, count it as two toppings, otherwise count it as one
    length += toppings[i].doubled ? 2 : 1;
  }
  return length;
};

/**
 * search inside the availableToppings for the doubleable-state of the choosen topping and returns the value as boolean
 * @param singledish
 * @param choosendish
 * @return the doubleable-status of the choosen topping
 */
export const findAvailableStatus = (singledish, choosendish) => {
  let doubleStatus;
  singledish.availableToppings.forEach(topping => {
    if (topping.name === choosendish.name) {
      doubleStatus = topping.doubleable;
    }
  });
  return doubleStatus;
};

export const renderTotalPrice = (obj, checkout) => {
  let { checkoutItems } = checkout;
  if (checkoutItems.length > 0) {
    for (let i = 0; i < checkoutItems.length; i++) {
      if (
        obj.name === checkoutItems[i].name &&
        obj.toppings === checkoutItems[i].toppings &&
        obj.sauce === checkoutItems[i].sauce
      ) {
        let newCheckOutItems = checkoutItems.slice();
        newCheckOutItems[i] = obj;
        localStorage.setItem("checkout", JSON.stringify(checkout));
      }
    }
  }
};

export const validateEmail = email => {
  // E-Mail Adressen Test
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
