import React, { useEffect, useState } from "react";
import "./ActivationComponent.scss";
import { ClimbingBoxLoader } from "react-spinners";

export default function ActivationComponent(props) {
  const { token } = props.match.params;
  const [loaded, setLoadingState] = useState(false);
  const [retry, setRetryState] = useState(false);
  const [error, setErrorState] = useState(null);
  const [success, setSuccessState] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}`, {
      method: "POST",
      body: JSON.stringify({
        query: `
                  mutation {
                    activateAccount(
                        token: "${token}"
                        )
                  }
                  `,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((resData) => {
        setLoadingState(true);
        if (!resData.hasOwnProperty("errors")) {
          setErrorState(null);
          setSuccessState(`Der Account wurde erfolgreich aktiviert.`);
        } else {
          setRetryState(true);
          setErrorState(resData.errors[0].message);
        }
        return;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="activation-component">
      <h3>Account aktivieren</h3>
      {!loaded && (
        <div className="climbing">
          <ClimbingBoxLoader />
        </div>
      )}
      {(error || success) && (
        <div className="message">
          <p className={error ? "error" : "success"}>
            {error ? error : success}
          </p>
          {success && <a href="https://www.doigastro.de">Zur Startseite</a>}
        </div>
      )}
      {retry && (
        <div className="retry">
          <button
            onClick={() => window.open("https://www.doigastro.de/", "_self")}
          >
            Zur Startseite
          </button>
          <button onClick={() => window.location.reload()}>
            Erneut versuchen
          </button>
        </div>
      )}
    </div>
  );
}
