import React, { Component } from "react";
import AppStore from "../../../images/Appstore Black.png";
import ForUser from "./ForUser";
import pizzaIcon from "../../../images/stueck.png";
import burgerIcon from "../../../images/kaeseburger.png";
import friesIcon from "../../../images/essen-und-restaurant.png";
import breakfast from "../../../images/croissant.png";
import doiApp from "../../../images/mannschaft.png";
import ForRestaurants from "./ForRestaurants";

export class UserOrRestaurants extends Component {
  state = {
    userVisible: true,
  };

  changeState = (e) => {
    if (this.state.userVisible === true && e.target.name === "restBtn") {
      this.setState({ userVisible: false });
    } else if (this.state.userVisible !== true && e.target.name !== "restBtn") {
      this.setState({ userVisible: true });
    }
  };

  render() {
    const { userVisible } = this.state;
    let userActive, restActive;
    if (userVisible) {
      userActive = "active";
      restActive = "";
    } else {
      userActive = "";
      restActive = "active";
    }
    return (
      <React.Fragment>
        <p className="free-register-for-users">
          Wie sich das für eine App gehört gibt's uns ab sofort hier:
        </p>
        <div className="store-badges">
          <a href="https://apps.apple.com/us/app/doiapp/id1509742755?l=de&ls=1">
            <img alt="Jetzt im App-Store" src={AppStore} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=de.doiapp.doigastro&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              alt="Jetzt bei Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png"
            />
          </a>
        </div>
        <div className="services row">
          <div className="single-advantage offset-1 col-10 col-xl-4">
            <div className="advantage-image-group">
              <img src={pizzaIcon} alt="Pizza-Icon" />
              <img src={burgerIcon} alt="Burger-Icon" />
              <img src={friesIcon} alt="Pommes-Icon" />
            </div>
            <p className="advantage-headtext">DoiGastro</p>
            <p>
              Unser ursprüngliches Angebot wurde und wird in den kommenden
              Wochen um zahlreiche Restaurants erweitert. Dazu haben wir viele
              Verbesserungen in der Abwicklung vorgenommen!
            </p>
          </div>
          <div className="single-advantage offset-1 col-10 col-xl-4">
            <div className="advantage-image-group">
              <img src={breakfast} alt="Frühstücks-Icon" />
            </div>
            <p className="advantage-headtext">Doifrühstück</p>
            <p>
              In Kürze schalten wir unseren Partner zur Frühstückslieferung
              frei. Starte mit doiFrühstück optimal in den Tag - mit Brötchen
              natürlich vom regionalen Bäcker.
            </p>
          </div>
          <div className="single-advantage offset-1 col-10 col-xl-4">
            <div className="advantage-image-group">
              <img src={doiApp} alt="doiApp-Logo" />
            </div>
            <p className="advantage-headtext">DoiApp</p>
            <p>
              Ob regionaler Metzger, Partyservice oder Getränkelieferant - wir
              arbeiten an der Ausweitung des Angebots. Die Devise ist dabei
              immer dieselbe: <br /> Fair zum Anbieter - gut für die Region!
            </p>
          </div>
        </div>
        <div className="user_rest">
          {/* <button
            onClick={this.changeState}
            name="userBtn"
            className={`user_rest_button ${userActive}`}
          >
            Für Nutzer
          </button> */}
          <button
            onClick={this.changeState}
            name="restBtn"
            className={`user_rest_button ${restActive}`}
          >
            Für Restaurants
          </button>
        </div>
        {/*  <ForUser visible={userVisible} /> */}
        <ForRestaurants visible={true} />
      </React.Fragment>
    );
  }
}

export default UserOrRestaurants;
