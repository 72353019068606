import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ChangeToppings.scss";

import {
  setDishModal,
  resetToppings
} from "../../../actions/single-dish-actions";
import AvailableTopping from "./AvailableTopping";

export default function EditToppingModal() {
  const singledish = useSelector(state => state.singledish.dish);
  const dispatch = useDispatch();
  return (
    <div className="modal">
      <div className="modal-header">
        <div>
          <h3>Beilagen bearbeiten</h3>
          <button
            onClick={() => {
              dispatch(setDishModal(false));
              localStorage.setItem("dishModal", JSON.stringify(false));
            }}
          >
            x
          </button>
        </div>
        <p>Scroll dich durch die verfügbaren Beilagen</p>
      </div>
      <div className="modal-body">
        {singledish.availableToppings.map((available, key) => (
          <AvailableTopping key={key} available={available} />
        ))}
      </div>
      <div className="modal-footer">
        <button
          onClick={() => {
            dispatch(setDishModal(false));
            localStorage.setItem("dishModal", JSON.stringify(false));
          }}
        >
          Erledigt
        </button>
        <button onClick={() => dispatch(resetToppings(singledish))}>
          Zurücksetzen
        </button>
      </div>
    </div>
  );
}
