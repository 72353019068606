import React, { Component } from 'react';

export class SaladComponent extends Component {
    render() {
        return (
            <div>
                <h1>SaladComponent</h1>
                <p>Hier wird gerockt...</p>
            </div>
        );
    }
}

export default SaladComponent;
