import React from "react";

export default React.createContext({
  token: null,
  userId: null,
  login: () => {},
  logout: () => {},
  incrCC: () => {},
  decrCC: () => {},
  resetCC: () => {},
  showCart: () => {}
});
