export const fetchDish = (email, dishname) => {
  return `
{
    fetchDish(email: "${email}", dishname: "${dishname}") {
      name
      info
      image
      availableToppings {
        name
        price
        doubleable
      }
      availableGarnishes {
        name
        price
        doubleable
      }
      availableSauces {
        name
        price
        doubleable
      }
      availableSalads {
        name
        price
        doubleable
      }
      toppings {
        name
        price
        doubled
      }
      sauces {
        name
        price
        doubled
      }
      garnishes {
        name
        price
        doubled
      }
      salads {
        name
        price
        doubled
      }
      pricing {
        name
        price
        factor
      }
      editable {
        howMuchMaxToppings
        maxDeletableToppings
      }
    }
  }
`;
};

export const resetPassword = email => {
  return `
    mutation {
      resetPasswordMail(email: "${email}") {
        email
      }
    }
  `;
};

export const changeAdress = (email, newCity, newZip, newStreet, newHouseNr) => {
  return `
  mutation {
    changeUserAdress(email: "${email}", newAdress: {
      city: "${newCity}"
      zip: "${newZip}"
      street: "${newStreet}"
      houseNr: "${newHouseNr}"
    }) {
      email
      phone
      adress {
        street
        houseNr
        zip
        city 
      }
  }
 } `;
};

export const changeMail = (email, newEmail) => {
  return `
  mutation {
    changeUserEmail(email: "${email}", newEmail: "${newEmail}") {
      email
      phone
      adress {
        street
        houseNr
        zip
        city 
      }
  }
}`;
};

export const changeNumber = (email, newNumber) => {
  return `
  mutation {
    changeUserNumber(email: "${email}", newPhone: "${newNumber}") {
      email
      phone
      adress {
        street
        houseNr
        zip
        city 
      }
  }
}`;
};
