import React, { Component } from 'react';

export class InsertWeekday extends Component {
    render() {
        const {
            day,
            day_state,
            special_state,
            checkWeekday,
            checkSpecialWeekday,
            germanWords
        } = this.props;
        return (
            <React.Fragment>
                <div className="single-weekday col-10 col-md-6 col-lg-5">
                    <p className="weekday_true_false">{germanWords[0]}</p>
                    <input
                        type="radio"
                        id={`${day}_open`}
                        name={`${day}`}
                        value="true"
                        onChange={checkWeekday}
                    />
                    <label className="label_true_false" htmlFor={`${day}_open`}>
                        {' '}
                        Geöffnet
                    </label>
                    <input
                        type="radio"
                        id={`${day}_closed`}
                        name={`${day}`}
                        onChange={this.props.checkWeekday}
                        value=""
                        defaultChecked
                    />
                    <label
                        className="label_true_false"
                        htmlFor={`${day}_closed`}
                    >
                        {' '}
                        Ruhetag
                    </label>
                    <br />
                </div>
                {day_state && (
                    <div className="weekday-explizity col-10 col-md-6 col-lg-5">
                        <h3>Öffnungszeiten {germanWords[1]}</h3>
                        <div className="explizity-question-block col-12">
                            <p className="question-explizity col-8">
                                Hat das Restaurant nur an bestimmten Montagen
                                geöffnet?
                            </p>
                            <input
                                type="radio"
                                id={`${day}_open-special`}
                                name={`${day}Special`}
                                value="true"
                                onChange={checkSpecialWeekday}
                            />
                            <label
                                className="label_true_false"
                                htmlFor={`${day}_open-special`}
                            >
                                {' '}
                                Ja
                            </label>
                            <input
                                type="radio"
                                id={`${day}_closed-special`}
                                name={`${day}Special`}
                                onChange={checkSpecialWeekday}
                                value=""
                                defaultChecked
                            />
                            <label
                                className="label_true_false"
                                htmlFor={`${day}_closed-special`}
                            >
                                {' '}
                                Nein
                            </label>
                        </div>
                        <p className="label-example">
                            Beispiel: Die Gaststätte zur Linde hat nur jeden 2.
                            Montag im Monat geöffnet. (Ich weiß, dass stimmt
                            nicht, es ist nur ein Beispiel...)
                        </p>
                        {special_state && (
                            <div className="special-dates">
                                <div>
                                    <label htmlFor={`min-date-special-${day}`}>
                                        Minimaler-Monatstag
                                    </label>
                                    <input
                                        type="number"
                                        id={`min-date-special-${day}`}
                                        name={`min-date-special-${day}`}
                                        min="1"
                                        max="31"
                                    />{' '}
                                    <br />
                                    <label htmlFor={`max-date-special-${day}`}>
                                        Maximaler-Monatstag
                                    </label>
                                    <input
                                        type="number"
                                        id={`max-date-special-${day}`}
                                        name={`max-date-special-${day}`}
                                        min="1"
                                        max="31"
                                    />
                                    <p className="label-info">
                                        Wenn die Linde beispielsweise nur am
                                        letzten Montag im Monat geöffnet hat,
                                        dann muss als mindester Montagstag an
                                        der Stelle der 21. eingegeben werden. Da
                                        die letzte Woche in einem
                                        "Javascript-Monat" vom 21.- (je nach
                                        Monat) 28. - 31. geht und der letzte
                                        Montag logischerweise in dieser Woche
                                        liegt.
                                    </p>
                                    <p className="label-example">
                                        Beispiel: Für den dritten Montag im
                                        Monat müsste als min. Tag der 21.
                                        ausgewählt werden...
                                    </p>
                                </div>
                            </div>
                        )}
                        <div>
                            <label htmlFor={`${day}-special-time-start`}>
                                Öffnungszeiten-Start
                            </label>
                            <input
                                type="text"
                                id={`${day}-special-time-start`}
                                name={`${day}-special-time-start`}
                            />
                        </div>
                        <div>
                            <label htmlFor={`${day}-special-time-end`}>
                                Öffnungszeiten-Ende
                            </label>
                            <input
                                type="text"
                                id={`${day}-special-time-end`}
                                name={`${day}-special-time-end`}
                            />
                        </div>
                        <div>
                            <label htmlFor={`${day}-special-time-break-start`}>
                                Pausenzeit-Beginn
                            </label>
                            <input
                                type="text"
                                id={`${day}-special-time-break-start`}
                                name={`${day}-special-time-break-start`}
                            />
                        </div>
                        <div>
                            <label htmlFor={`${day}-special-time-break-end`}>
                                Pausenzeit-Ende
                            </label>
                            <input
                                type="text"
                                id={`${day}-special-time-break-end`}
                                name={`${day}-special-time-break-end`}
                            />
                        </div>
                        <p className="label-info">
                            Bitte gib die Uhrzeiten ohne Anführungszeichen oder
                            sonstiges getrennt mit einem Doppelpunkt wie folgt
                            an: HH:MM <br />
                            Ich denke die Textfelder sind einigermaßen
                            selbsterklärend....
                        </p>
                        <p className="label-example">
                            Beispiel: Für den ersten Montag im Monat <br />
                            Min-Tag: 1 <br />
                            Max-Tag: 7 <br />
                            Öffnungszeiten-Start: 11:00 <br />
                            Öffnungszeiten-Ende: 23:00 <br />
                            Pausenzeit-Beginn: 14:30 <br />
                            Öffnungszeiten-Start: 16:00 <br />
                            Dieses Restaurant hat jeden 1. Montag im Monat von
                            11 bis 23 Uhr geöffnet. An jenen Montagen ist von
                            14:30 bis 16:00 Uhr geschlossen....
                            <br />
                        </p>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default InsertWeekday;
