import React, { Component } from 'react';

import CreatedDish from './CreatedDish';
import MenuSchema from './checkComponents/MenuSchema';
import CategoryPreFactoring from './CategoryPreFactoring';

export class Category extends Component {
    state = {
        dishes: 1,
        choosableToppings: 1,
        choosableSauces: 1,
        choosableGarnishes: 1,
        choosableSalads: 1,
        prefabricatedToppings: false,
        prefabricatedSauces: false,
        prefabricatedGarnishes: false,
        prefabricatedSalads: false
    };

    renderDishes = () => {
        let singleDish = [];
        for (let i = 1; i <= this.state.dishes; i++) {
            singleDish.push(
                <CreatedDish key={i} number={i} catNumber={this.props.number} />
            );
        }
        return singleDish;
    };

    addDishes = e => {
        e.preventDefault();
        if (e.target.name === 'add') {
            this.setState({ dishes: this.state.dishes + 1 });
        } else {
            if (this.state.dishes > 0) {
                this.setState({ dishes: this.state.dishes - 1 });
            }
        }
    };

    renderSchema = (name, info) => {
        let array = [];
        for (let i = 1; i <= this.state[info]; i++) {
            array.push(
                <MenuSchema
                    key={i}
                    number={i}
                    name={name + ' ' + i}
                    info={info}
                    doubleState="doubleable"
                    doubleState_string="Verdoppelbar?"
                    dishNumber={this.props.number}
                    catNumber={i}
                />
            );
        }
        return array;
    };

    addChoosable = e => {
        e.preventDefault();
        if (e.target.name === 'add') {
            this.setState({
                [e.target.id]: this.state[e.target.id] + 1
            });
        } else {
            if (this.state[e.target.id] > 0) {
                this.setState({ [e.target.id]: this.state[e.target.id] - 1 });
            }
        }
    };

    changePreState = e => {
        let bool = Boolean(e.target.value);
        this.setState({ [e.target.name]: bool });
    };

    render() {
        const { number } = this.props;
        const {
            prefabricatedToppings,
            prefabricatedSauces,
            prefabricatedGarnishes,
            prefabricatedSalads
        } = this.state;
        return (
            <div className="single-category">
                <hr />
                <h1>Kategorie {number}</h1>
                <div className="single-category-block">
                    <label
                        htmlFor={`category-name-${number}`}
                        className="col-6"
                    >
                        Kategorie-Name
                    </label>
                    <input
                        type="text"
                        id={`category-name-${number}`}
                        name={`category-name-${number}`}
                        className="col-6"
                    />
                </div>
                <div className="single-category-block">
                    <label
                        htmlFor={`category-image-${number}`}
                        className="col-6"
                    >
                        Kategorie-Bild
                    </label>
                    <input
                        type="text"
                        id={`category-image-${number}`}
                        name={`category-image-${number}`}
                        className="col-6"
                    />
                </div>
                <CategoryPreFactoring
                    number={number}
                    word="Zutatenliste"
                    changePreState={this.changePreState}
                    prefabricatedState={prefabricatedToppings}
                    prefabricatedWord={[
                        'prefabricatedToppings',
                        'choosableToppings'
                    ]}
                    renderFunction={this.renderSchema(
                        'Toppings',
                        'choosableToppings'
                    )}
                    addChoosable={this.addChoosable}
                />
                <CategoryPreFactoring
                    number={number}
                    word="Soßenliste"
                    changePreState={this.changePreState}
                    prefabricatedState={prefabricatedSauces}
                    prefabricatedWord={[
                        'prefabricatedSauces',
                        'choosableSauces'
                    ]}
                    renderFunction={this.renderSchema(
                        'Soßen',
                        'choosableSauces'
                    )}
                    addChoosable={this.addChoosable}
                />
                <CategoryPreFactoring
                    number={number}
                    word="Beilagenliste"
                    changePreState={this.changePreState}
                    prefabricatedState={prefabricatedGarnishes}
                    prefabricatedWord={[
                        'prefabricatedGarnishes',
                        'choosableGarnishes'
                    ]}
                    renderFunction={this.renderSchema(
                        'Beilagen',
                        'choosableGarnishes'
                    )}
                    addChoosable={this.addChoosable}
                />
                <CategoryPreFactoring
                    number={number}
                    word="Salatliste"
                    changePreState={this.changePreState}
                    prefabricatedState={prefabricatedSalads}
                    prefabricatedWord={[
                        'prefabricatedSalads',
                        'choosableSalads'
                    ]}
                    renderFunction={this.renderSchema(
                        'Salat',
                        'choosableSalads'
                    )}
                    addChoosable={this.addChoosable}
                />
                <p className="categorie-heading">Gerichts-Liste</p>
                <p className="label-info">
                    Bitte füge die verschiedenen Gerichte nach den Angeboten des
                    Restaurants ein. <br />
                </p>
                <p className="label-example">
                    Beispiel: Die Gaststätte zur Linde hat folgende Gerichte in
                    der Kategorie Pizza: <br />
                    Margherita
                    <br />
                    Salami
                    <br />
                    Schinken
                    <br />
                    San Martino
                    <br />
                    ...
                </p>
                <div className="mx-auto">
                    {this.renderDishes()}
                    <button
                        className="cat-btn col-6"
                        name="add"
                        onClick={this.addDishes}
                    >
                        + Gericht
                    </button>
                    <button
                        className="cat-btn col-6"
                        name="delete"
                        onClick={this.addDishes}
                    >
                        - Gericht
                    </button>
                </div>
                <hr />
            </div>
        );
    }
}

export default Category;
