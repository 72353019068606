import React, { Component } from "react";
import "./DishEditView.scss";

import ChangeGarnishes from "./ChangeGarnishes";
import ChangeSauce from "./ChangeSauce";
import ChangeSalads from "./ChangeSalads";
import ChoosenToppings from "./ChoosenToppings";
import ChangeToppings from "./ChangeToppings";
import InfoSingleDish from "./InfoSingleDish";
import EditToppingModal from "./EditToppingModal";
import WhiteCart from "../../../images/cart.png";
import InfoButton from "../../../images/info-button.png";
import GreenCart from "../../../images/cart-green.png";
import Receipt from "../../../images/receipt.png";
import {
  IoIosCheckmarkCircleOutline,
  IoIosInformationCircleOutline,
  IoMdTrash
} from "react-icons/io";
import { IoMdArrowRoundBack } from "react-icons/io";
import { connect } from "react-redux";
import { addCheckOutItem } from "../../../actions/cart-actions";
import { toggleComponents } from "../../../actions/toggleActions";
import { resetToppings } from "../../../actions/single-dish-actions";

export class DishEditView extends Component {
  constructor(props) {
    super(props);
    this.number = React.createRef();
    this.changeGarnishToReset = React.createRef();
    this.changeSaladToReset = React.createRef();
    this.changeSauceToReset = React.createRef();
    this.changePricing = React.createRef();
  }

  state = {
    cartIcon: GreenCart,
    sauceValue: { name: "", price: 0 },
    garnishValue: { name: "", price: 0 },
    saladValue: { name: "", price: 0 },
    currSize: "",
    currPrice: this.props.singledish.pricing[0].price,
    normalPrice: this.props.singledish.pricing[0],
    currNumber: 1,
    changes: [],
    succesfullAdded: false,
    showAdditives: false,
    addedItems: null,
    value: ""
  };

  addClicked = choosenToppings => {
    let { singledish } = this.props;
    this.addToCart(choosenToppings);
    if (singledish.availableGarnishes[0].name)
      this.changeGarnishToReset.current.papaSaidChangeGarnish();
    if (singledish.availableSauces[0].name)
      this.changeSauceToReset.current.papaSaidChangeSauce();
    if (singledish.availableSalads[0].name)
      this.changeSaladToReset.current.papaSaidChangeSalad();
    this.setState({
      value: "",
      currSize: "",
      currPrice: this.props.singledish.pricing[0].price
    });
  };

  addToCart = toppings => {
    /* if (this.context.cartContent === 0) {
      this.props.deleteCart();
    } */
    const { singledish } = this.props;
    let {
      currPrice,
      currSize,
      sauceValue,
      garnishValue,
      saladValue
    } = this.state;
    /* this.context.incrCC(this.number.current.value); */
    let curSize = currSize;
    if (curSize === "") {
      if (!singledish.pricing[0].name) {
        curSize = "Normal";
      } else {
        curSize = singledish.pricing[0].name;
      }
    }
    let toppingNames = [];
    toppings.forEach(topping => {
      topping.doubled
        ? toppingNames.push("Doppelt " + topping.name)
        : toppingNames.push(topping.name);
    });
    let toppingList = toppingNames.join(", ");
    let onTop = sauceValue.price + garnishValue.price + saladValue.price;
    // ÄNDERUNGEN DER TOPPINGS FÜR CUSTOMIZED AUSDRUCKE
    // Array, worin die Änderungen zwischengespeichert werden
    let changes = [];
    // Die Iteration über alle Items, welche das Gericht ursprünglich bereit hält.
    for (let topping of singledish.toppings) {
      // Wenn eine Zutat gefunden wird...
      let founded = toppings.find(topp => topping.name === topp.name);
      // ...und Sie nicht undefined ist, also vorhanden ist...
      if (typeof founded !== "undefined") {
        // ...muss überprüft werden, ob Sie verdoppelt wurde, andernfalls gab es keine Veränderung.
        if (founded.doubled) {
          changes.push(`Doppelt ${founded.name}`);
        }
      }
      // Wurde eine Zutat nicht in der Liste gefunden, ist also "undefined", wurde es entfernt
      else {
        changes.push(`ohne ${topping.name}`);
      }
    }

    // Die Iteration über die Zutaten der neuen Topping-Liste...
    for (let topping of toppings) {
      // Zunächst wird jedes Topping der neuen Liste innerhalb der alten Topping-Liste gesucht...
      let founded = singledish.toppings.find(
        topp => topping.name === topp.name
      );
      // ...wenn nichts gefunden wurde, der Typ also "undefined" ist...
      if (typeof founded == "undefined") {
        // ...muss gecheckt werden, ob die neue Zutat doppelt hinzugefügt wurde oder nicht.
        if (topping.doubled) {
          changes.push(`+ Doppelt ${topping.name}`);
        } else {
          changes.push(`+ ${topping.name}`);
        }
      }
    }
    sauceValue = this.objectify(sauceValue, "availableSauces");
    garnishValue = this.objectify(garnishValue, "availableGarnishes");
    saladValue = this.objectify(saladValue, "availableSalads");
    if (toppings[0].price) {
      let sizing = currSize;
      if (currSize === "") {
        sizing = singledish.pricing[0].name;
      }
      let obj = singledish.pricing.find(o => o.name === sizing);
      for (let i = 0; i < toppings.length; i++) {
        if (typeof singledish.toppings[i] !== "undefined") {
          onTop -= singledish.toppings[i].price * obj.factor;
        }
        if (toppings[i].doubled) {
          onTop += toppings[i].price * 2 * obj.factor;
        } else {
          onTop += toppings[i].price * obj.factor;
        }
      }
    }
    let cartItem = {
      name: singledish.name,
      toppings: toppingList,
      sauce: sauceValue.name,
      garnish: garnishValue.name,
      salad: saladValue.name,
      size: curSize,
      price: currPrice + onTop,
      quantity: this.state.currNumber,
      changes
    };
    this.showSuccessfulAdded(true, cartItem);
    this.props.addCheckOutItem(cartItem);
    localStorage.setItem(
      "checkout",
      JSON.stringify([...this.props.checkout.checkoutItems, cartItem])
    );
    this.setState({ currNumber: 1 });
    this.defaultToppings();
  };

  /**
   * changes the current number of this dish and sets the new currNumber-state based on the current state
   * @param value the value which operator was clicked
   */
  changeCurrNumber = value => {
    if (value === "-" && this.state.currNumber !== 1) {
      this.setState({ currNumber: this.state.currNumber - 1 });
    } else if (value === "+") {
      this.setState({ currNumber: this.state.currNumber + 1 });
    }
  };

  /**
   * consumes the current toppings adds the price of the other factors an the toppings based on the double state and the factor and returns the on top cost as a number
   * @param toppings the currently added toppings
   * @return the total on top costs as number
   */
  checkPrice = toppings => {
    let { singledish } = this.props;
    const { sauceValue, garnishValue, saladValue, normalPrice } = this.state;
    let factor = singledish.pricing.find(
      price => price.price === normalPrice.price
    );
    let onTop = sauceValue.price + garnishValue.price + saladValue.price;
    if (toppings.length > 1 && toppings[0].price) {
      for (let i = 0; i < singledish.toppings.length; i++) {
        if (typeof singledish.toppings[i] !== "undefined") {
          onTop -= singledish.toppings[i].price * factor.factor;
        }
      }
      for (let i = 0; i < toppings.length; i++) {
        onTop +=
          toppings[i].price * (toppings[i].doubled ? 2 : 1) * factor.factor;
      }
    }
    return onTop > 0 ? onTop : 0;
  };

  defaultToppings = () => {
    this.setState((state, props) => {
      return {
        toppings: [],
        currNumber: 1
      };
    });
  };

  handleChange = e => {
    let price = this.props.singledish.pricing.find(
      price => price.name === e.target.value
    );
    this.setState({
      value: e.target.value,
      currPrice: price.price,
      normalPrice: price
    });
    this.returnCurrPrice(price.price, e.target.value);
  };

  objectify = (key, value) => {
    const { singledish } = this.props;
    if (key.name === "") {
      if (singledish[value][0].name) {
        return singledish[value][0];
      } else {
        return { name: "", price: 0 };
      }
    } else {
      return key;
    }
  };

  renderValue = (value, stateComponent) => {
    if (this.state[stateComponent] !== value) {
      this.setState({ [stateComponent]: value });
    }
  };

  returnCurrPrice = (currentPrice, size) => {
    if (!size) {
      if (
        typeof this.props.singledish.pricing[0] !== "undefined" &&
        typeof this.props.singledish.pricing[0].name !== "undefined"
      ) {
        if (this.state.currSize !== size) {
          this.setState({
            currSize: this.props.singledish.pricing[0].name
          });
        }
      } else {
        if (this.state.currSize !== size) {
          this.setState({ currSize: "Normal" });
        }
      }
    } else {
      if (this.state.currSize !== size) {
        this.setState({ currSize: size });
      }
    }
    if (this.state.currPrice !== currentPrice) {
      this.setState({ currPrice: currentPrice });
    }
  };

  showSuccessfulAdded = (bool, obj) => {
    this.setState({ succesfullAdded: bool, addedItems: obj });
  };

  render() {
    let { singledish, additives, modal, restaurant } = this.props;
    let { currNumber, succesfullAdded, addedItems, showAdditives } = this.state;
    // choosenToppings sind entweder die aktuellen Toppings (wenn bereits bearbeitet wurde) ansonsten die standard toppings des singleDish im Redux-Store
    return (
      <div className="dish-edit-view">
        {!modal && (
          <>
            <div className="edit-view-header">
              <div className="image-row">
                <button onClick={() => window.history.back()}>
                  <IoMdArrowRoundBack className="back-button-icon" />
                </button>
                <img
                  className="dish-image"
                  src={singledish.image}
                  alt={`Bild von ${singledish.name}`}
                />
                <button
                  onClick={() =>
                    window.open(`/${restaurant.url}/checkout`, "_self")
                  }
                >
                  <img
                    src={Receipt}
                    alt="Platzhalter einer Rechnung als Button zum Warenkorb"
                  />
                  <p>Warenkorb</p>
                </button>
              </div>
              <div className="dish-name-and-additives">
                <div className="heading-and-additiv-info">
                  <h1 className="single-dish-view-heading">
                    {singledish.name}
                  </h1>
                  <button
                    onClick={() => this.setState({ showAdditives: true })}
                  >
                    <img src={InfoButton} alt="Bild des Info-Buttons" />
                  </button>
                </div>
                <div className="dish-toppings-and-info">
                  <InfoSingleDish info={singledish.info} />
                  <ChoosenToppings />
                </div>
              </div>
            </div>
            <div className="edit-view-body row">
              {singledish.availableToppings[0].name && <ChangeToppings />}
              <div className="select-div col-12 col-sm-6">
                {singledish.availableSauces[0].name && (
                  <ChangeSauce
                    ref={this.changeSauceToReset}
                    sauces={singledish.availableSauces}
                    checkSauce={sauce => this.renderValue(sauce, "sauceValue")}
                  />
                )}
                {singledish.availableGarnishes[0].name && (
                  <ChangeGarnishes
                    ref={this.changeGarnishToReset}
                    garnishes={singledish.availableGarnishes}
                    checkGarnish={garnish =>
                      this.renderValue(garnish, "garnishValue")
                    }
                  />
                )}
                {singledish.availableSalads[0].name && (
                  <ChangeSalads
                    ref={this.changeSaladToReset}
                    salads={singledish.availableSalads}
                    checkSalad={salad => this.renderValue(salad, "saladValue")}
                  />
                )}
                {singledish.pricing.length > 1 && (
                  <>
                    <h3 className="changers-subheading">Größe wählen</h3>
                    <select
                      className="select-list"
                      value={this.state.value}
                      onChange={this.handleChange}
                    >
                      {singledish.pricing.map((price, i) => (
                        <option key={i} value={`${price.name}`}>
                          {price.name}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </div>
            </div>
            <div className="edit-view-end">
              <div>
                <div className="new-number-changer">
                  <p>Anzahl:</p>
                  <button onClick={() => this.changeCurrNumber("-")}>-</button>
                  <p>{currNumber}</p>
                  <button onClick={() => this.changeCurrNumber("+")}>+</button>
                </div>
                <p className="edit-view-price">
                  Gesamtpreis:{" "}
                  {(
                    (this.state.currPrice +
                      this.checkPrice(singledish.choosenToppings)) *
                    currNumber
                  )
                    .toFixed(2)
                    .replace(".", ",") + "€"}
                </p>
              </div>
              <div className="reset-and-add">
                <button
                  onClick={() => this.props.resetToppings(singledish)}
                  className="edit-view-reset-button"
                >
                  <IoMdTrash className="trash" />
                </button>
                <button
                  onClick={() => {
                    this.addClicked(singledish.choosenToppings);
                    this.props.resetToppings(singledish);
                  }}
                  onMouseEnter={() => this.setState({ cartIcon: WhiteCart })}
                  onMouseLeave={() => this.setState({ cartIcon: GreenCart })}
                  onTouchStart={() => this.setState({ cartIcon: WhiteCart })}
                  onTouchEnd={() => this.setState({ cartIcon: GreenCart })}
                  className="edit-view-add-to-cart-button"
                >
                  <img
                    src={this.state.cartIcon}
                    alt="Symbol eines Einkaufswagens auf dem Button 'zum Warenkorb hinzufügen'"
                    className="addButton"
                  />
                </button>
              </div>
            </div>
            {showAdditives && (
              <div className="successfull-added additives">
                <IoIosInformationCircleOutline
                  color="rgb(110, 156, 98)"
                  size="35px"
                />
                <h3>Inhalts- und Zusatzstoffe</h3>
                <p>
                  Folgende Angaben wurden seitens des Restaurants zu den
                  Inhaltsstoffen der jeweiligen Gerichte gemacht:
                </p>
                {additives.map(add => (
                  <div className="additive-div">
                    <p>{add.name}</p>
                    {add.additives.map(singleAdd => (
                      <p>
                        {singleAdd.name} in {singleAdd.in}
                      </p>
                    ))}
                  </div>
                ))}
                <p>
                  Trotz großer Sorgfalt kann seitens des Restaurants nicht
                  ausgeschlossen werden, dass bei der Herstellung der Speisen
                  Allergene vermischt werden.
                </p>
                <div className="succesfull-added-buttons additive-button">
                  <button
                    onClick={() => this.setState({ showAdditives: false })}
                  >
                    Verstanden
                  </button>
                </div>
              </div>
            )}
            {succesfullAdded && (
              <div className="successfull-added">
                <IoIosCheckmarkCircleOutline
                  color="rgb(110, 156, 98)"
                  size="45px"
                />
                <h3>Erfolgreich hinzugefügt</h3>
                <p>
                  Sie haben {addedItems.quantity}x {addedItems.name} erfolgreich
                  zum Warenkorb hinzugefügt
                </p>
                <div className="succesfull-added-buttons">
                  <button
                    onClick={() => this.setState({ succesfullAdded: false })}
                  >
                    Zurück zum Gericht
                  </button>
                  <button
                    onClick={() =>
                      window.open(`/${restaurant.url}/checkout`, "_self")
                    }
                  >
                    Zum Warenkorb
                  </button>
                  <button
                    onClick={() =>
                      window.open(`/${restaurant.url}/cart`, "_self")
                    }
                  >
                    Zur Speisekarte
                  </button>
                </div>
              </div>
            )}
          </>
        )}
        {modal && <EditToppingModal />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  singledish: state.singledish.dish,
  restaurant: state.restaurant,
  modal: state.singledish.showModal,
  checkout: state.checkout,
  additives: state.restaurant.menu.dishes
});

export default connect(
  mapStateToProps,
  { addCheckOutItem, toggleComponents, resetToppings }
)(DishEditView);
