import React from "react";
import { useSelector } from "react-redux";

export default function EmptyCart(props) {
  const restaurant = useSelector(state => state.restaurant);
  return (
    <div className="empty-checkout">
      <h3>Es befinden sich derzeit keine Gerichte im Warenkorb.</h3>
      <button onClick={() => window.open(`/${restaurant.url}/cart`, "_self")}>
        Zur Speisekarte
      </button>
    </div>
  );
}
