import React, { Component } from "react";

export class ChangeGarnishes extends Component {
  state = {
    value: ""
  };

  handleChange = e => {
    const { garnishes } = this.props;
    this.setState({ value: e.target.value });
    for (let i = 0; i < garnishes.length; i++) {
      if (e.target.value === garnishes[i].name) {
        this.props.checkGarnish(garnishes[i]);
      }
    }
  };

  papaSaidChangeGarnish = () => {
    this.setState({ value: "" });
  };

  renderOptions = garnishes => {
    return garnishes.map(garnish => (
      <option key={garnish.name} value={garnish.name}>
        {garnish.name}
      </option>
    ));
  };

  render() {
    const { garnishes } = this.props;
    if (garnishes[0].price === 0 || garnishes[0].price !== null) {
      return (
        <>
          <h3 className="changers-subheading">Beilagen bearbeiten</h3>
          <select
            className="select-list"
            value={this.state.value}
            onChange={this.handleChange}
          >
            {this.renderOptions(garnishes)}
          </select>
        </>
      );
    } else {
      return null;
    }
  }
}

export default ChangeGarnishes;
