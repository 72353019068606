import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { editToppings } from "../../../actions/single-dish-actions";

import { IoIosCheckmark, IoIosDoneAll } from "react-icons/io";

const AvaibleState = (avaible, singledish) => {
  const dispatch = useDispatch();
  const toppings = singledish.choosenToppings;
  for (let i = 0; i < toppings.length; i++) {
    if (toppings[i].name === avaible.name) {
      if (toppings[i].doubled) {
        return (
          <button
            className="available-topping"
            onClick={() => dispatch(editToppings(toppings[i], singledish))}
          >
            <p>{toppings[i].name}</p>
            <IoIosDoneAll className="checkmark" />
          </button>
        );
      }
      // Wenn ein Belag nicht doubleable ist, darf er auch nicht verdoppelt werden. Bitte rausfinden
      return (
        <button
          className="available-topping"
          onClick={() => dispatch(editToppings(toppings[i], singledish))}
        >
          <p>{toppings[i].name}</p>
          <IoIosCheckmark className="checkmark" />
        </button>
      );
    }
  }
  return (
    <button
      className="available-topping"
      onClick={() => dispatch(editToppings(avaible, singledish))}
    >
      {avaible.name}
    </button>
  );
};

export default function AvailableTopping(props) {
  const singledish = useSelector((state) => state.singledish.dish);
  const { available } = props;
  return <div>{AvaibleState(available, singledish)}</div>;
}
