import React, { Component } from "react";
import "./App.css";
import "../../../node_modules/bootstrap-4-grid/css/grid.css";

import Header from "./header/Header";
import UserOrRestaurant from "./user_restaurant_advantages/UserOrRestaurants";
import Contact from "./contact/Contact";

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="body-wrapper">
          <Header />
          <UserOrRestaurant />
          <Contact />
        </div>
      </div>
    );
  }
}

export default App;
