const initialState = {
  logSignModal: false,
  typeVal: "Liefern lassen",
  timeVal: "Direktbestellung"
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "SET_LOG_SIGN":
      return {
        ...state,
        logSignModal: action.payload
      };
    case "SET_TYPE_VAL":
      return {
        ...state,
        typeVal: action.payload
      };
    case "SET_TIME_VAL":
      return {
        ...state,
        timeVal: action.payload
      };
    default:
      return state;
  }
}
