import React, { Component } from 'react';

import ToppingComponent from './checkComponents/ToppingComponent';
import SauceComponent from './checkComponents/SauceComponent';
import GarnishComponent from './checkComponents/GarnishComponent';
import SaladComponent from './checkComponents/SaladComponent';

export class CheckDishProperties extends Component {
    render() {
        const {
            number,
            name,
            example,
            catNumber,
            topicString,
            topic,
            prefactored,
            choosableTopicsString,
            choosableTopics,
            maxAddToppingsString,
            maxAddToppings,
            changeState,
            setAddableToppings
        } = this.props;

        const components = {
            choosableToppings: ToppingComponent,
            choosableSauces: SauceComponent,
            choosableGarnishes: GarnishComponent,
            choosableSalads: SaladComponent
        };
        const SpecificComponent = components[choosableTopicsString];
        return (
            <React.Fragment>
                <hr />
                <div className="dish-propertie-check">
                    <p className="weekday_true_false">{name}</p>
                    <p className="label-example">{example}</p>
                    <input
                        type="radio"
                        id={`catno-${catNumber}-${topicString}-dishno-${number}-true`}
                        name={`${topicString}`}
                        value="true"
                        onChange={changeState}
                    />
                    <label
                        className="label_true_false"
                        htmlFor={`catno-${catNumber}-${topicString}-dishno-${number}-true`}
                    >
                        {' '}
                        Ja
                    </label>
                    <input
                        type="radio"
                        id={`catno-${catNumber}-${topicString}-dishno-${number}-false`}
                        name={`${topicString}`}
                        onChange={changeState}
                        value=""
                        defaultChecked
                    />
                    <label
                        className="label_true_false"
                        htmlFor={`catno-${catNumber}-${topicString}-dishno-${number}-false`}
                    >
                        {' '}
                        Nein
                    </label>
                    <br />
                </div>
                {topic && (
                    <SpecificComponent
                        number={number}
                        catNumber={catNumber}
                        choosableTopicsString={choosableTopicsString}
                        choosableTopics={choosableTopics}
                        maxAddToppingsString={maxAddToppingsString}
                        maxAddToppings={maxAddToppings}
                        changeState={changeState}
                        prefactored={prefactored}
                        setAddableToppings={setAddableToppings}
                    />
                )}
                <hr />
            </React.Fragment>
        );
    }
}

export default CheckDishProperties;
