import React from "react";
import { useSelector } from "react-redux";

const renderToppingNames = toppings => {
  let toppingNames = [];
  toppings.forEach(topping => {
    topping.doubled
      ? toppingNames.push("Doppelt " + topping.name)
      : toppingNames.push(topping.name);
  });
  return toppingNames.join(", ");
};

export default function ChoosenToppings(props) {
  const singledish = useSelector(state => state.singledish.dish);
  const { toppings } = props;
  /* render choosenToppings in dish-edit-view */
  if (singledish && !window.location.href.includes("cart")) {
    if (
      singledish.choosenToppings[0].price === 0 ||
      singledish.choosenToppings[0].price !== null
    ) {
      return <div>{renderToppingNames(singledish.choosenToppings)}</div>;
    }
  }
  /* render start-toppings in cart */
  if (toppings) {
    return (
      <div className="cart-topping-names">{renderToppingNames(toppings)}</div>
    );
  }
  return null;
}
