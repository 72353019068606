import React, { Component } from 'react';

import Category from './Category';

export class DishCategories extends Component {
    state = {
        returnedCats: 1
    };

    renderCats = () => {
        let cats = [];
        for (let i = 1; i <= this.state.returnedCats; i++) {
            cats.push(<Category key={i} number={i} />);
        }
        return cats;
    };

    addCats = e => {
        e.preventDefault();
        if (e.target.name === 'add') {
            this.setState({ returnedCats: this.state.returnedCats + 1 });
        } else {
            if (this.state.returnedCats > 0) {
                this.setState({ returnedCats: this.state.returnedCats - 1 });
            }
        }
    };

    render() {
        return (
            <div className="col-12">
                <p className="categorie-heading col-10 col-md-6 col-lg-5">
                    Gerichts-Kategorien
                </p>
                <p className="label-info col-10 col-md-6 col-lg-5">
                    Bitte füge die verschiedenen Kategorien nach den Angeboten
                    des Restaurants ein. <br />
                    Füge dazu einen Link zu dem "Kategorie-Titelbild" ein.
                </p>
                <p className="label-example col-10 col-md-6 col-lg-5">
                    Beispiel: Die Gaststätte zur Linde hat folgende Kategorien:{' '}
                    <br />
                    Pizzen
                    <br />
                    Nudelgerichte
                    <br />
                    Fleischgerichte
                    <br />
                    Burger
                    <br />
                    ...
                </p>
                <div className="cats mx-auto col-10 col-md-6 col-lg-5">
                    {this.renderCats()}
                </div>
                <div className="mx-auto col-10 col-md-6 col-lg-5">
                    <button
                        className="cat-btn col-6"
                        name="add"
                        onClick={this.addCats}
                    >
                        + Kategorie hinzufügen
                    </button>
                    <button
                        className="cat-btn col-6"
                        name="delete"
                        onClick={this.addCats}
                    >
                        - Kategorie entfernen
                    </button>
                </div>
            </div>
        );
    }
}

export default DishCategories;
