let day = new Date(),
  std = day.getHours(),
  min = day.getMinutes(),
  monthday = day.getDate(),
  weekday = day.getDay(),
  // Wenn die Minutenanzahl einstellig, also kleiner/gleich 9, dann muss eine Null davorgesetzt werden, ansonsten kann der Fehler auftreten, dass 22:07 Uhr als 227 bezeichnet wird. Welches dann z.B. bei der Linde kleiner ist als 1700 und dementsprechend wird die Zeit als geschlossen ausgegeben.
  currMins = min <= 9 ? "0" + min : min,
  curr_std_min = parseInt(std + "" + currMins);

const allDays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
];

const germanNomen = [
  "Sonntag",
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag"
];
const germanAdverb = [
  "sonntags",
  "montags",
  "dienstags",
  "mittwochs",
  "donnerstags",
  "freitags",
  "samstags"
];

const returnValue = (
  currOpen,
  isOpeningToday,
  hasOpenedToday,
  isOpeningAnOtherDay
) => {
  return {
    weekdayNomen: germanNomen[weekday],
    weekdayAdverb: germanAdverb[weekday],
    currOpen,
    isOpeningToday,
    hasOpenedToday,
    isOpeningAnOtherDay
  };
};

const todaysTime = (soe, openingTime, specialCheck = false) => {
  try {
    if (!specialCheck) {
      return parseInt(openingTime[allDays[weekday]][soe].replace(":", ""));
    } else {
      return parseInt(
        openingTime[allDays[weekday]].special.time[soe].replace(":", "")
      );
    }
  } catch (err) {
    console.log(
      "Es ist ein Fehler aufgetreten: Der eingegebene Wert: " +
        soe +
        ' ist nicht gültig. Bitte wählen Sie "start", "end", "breakstart" oder "breakend"'
    );
  }
};

export const checkDate = openingTime => {
  if (openingTime[allDays[weekday]].start) {
    if (openingTime[allDays[weekday]].breakstart) {
      if (
        curr_std_min >= todaysTime("breakstart", openingTime) &&
        curr_std_min <= todaysTime("breakend", openingTime)
      ) {
        return returnValue(false, true, true, false);
      } else if (
        (curr_std_min >= todaysTime("start", openingTime) &&
          curr_std_min < todaysTime("breakstart", openingTime)) ||
        (curr_std_min > todaysTime("breakend", openingTime) &&
          curr_std_min <= todaysTime("end", openingTime))
      ) {
        return returnValue(true, false, false, false);
      } else {
        return returnValue(false, false, true, false);
      }
    } else {
      if (
        curr_std_min >= todaysTime("start", openingTime) &&
        curr_std_min <= todaysTime("end", openingTime)
      ) {
        return returnValue(true, false, false, false);
      } else if (curr_std_min < todaysTime("start", openingTime)) {
        return returnValue(false, true, false, false);
      } else {
        return returnValue(false, false, true, false);
      }
    }
  } else if (
    openingTime[allDays[weekday]].special.minDate ||
    openingTime[allDays[weekday]].special.maxDate
  ) {
    if (monthday < openingTime[allDays[weekday]].special.maxDate) {
      if (
        curr_std_min >= todaysTime("start", openingTime, true) &&
        curr_std_min <= todaysTime("end", openingTime, true)
      ) {
        return returnValue(true, false, false, false);
      } else if (curr_std_min < todaysTime("start", openingTime, true)) {
        return returnValue(false, true, false, false);
      } else {
        return returnValue(false, false, true, false);
      }
    } else {
      return returnValue(false, false, false, true);
    }
  } else {
    return returnValue(false, false, false, false);
  }
};

export const getTimes = openingTime => {
  if (openingTime[allDays[weekday]].start) {
    if (openingTime[allDays[weekday]].breakstart) {
      if (
        curr_std_min >= todaysTime("start", openingTime) &&
        curr_std_min <= todaysTime("breakstart", openingTime)
      ) {
        return {
          min: openingTime[allDays[weekday]].start,
          max: openingTime[allDays[weekday]].breakstart
        };
      } else if (
        curr_std_min >= todaysTime("breakend", openingTime) &&
        curr_std_min <= todaysTime("end", openingTime)
      ) {
        return {
          min: openingTime[allDays[weekday]].breakend,
          max: openingTime[allDays[weekday]].end
        };
      } else {
        return false;
      }
    } else {
      return {
        min: openingTime[allDays[weekday]].start,
        max: openingTime[allDays[weekday]].end
      };
    }
  } else if (
    openingTime[allDays[weekday]].special.minDate ||
    openingTime[allDays[weekday]].special.maxDate
  ) {
    if (monthday < openingTime[allDays[weekday]].special.maxDate) {
      if (openingTime[allDays[weekday]].special.time.breakstart) {
        if (
          curr_std_min >= todaysTime("start", openingTime, true) &&
          curr_std_min <= todaysTime("breakstart", openingTime, true)
        ) {
          return {
            min: openingTime[allDays[weekday]].special.time.start,
            max: openingTime[allDays[weekday]].special.time.breakstart
          };
        } else if (
          curr_std_min >= todaysTime("breakend", openingTime, true) &&
          curr_std_min <= todaysTime("end", openingTime, true)
        ) {
          return {
            min: openingTime[allDays[weekday]].special.time.breakend,
            max: openingTime[allDays[weekday]].special.time.end
          };
        } else {
          return false;
        }
      } else {
        return {
          min: openingTime[allDays[weekday]].special.time.start,
          max: openingTime[allDays[weekday]].special.time.end
        };
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getTimeInHrs = () => {
  let date = new Date();
  return {
    hours: date.getHours,
    mins: date.getMinutes,
    seconds: date.getSeconds
  };
};

export const getWords = (hasOpened, willOpen, openingTime) => {
  let generalWord, information;
  if (!hasOpened && !willOpen) {
    generalWord = "Heute";
    information = germanAdverb[weekday] + " Ruhetag";
  } else if (!hasOpened && willOpen) {
    generalWord = "Jetzt";
    if (openingTime[allDays[weekday]].special.time.start) {
      information =
        "Öffnet heute um " +
        openingTime[allDays[weekday]].special.time.start +
        " Uhr.";
    } else {
      information =
        "Öffnet heute um " + openingTime[allDays[weekday]].start + " Uhr.";
    }
  } else if (hasOpened && !willOpen) {
    generalWord = "Jetzt";
    if (openingTime[allDays[weekday]].special.time.start) {
      information =
        "War bis " +
        openingTime[allDays[weekday]].special.time.start +
        +" geöffnet.";
    } else {
      information =
        "War bis " + openingTime[allDays[weekday]].end + " geöffnet.";
    }
  } else {
    generalWord = "Derzeit";
    information =
      germanAdverb[weekday] +
      " von " +
      openingTime[allDays[weekday]].start +
      " bis " +
      openingTime[allDays[weekday]].end +
      " Uhr";
  }
  return {
    general: generalWord,
    information
  };
};

export const returnNames = {
  weekdayNomen: germanNomen[weekday],
  weekdayAdverb: germanAdverb[weekday]
};

export const showTimes = openingTime => {
  var dayArray = [];
  allDays.forEach(function(weekday, i) {
    if (openingTime[weekday].start !== null) {
      if (openingTime[weekday].breakstart !== null) {
        dayArray.push(
          germanAdverb[i] +
            " • " +
            openingTime[weekday].start +
            " bis " +
            openingTime[weekday].breakstart +
            " und von " +
            openingTime[weekday].breakend +
            " bis" +
            openingTime[weekday].end +
            " Uhr"
        );
      } else {
        dayArray.push(
          germanAdverb[i] +
            "  •  " +
            openingTime[weekday].start +
            " bis " +
            openingTime[weekday].end +
            " Uhr"
        );
      }
    } else {
      dayArray.push(germanAdverb[i] + " geschlossen");
    }
  });
  dayArray.push(dayArray.shift());
  return dayArray;
};
