import React, { Component } from "react";
import { Query, Subscription } from "react-apollo";

import { queryAllOrders } from "../queries";
import NewestOrder from "./NewestOrder";
import SingleOrder from "./SingleOrder";
import { connect } from "mqtt";

const OrderedDishes_Query = queryAllOrders;

/* const NewOrder = orderSubscritption; */

let val = null;

let mqttClient;

export class LiveOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      allOrders: [],
    };
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_URL}`, {
      method: "POST",
      body: JSON.stringify({
        query: `
          query {
            showAllOrders(partnerId: "${this.props.partnerId}") {
              _id
              type
              orderer {
                firstName
                lastName
                phone
                zip
                city
                street
                houseNr
                moreInformation
              }
              orderedProducts {
                name
                price
                quantity
                customizations {
                  name
                  selectedOption
                }
              }
              total
              done
              whenDone
              date
              timeHrs
              orderTime {timeType wishTime}
            }
          }
                `,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((resData) => {
        return this.setState({
          allOrders: resData.data.showAllOrders,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const getOS = () => {
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Mac OS";
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
      } else if (/Android/.test(userAgent)) {
        os = "Android";
      } else if (!os && /Linux/.test(platform)) {
        os = "Linux";
      }

      return os;
    };

    let { loading, allOrders } = this.state;
    const { partnerId, partner } = this.props;

    let that = this;

    if (!mqttClient) {
      mqttClient = connect(process.env.REACT_APP_MQTT, {
        reconnectPeriod: 1000,
      });

      mqttClient.on("connect", function () {
        console.log("🚀 MQTT connected");
      });

      mqttClient.subscribe(`partner/${partnerId}/printOrder`);

      mqttClient.subscribe(`control/frontend`);

      mqttClient.on("message", function (topic, message) {
        message = message.toString();
        if (topic === "control/frontend") {
          switch (message) {
            case "reload":
              mqttClient.end();
              window.location.reload();
              break;
          }
          return;
        }
        let newOrder = JSON.parse(message);
        console.log("hallo");
        if (!newOrder.printOnlyOne) {
          that.setState({ allOrders: [newOrder, ...that.state.allOrders] });
        }
      });
    }

    if (loading) {
      return <p>Lädt...</p>;
    }

    return (
      <div className="allOrders">
        <h4 className="order-done-info">
          Um Bestellungen als erledigt zu markieren die jeweilige Bestellung
          antippen!
        </h4>

        {allOrders.length === 0 ? (
          <p>Noch keine Bestellungen</p>
        ) : (
          allOrders.map((order) => (
            <SingleOrder key={order._id} order={order} partner={partner} />
          ))
        )}

        <iframe title="printiFrame" id="ifmconteeent" />
        <iframe title="printiFrame" className="ifmcontent" />
        <iframe title="printiFrame" className="ifmcontent" />
        <iframe title="printiFrame" className="ifmcontent" />
      </div>
    );
  }
}

export default LiveOrders;
