const initialState = {
  landingComponent: true,
  cartComponent: false,
  dishComponent: false,
  checkOutComponent: false,
  prev: ""
};

const setEverythingToFalse = () => {
  return {
    landingComponent: false,
    cartComponent: false,
    dishComponent: false,
    checkOutComponent: false
  };
};

export const toggle = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE":
      if (action.payload[1] === "back") {
        switch (state.prev) {
          case "cartComponent":
            return {
              ...state,
              ...setEverythingToFalse(),
              cartComponent: true
            };
          case "dishComponent":
            if (state.dishComponent) {
              return {
                ...state,
                ...setEverythingToFalse(),
                cartComponent: true
              };
            }
            return {
              ...state,
              ...setEverythingToFalse(),
              dishComponent: true
            };
          default:
            return {
              ...state,
              ...setEverythingToFalse(),
              landingComponent: true
            };
        }
      }
      state.prev = action.payload[1];
      switch (action.payload[0]) {
        case "LANDINGPAGE":
          return {
            ...state,
            ...setEverythingToFalse(),
            landingComponent: true
          };
        case "CARTCOMPONENT":
          return {
            ...state,
            ...setEverythingToFalse(),
            cartComponent: true
          };
        case "DISH":
          return {
            ...state,
            ...setEverythingToFalse(),
            dishComponent: true,
            prev: action.payload[1]
          };
        case "CHECKOUT":
          return {
            ...state,
            ...setEverythingToFalse(),
            checkOutComponent: true
          };
        default:
          return console.log("default error");
      }
    default:
      return state;
  }
};
