import React, { Component } from "react";

export class MenuSchema extends Component {
  render() {
    const {
      name,
      number,
      info,
      dishNumber,
      catNumber,
      doubleState,
      doubleState_string
    } = this.props;
    return (
      <div className="col-12 schema">
        <h3>{name}</h3>
        <div className="menu-schema">
          <label
            htmlFor={`${info}-menu-schema-name-no${number}-dishno${dishNumber}-catno${catNumber}`}
            className="col-3"
          >
            Name
          </label>
          <input
            type="text"
            id={`${info}-menu-schema-name-no${number}-dishno${dishNumber}-catno${catNumber}`}
            className="col-6"
          />
        </div>
        <div className="menu-schema">
          <label
            htmlFor={`menu-schema-valence-no${number}-dishno${dishNumber}-catno${catNumber}`}
            className="col-3"
          >
            Wertigkeit
          </label>
          <input
            type="text"
            id={`menu-schema-valence-no${number}-dishno${dishNumber}-catno${catNumber}`}
            className="col-6"
          />
        </div>
        <div className="menu-schema">
          <label className="col-3">{doubleState_string}</label>
          <div className="menu-schema offset-2 col-3">
            <input
              type="radio"
              id={`${info}-menu-schema-${doubleState}-no${number}-dishno${dishNumber}-catno${catNumber}-true`}
              name={`${info + doubleState + number}`}
              value="true"
              onChange={this.changeState}
            />
            <label
              className="label_true_false"
              htmlFor={`${info}-menu-schema-${doubleState}-no${number}-dishno${dishNumber}-catno${catNumber}-true`}
            >
              {" "}
              Ja
            </label>
          </div>
          <div className="menu-schema col-3">
            <input
              type="radio"
              id={`${info}-menu-schema-${doubleState}-no${number}-dishno${dishNumber}-catno${catNumber}-false`}
              name={`${info + doubleState + number}`}
              onChange={this.changeState}
              value=""
              defaultChecked
            />
            <label
              className="label_true_false"
              htmlFor={`${info}-menu-schema-${doubleState}-no${number}-dishno${dishNumber}-catno${catNumber}-false`}
            >
              {" "}
              Nein
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuSchema;
