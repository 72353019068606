import React, { Component } from "react";
import AppStore from "../../../images/Appstore Black.png";
import oldPage from "../../../images/doigastro-old.png";
import "./header.css";
import groupIcon from "../../../images/grouporder.png";

class Header extends Component {
  render() {
    return (
      <div className="new-header-img">
        <img
          className="new-title-pic"
          src={oldPage}
          alt="Titelbild doiGastro"
        />
        <div className="new-header-overlay">
          <h3>Aus doiGastro wird doiApp</h3>
          <div className="corona-reason">
            <img src={groupIcon} alt="Gruppen-Bestellung-Icon" />
            <p>
              In Anbetracht der vielzitierten "aktuellen Situation" und vieler
              Anfragen in diesem Zuge haben wir uns dazu entschlossen, aus
              doiGastro nun doiApp werden zu lassen.
            </p>
          </div>
        </div>
        {/* 
          <RestaurantOverview /> */}
      </div>
    );
  }
}

export default Header;
