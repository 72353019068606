import React, { Component } from "react";

export class InfoSingleDish extends Component {
  render() {
    const { info } = this.props;
    if (info) {
      return <div>{info}</div>;
    } else {
      return null;
    }
  }
}

export default InfoSingleDish;
