import React, { Component } from "react";
import "./contact.css";

export class Contact extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    mailSent: false,
    nameError: false,
    emailError: false,
    messageError: false,
  };

  checkKey = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };

  handleContactSubmit = (e) => {
    e.preventDefault();
    let { firstName, lastName, email, message } = this.state;
    // Zunächst werden alle potentiellen Error und die Einstellung, ob die Nachricht bereits gesendet wurde auf false geschaltet, damit die Warnungen verschwinden.
    this.setState({
      mailSent: false,
      emailError: false,
      nameError: false,
      messageError: false,
    });
    // Anschließend werden zunächst die Email und die Nachrich validiert, dann die Namen
    if (!this.validateEmail(email)) return this.setState({ emailError: true });
    if (this.nameCheck(message)) {
      this.setState({ messageError: true });
      return;
    }
    if (this.nameCheck(firstName) || this.nameCheck(lastName)) {
      return this.setState({ nameError: true });
    }
    // Wenn alle 3 Bedingungen erfüllt sind, kann die Mail geschickt werden
    if (
      !this.state.emailError &&
      !this.state.nameError &&
      !this.state.messageError
    ) {
      fetch(`${process.env.REACT_APP_URL}`, {
        method: "POST",
        body: JSON.stringify({
          query: `
          mutation {
            newMail (input: {
              firstName: "${firstName}"
              lastName: "${lastName}"
              email: "${email}"
              message: "${message}"
            })
            {
              firstName
            }
          }
        `,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((resData) => {
          // Zum Abschluss wird alles auf 0 gesetzt, und die Info, dass die Mail gesendet
          this.setState({
            firstName: "",
            lastName: "",
            email: "",
            message: "",
            mailSent: true,
            emailError: false,
            messageError: false,
            nameError: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  nameCheck = (name) => {
    let newName = name.replace(/\s+/g, "");
    if (newName.length > 0) return false;
    return true;
  };

  validateEmail = (email) => {
    let testObj =
      /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return testObj.test(email);
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      message,
      mailSent,
      emailError,
      messageError,
      nameError,
    } = this.state;
    return (
      <div className="contact-field">
        {/* <h1>Kontaktiere uns</h1>
        <div className="row">
          <div className="col-12">
            <p className="contact-sub-text col-lg-7">
              Für Anregungen, konstruktive Kritik oder andere Anliegen sind wir
              stets dankbar. Fülle einfach das unten stehende Formular aus, um
              mit uns in Kontakt zu treten.
            </p>
            <form>
              <input
                type="text"
                name="firstname_contact"
                placeholder="Vorname"
                className="col-sm-4 col-md-5 col-10"
                value={firstName}
                onChange={e =>
                  this.setState({ firstName: e.target.value, mailSent: false })
                }
              />
              <input
                type="text"
                name="lastname_contact"
                placeholder="Nachname"
                className="col-sm-4 col-md-5 col-10"
                value={lastName}
                onChange={e =>
                  this.setState({ lastName: e.target.value, mailSent: false })
                }
              />
              <input
                type="email"
                name="email_contact"
                placeholder="E-Mail Adresse*"
                className="col-md-5 col-10"
                value={email}
                required
                onKeyDown={e => this.checkKey(e)}
                onChange={e =>
                  this.setState({ email: e.target.value, mailSent: false })
                }
              />
              <textarea
                name="Text1"
                rows="8"
                placeholder="Deine Nachricht...*"
                value={message}
                required
                onChange={e =>
                  this.setState({ message: e.target.value, mailSent: false })
                }
              />
              <input
                onClick={e => this.handleContactSubmit(e)}
                type="submit"
                name="contact_submit"
                value="Senden"
              />
            </form>
            {mailSent && (
              <div className="messages success-msg">
                <p>Vielen Dank, deine Nachricht ist auf dem Weg zu uns.</p>
              </div>
            )}
            {nameError && (
              <div className="messages error-msg">
                <p>Bitte einen vollständigen Namen angeben.</p>
              </div>
            )}
            {emailError && (
              <div className="messages error-msg">
                <p>Bitte eine gültige E-Mail-Adresse angeben.</p>
              </div>
            )}
            {messageError && (
              <div className="messages error-msg">
                <p>Bitte eine Nachricht eingeben.</p>
              </div>
            )} 
          </div>
        </div>*/}
      </div>
    );
  }
}

export default Contact;
