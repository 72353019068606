import React, { Component } from "react";

import grownGraph from "../../../images/grown_graph2.png";
import fallenGraph from "../../../images/down_graph.png";
import startScreen from "../../../images/startScreen.png";
import webSite from "../../../images/website.png";
import digital from "../../../images/responsive.png";

export class ForRestaurants extends Component {
  render() {
    if (this.props.visible) {
      return (
        <div className="services row">
          <div className="single-advantage col-12 col-md-12 col-l-4 col-xl-4">
            <div className="advantage-rest-image-group">
              <img src={grownGraph} alt="steigender Graph-Icon" />
            </div>
            <p className="advantage-headtext">Umsatzsteigerung</p>
            <p>
              Bequem erreichbar für alle Leute in deiner Region. Erhalte die
              Bestellungen direkt vom Nutzer - schwarz auf weiß. Erspare dir
              langwierige Bestellungen am Telefon, welche oft mit Fehlern
              behaftet sind.
                </p>
          </div>
          <div className="single-advantage col-12 col-md-12 col-l-4 col-xl-4">
            <div className="advantage-rest-image-group">
              <img src={fallenGraph} alt="fallender Graph-Icon" />
            </div>
            <p className="advantage-headtext">
              Reduziere Fehler &amp; Aufwand
                </p>
            <p>
              Du machst die Regeln, die Nutzer profitieren. Nenne uns deine
              Serviceleistungen, wir kümmern uns um den Rest. Über doiApp
              direkt zum Kunden - die höchstmögliche Servicequalität.
                </p>
          </div>
          <div className="single-advantage col-12 col-md-12 col-l-4 col-xl-4">
            <div className="advantage-image-group">
              <img src={digital} alt="Gruppen-Bestellung-Icon" />
            </div>
            <p className="advantage-headtext">Digital gerüstet</p>
            <p>
              Nur wer mit der Digitalisierung geht hat Zukunft - das gilt
              auch für ländliche Gastronomie. Wappne deinen Service digital
              für eine erfolgreiche Zukunft.
                </p>
          </div>
          {/* <div className="smartphone col-xl-4 col-12">
              <img
                src={startScreen}
                alt="Einblick in die App: der Benutzerbildschirm zu erstellen eigener Gerichte"
              />
            </div> */}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ForRestaurants;
