import { combineReducers } from "redux";
import restaurant from "./basicInformations";
import basicInformation from "./information";
import { toggle } from "./toggler";
import { singledish } from "./single-dish";
import { checkout } from "./checkout";
import border from "./border";
import checkoutModal from "./checkoutModal";
import { user } from "./userReducer";

const combinedReducers = combineReducers({
  basicInformation,
  toggle,
  restaurant,
  singledish,
  checkout,
  border,
  checkoutModal,
  user
});

export default combinedReducers;
