import React from "react";
import { useDispatch } from "react-redux";
import {
  increment,
  decrement,
  removeCheckOutItem
} from "../../../actions/cart-actions";

const renderInfo = arr => {
  let newArray = arr.filter(item => item !== "");
  if (newArray) {
    return newArray.join(", ");
  }
};

export default function CheckOutItem(props) {
  const { size, item } = props;
  const dispatch = useDispatch();

  return (
    <div className="checkout-item-row">
      <div className="checkout-item-quantity">
        <button
          name="sub"
          className="change-quantity-in-checkout"
          onClick={() => {
            if (item.quantity > 1) {
              dispatch(decrement([item, 1]));
            }
          }}
        >
          -
        </button>
        <p>{item.quantity}x</p>
        <button
          name="add"
          className="change-quantity-in-checkout"
          onClick={() => dispatch(increment(item))}
        >
          +
        </button>
      </div>
      <div className="checkout-item-main-info">
        <p className="checkout-item-name">
          {item.name} {size}
        </p>
        <p className="checkout-item-info">
          {renderInfo([item.toppings, item.sauce, item.garnish, item.salad])}
        </p>
      </div>
      <div className="checkout-item-price">
        <p>{(item.price * item.quantity).toFixed(2).replace(".", ",")}€</p>
      </div>
      <div className="checkout-item-remove">
        <button
          onClick={() => {
            dispatch(decrement([item, item.quantity]));
            dispatch(removeCheckOutItem(item));
          }}
        >
          x
        </button>
      </div>
    </div>
  );
}
