const initialState = {
  dish: null,
  showModal: false
};

export const singledish = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DISH":
      delete action.payload.__typename;
      return {
        ...state,
        dish: {
          ...action.payload,
          choosenToppings: action.payload.toppings
        },
        showModal: false
      };
    case "EDIT_DISH":
      return {
        ...state,
        dish: {
          ...state.dish,
          choosenToppings: action.payload
        }
      };
    case "RESET_TOPPINGS":
      return {
        ...state,
        dish: {
          ...state.dish,
          choosenToppings: action.payload.toppings
        }
      };
    case "SET_MODAL":
      return {
        ...state,
        showModal: action.payload
      };
    default:
      return state;
  }
};
