export const increment = nr => {
  return {
    type: "INCREMENT",
    payload: nr
  };
};

export const decrement = () => {
  return {
    type: "DECREMENT"
  };
};

export const toggling = () => {
  return {
    type: "LANDINGPAGE"
  };
};

export const setBorders = border => {
  return {
    type: "SET_BORDERS",
    payload: border
  };
};

export const toggleCheckoutModal = bool => {
  return {
    type: "TOGGLE_CHECKOUT_MODAL",
    payload: bool
  };
};
