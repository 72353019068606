import React, { Component } from "react";

import CheckDishProperties from "./CheckDishProperties";

export class CreatedDish extends Component {
  state = {
    toppings: false,
    sauces: false,
    garnishes: false,
    salads: false,
    prefactoredToppings: false,
    prefactoredSauces: false,
    prefactoredGarnishes: false,
    prefactoredSalads: false,
    choosableToppings: true,
    choosableSauces: true,
    choosableGarnishes: true,
    choosableSalads: false,
    maxAddToppings: true,
    maxAddableToppings: null
  };

  changeState = e => {
    let bool = Boolean(e.target.value);
    this.setState({
      [e.target.name]: bool
    });
  };

  render() {
    const { number, catNumber } = this.props;
    const {
      toppings,
      sauces,
      garnishes,
      salads,
      choosableToppings,
      choosableSauces,
      choosableGarnishes,
      choosableSalads,
      maxAddToppings,
      prefactoredToppings,
      prefactoredGarnishes,
      prefactoredSalads,
      prefactoredSauces
    } = this.state;
    return (
      <React.Fragment>
        <hr />
        <div className="single-dish">
          <h2>Gericht {number}</h2>
          <div className="single-category-block">
            <label
              htmlFor={`catno-${catNumber}-dish-name-${number}`}
              className="col-6"
            >
              Gericht-Name
            </label>
            <input
              type="text"
              id={`catno-${catNumber}-dish-name-${number}`}
              name={`catno-${catNumber}-dish-name-${number}`}
              className="col-6"
            />
          </div>
          <div className="single-category-block">
            <label
              htmlFor={`catno-${catNumber}-dish-shortinfo-${number}`}
              className="col-6"
            >
              Gericht-Kurzinfo
            </label>
            <input
              type="text"
              id={`catno-${catNumber}-dish-shortinfo-${number}`}
              name={`catno-${catNumber}-dish-shortinfo-${number}`}
              className="col-6"
            />
          </div>
          <CheckDishProperties
            name="Kann man bei dem Gericht Zutaten ('Toppings') auswählen?"
            example="Beispiel: Bei einer Pizza in der Linde, kann man
                        bestimmen, welche Toppings drauf sollen, ebenso bei Krustis und Salaten..."
            number={number}
            catNumber={catNumber}
            topicString={"toppings"}
            prefactored={prefactoredToppings}
            topic={toppings}
            choosableTopicsString={"choosableToppings"}
            choosableTopics={choosableToppings}
            maxAddToppingsString={"maxAddToppings"}
            maxAddToppings={maxAddToppings}
            changeState={this.changeState}
            setAddableToppings={this.setAddableToppings}
          />
          <CheckDishProperties
            name="Kann man bei dem Gericht eine Soße auswählen?"
            example="Beispiel: Bei Nudelgerichten in der Linde kann man aus 4 verschiedenen Soßen wählen..."
            number={number}
            catNumber={catNumber}
            topicString={"sauces"}
            prefactored={prefactoredSauces}
            topic={sauces}
            choosableTopicsString={"choosableSauces"}
            choosableTopics={choosableSauces}
            changeState={this.changeState}
          />
          <CheckDishProperties
            name="Kann man bei dem Gericht Beilagen auswählen?"
            example="Beispiel: Bei Fleischgerichten in der Linde kann man zwischen Pommes, Kroketten und Djuwetschreis wählen..."
            number={number}
            catNumber={catNumber}
            topicString={"garnishes"}
            prefactored={prefactoredGarnishes}
            topic={garnishes}
            choosableTopicsString={"choosableGarnishes"}
            choosableTopics={choosableGarnishes}
            changeState={this.changeState}
          />
          <CheckDishProperties
            name="Kann man bei dem Gericht einen Beilagensalat auswählen/entfernen?"
            example="Beispiel: Bei Fleischgerichten in der Linde kann man den Beilagensalat zu und abwählen..."
            number={number}
            catNumber={catNumber}
            topicString={"salads"}
            prefactored={prefactoredSalads}
            topic={salads}
            choosableTopicsString={"choosableSalads"}
            choosableTopics={choosableSalads}
            changeState={this.changeState}
          />
          <div className="single-category-block">
            <label htmlFor={`dish-image-${number}`} className="col-6">
              Kategorie-Bild
            </label>
            <input
              type="text"
              id={`dish-image-${number}`}
              name={`dish-image-${number}`}
              className="col-6"
            />
          </div>
          <h3>Gerichts-Liste</h3>
          <p className="label-info">
            Bitte füge die verschiedenen Gerichte nach den Angeboten des
            Restaurants ein. <br />
          </p>
          <p className="label-example">
            Beispiel: Die Gaststätte zur Linde hat folgende Gerichte in der
            Kategorie Pizza: <br />
            Margherita
            <br />
            Salami
            <br />
            Schinken
            <br />
            San Martino
            <br />
            ...
          </p>
          {/* <div className="mx-auto">
            {this.renderDishes()}
            <button
                className="cat-btn col-6"
                name="add"
                onClick={this.addDishes}
            >
                + Gericht
            </button>
            <button
                className="cat-btn col-6"
                name="delete"
                onClick={this.addDishes}
            >
                - Gericht
            </button>
        </div> */}
        </div>
        <hr />
      </React.Fragment>
    );
  }
}

export default CreatedDish;
