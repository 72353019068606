import React, { Component } from "react";
import ChoosenToppings from "./ChoosenToppings";
import InfoSingleDish from "./InfoSingleDish";

export class SingleDish extends Component {
  constructor(props) {
    super(props);
    this.number = React.createRef();
    this.changeGarnishToReset = React.createRef();
    this.changeSaladToReset = React.createRef();
    this.changeSauceToReset = React.createRef();
    this.changePricing = React.createRef();
  }

  state = {
    toppings: [],
    sauceValue: { name: "", price: 0 },
    garnishValue: { name: "", price: 0 },
    saladValue: { name: "", price: 0 },
    currSize: "",
    currPrice: this.props.singledish.pricing[0].price,
    currNumber: 1,
    changes: [],
    openEditView: false,
    succesfullAdded: false,
    addedItems: null,
    openCart: false
  };

  addToCart = toppings => {
    /* if (this.context.cartContent === 0) {
      this.props.deleteCart();
    } */
    const { singledish } = this.props;
    let {
      currPrice,
      currSize,
      sauceValue,
      garnishValue,
      saladValue
    } = this.state;

    /* this.context.incrCC(this.number.current.value); */
    let curSize = currSize;
    if (curSize === "") {
      if (!singledish.pricing[0].name) {
        curSize = "Normal";
      } else {
        curSize = singledish.pricing[0].name;
      }
    }
    let toppingNames = [];
    toppings.forEach(topping => {
      topping.doubled
        ? toppingNames.push("Doppelt " + topping.name)
        : toppingNames.push(topping.name);
    });
    let toppingList = toppingNames.join(", ");
    let onTop = sauceValue.price + garnishValue.price + saladValue.price;
    // ÄNDERUNGEN DER TOPPINGS FÜR CUSTOMIZED AUSDRUCKE
    // Array, worin die Änderungen zwischengespeichert werden
    let changes = [];
    // Die Iteration über alle Items, welche das Gericht ursprünglich bereit hält.
    for (let topping of singledish.toppings) {
      // Wenn eine Zutat gefunden wird...
      let founded = toppings.find(topp => topping.name === topp.name);
      // ...und Sie nicht undefined ist, also vorhanden ist...
      if (typeof founded !== "undefined") {
        // ...muss überprüft werden, ob Sie verdoppelt wurde, andernfalls gab es keine Veränderung.
        if (founded.doubled) {
          changes.push(`Doppelt ${founded.name}`);
        }
      }
      // Wurde eine Zutat nicht in der Liste gefunden, ist also "undefined", wurde es entfernt
      else {
        changes.push(`ohne ${topping.name}`);
      }
    }

    // Die Iteration über die Zutaten der neuen Topping-Liste...
    for (let topping of toppings) {
      // Zunächst wird jedes Topping der neuen Liste innerhalb der alten Topping-Liste gesucht...
      let founded = singledish.toppings.find(
        topp => topping.name === topp.name
      );
      // ...wenn nichts gefunden wurde, der Typ also "undefined" ist...
      if (typeof founded == "undefined") {
        // ...muss gecheckt werden, ob die neue Zutat doppelt hinzugefügt wurde oder nicht.
        if (topping.doubled) {
          changes.push(`+ Doppelt ${topping.name}`);
        } else {
          changes.push(`+ ${topping.name}`);
        }
      }
    }
    sauceValue = this.objectify(sauceValue, "availableSauces");
    garnishValue = this.objectify(garnishValue, "availableGarnishes");
    saladValue = this.objectify(saladValue, "availableSalads");
    if (toppings[0].price) {
      let sizing = currSize;
      if (currSize === "") {
        sizing = singledish.pricing[0].name;
      }
      let obj = singledish.pricing.find(o => o.name === sizing);
      for (let i = 0; i < toppings.length; i++) {
        if (typeof singledish.toppings[i] !== "undefined") {
          onTop -= singledish.toppings[i].price * obj.factor;
        }
        if (toppings[i].doubled) {
          onTop += toppings[i].price * 2 * obj.factor;
        } else {
          onTop += toppings[i].price * obj.factor;
        }
      }
    }
    let cartItem = {
      name: singledish.name,
      toppings: toppingList,
      sauce: sauceValue.name,
      garnish: garnishValue.name,
      salad: saladValue.name,
      size: curSize,
      price: currPrice + onTop,
      quantity: this.state.currNumber,
      changes
    };
    this.showSuccessfulAdded(true, cartItem);
    this.props.setCart(cartItem);
    this.setState({ currNumber: 1 });
    this.defaultToppings();
  };

  changeCurrNumber = value => {
    if (value === "-") {
      if (this.state.currNumber === 1) return;
      else this.setState({ currNumber: this.state.currNumber - 1 });
    } else {
      this.setState({ currNumber: this.state.currNumber + 1 });
    }
  };

  /*   changeToppings = (item, tops) => {
    const { singledish } = this.props;
    let newToppings = [...tops],
      toppingLength = singledish.toppings.length,
      fullLength = this.checkLength(newToppings);
    for (let i = 0; i < newToppings.length; i++) {
      if (item.name === newToppings[i].name) {
        if (!item.doubled) {
          if (fullLength >= 1) {
            newToppings.splice(newToppings.indexOf(newToppings[i]), 1);
          }
          if (
            !singledish.editable.howMuchMaxToppings ||
            toppingLength > fullLength
          ) {
            newToppings.push({
              name: item.name,
              price: item.price,
              doubled: true
            });
          }
          return this.setState({ toppings: newToppings });
        } else {
          if (newToppings.length > 1) {
            newToppings.splice(newToppings.indexOf(newToppings[i]), 1);
            return this.setState({ toppings: newToppings });
          } else {
            newToppings.splice(newToppings.indexOf(newToppings[i]), 1);
            newToppings.push({
              name: item.name,
              price: item.price,
              doubled: false
            });
            return this.setState({ toppings: newToppings });
          }
        }
      }
    }
    if (!singledish.editable.howMuchMaxToppings || toppingLength > fullLength) {
      newToppings.push({
        name: item.name,
        price: item.price,
        doubled: false
      });
    }
    this.checkLength(newToppings);
    this.setState({ toppings: newToppings });
  }; */

  /*  checkLength = toppings => {
    let length = 0;
    for (let i = 0; i < toppings.length; i++) {
      if (toppings[i].doubled) {
        length += 2;
      } else {
        length += 1;
      }
    }
    return length;
  }; */

  defaultToppings = () => {
    this.setState((state, props) => {
      return {
        toppings: [],
        currNumber: 1
      };
    });
  };

  handleSucessfullMsg = (msg, fromWhere) => {
    if (msg === "backToDish") {
      this.setState({
        succesfullAdded: false,
        addedItems: null,
        openCart: false
      });
    } else if (msg === "showMeals") {
      this.setState({
        succesfullAdded: false,
        addedItems: null,
        openEditView: false,
        openCart: false
      });
    } else if (msg === "showCart") {
      this.setState({
        succesfullAdded: false,
        addedItems: null,
        openEditView: true,
        openCart: true,
        fromWhere
      });
    }
  };

  objectify = (key, value) => {
    const { singledish } = this.props;
    if (key.name === "") {
      if (singledish[value][0].name) {
        return singledish[value][0];
      } else {
        return { name: "", price: 0 };
      }
    } else {
      return key;
    }
  };

  openEditView = () => {
    this.setState({ openEditView: !this.state.openEditView });
  };

  openCheckOut = () => {
    this.setState({ openCart: true });
  };

  renderValue = (value, stateComponent) => {
    if (this.state[stateComponent] !== value) {
      this.setState({ [stateComponent]: value });
    }
  };

  returnCurrPrice = (currentPrice, size) => {
    if (size === "") {
      if (
        typeof this.props.singledish.pricing[0] !== "undefined" &&
        typeof this.props.singledish.pricing[0].name !== "undefined"
      ) {
        if (this.state.currSize !== size) {
          this.setState({
            currSize: this.props.singledish.pricing[0].name
          });
        }
      } else {
        if (this.state.currSize !== size) {
          this.setState({ currSize: "Normal" });
        }
      }
    } else {
      if (this.state.currSize !== size) {
        this.setState({ currSize: size });
      }
    }
    if (this.state.currPrice !== currentPrice) {
      this.setState({ currPrice: currentPrice });
    }
  };

  showSuccessfulAdded = (bool, obj) => {
    this.setState({ succesfullAdded: bool, addedItems: obj });
  };

  render() {
    let { singledish } = this.props;
    return (
      <div className="new-cart-single-dish">
        <div className="name-and-price">
          <p>{singledish.name}</p>
          <p>{singledish.pricing[0].price.toFixed(2).replace(".", ",")}€</p>
        </div>
        <div>
          <ChoosenToppings toppings={singledish.toppings} />
          <InfoSingleDish info={singledish.info} />
        </div>
      </div>
    );
  }
}

export default SingleDish;
