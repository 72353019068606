import React, { Component } from 'react';

import MenuSchema from './MenuSchema';

export class SauceComponent extends Component {
    state = {
        choosableSauces: 1
    };

    renderSauces = () => {
        let choosableSauces = [];
        for (let i = 1; i <= this.state.choosableSauces; i++) {
            choosableSauces.push(
                <MenuSchema
                    key={i}
                    number={i}
                    name={`Wählbare Soße ${i}`}
                    info="choosableSauces"
                    doubleState="doubleable"
                    doubleState_string="Verdoppelbar?"
                    dishNumber={this.props.number}
                    catNumber={this.props.catNumber}
                />
            );
        }
        return choosableSauces;
    };

    addSauces = e => {
        e.preventDefault();
        if (e.target.name === 'add') {
            this.setState({
                choosableSauces: this.state.choosableSauces + 1
            });
        } else {
            if (this.state.choosableSauces > 0) {
                this.setState({
                    choosableSauces: this.state.choosableSauces - 1
                });
            }
        }
    };

    render() {
        const {
            number,
            catNumber,
            choosableTopicsString,
            choosableTopics,
            //prefactored,
            changeState
        } = this.props;
        return (
            <React.Fragment>
                <div className="dish-propertie-check">
                    <p className="weekday_true_false">
                        Sollen die vorgefertigten Soßen genutzt werden?
                    </p>
                    <input
                        type="radio"
                        id={`catno-${catNumber}-dishno-${number}-choosableSaucesPrefactored-true`}
                        name={choosableTopicsString}
                        value="true"
                        onChange={changeState}
                        defaultChecked
                    />
                    <label
                        className="label_true_false"
                        htmlFor={`catno-${catNumber}-dishno-${number}-choosableSaucesPrefactored-true`}
                    >
                        {' '}
                        Ja
                    </label>
                    <input
                        type="radio"
                        id={`catno-${catNumber}-dishno-${number}-choosableSaucesPrefactored-false`}
                        name={choosableTopicsString}
                        onChange={changeState}
                        value=""
                    />
                    <label
                        className="label_true_false"
                        htmlFor={`catno-${catNumber}-dishno-${number}-choosableSaucesPrefactored-false`}
                    >
                        {' '}
                        Nein
                    </label>
                    {!choosableTopics && (
                        <div className="dish-propertie-check">
                            <div className="mx-auto">
                                {this.renderSauces()}
                                <button
                                    className="offset-3 dish-btn col-3"
                                    name="add"
                                    onClick={this.addSauces}
                                >
                                    + Beilage
                                </button>
                                <button
                                    className="cat-btn col-3"
                                    name="delete"
                                    onClick={this.addSauces}
                                >
                                    - Beilage
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default SauceComponent;
