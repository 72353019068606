const initialState = {
  h: 0,
  w: 0
};

const borderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_BORDERS":
      return {
        h: action.payload.h,
        w: action.payload.w
      };
    default:
      return state;
  }
};

export default borderReducer;
