import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap-4-grid/css/grid.min.css";
import Index from "./components/Index";
import { restaurantStore } from "./restaurantStore";
import { Provider } from "react-redux";

ReactDOM.render(
  <Provider store={restaurantStore}>
    <Index />
  </Provider>,
  document.getElementById("root")
);
