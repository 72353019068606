import React, { Component } from "react";
import "./footer.css";
import { IoMdHeartEmpty } from "react-icons/io";

export class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <ul>
          <a className="footer-link" href="/agb">
            <li>AGB</li>
          </a>
          <a className="footer-link" href="/datenschutz">
            <li>Datenschutz</li>
          </a>
          <a className="footer-link" href="/impressum">
            <li>Impressum</li>
          </a>
        </ul>
        <div className="bottenhorn">
          Made with{"  "}
          <p className="heart_icon">
            <IoMdHeartEmpty />
          </p>
          {"  "}
          in Bottenhorn
        </div>
      </div>
    );
  }
}

export default Footer;
