import React, { Component } from "react";
import "./foruser.css";
import pizzaIcon from "../../../images/pizza.png";
import burgerIcon from "../../../images/burger.png";
import friesIcon from "../../../images/fried-potatoes.png";
import ownDishesIcon from "../../../images/owndishes.png";
import editableDishes from "../../../images/editDish.png";
import groupIcon from "../../../images/grouporder.png";
import userIcon from "../../../images/user.png";

export class ForUser extends Component {
  render() {
    if (this.props.visible) {
      return (
        <div className="for-user">
          <div className="advantages">
            <div className="advantage-group col-xl-4 col-12">
              <div className="single-advantage">
                <div className="advantage-image-group">
                  <img src={pizzaIcon} alt="Pizza-Icon" />
                  <img src={burgerIcon} alt="Burger-Icon" />
                  <img src={friesIcon} alt="Pommes-Icon" />
                </div>
                <p className="advantage-headtext">Dein Lieblingsessen</p>
                <p className="advantage-subtext">
                  Such dir dein Lieblingsessen aus den Restaurants in deiner
                  Nähe aus. Profititere vom jeweiligen Service der Restaurants
                  in deiner Umgebung.
                </p>
              </div>
              <div className="single-advantage">
                <div className="advantage-image-group">
                  <img
                    src={ownDishesIcon}
                    alt="Gerichte-selbst-erstellen-Icon"
                  />
                </div>
                <p className="advantage-headtext">Deine Gerichte</p>
                <p className="advantage-subtext">
                  Bei doiGastro ist es kein Problem, nach den Vorgaben der
                  Restaurants eigene Gerichte zu erstellen. Ganz so, als wärst
                  du vor Ort.
                </p>
              </div>
            </div>
            <div className="smartphone col-xl-4 col-12">
              <img
                src={editableDishes}
                alt="Einblick in die App: der Benutzerbildschirm zu erstellen eigener Gerichte"
              />
            </div>
            <div className="advantage-group col-xl-4 col-12">
              <div className="single-advantage">
                <div className="advantage-image-group">
                  <img src={userIcon} alt="Benutzer-Account-Icon" />
                </div>
                <p className="advantage-headtext">Dein Account</p>
                <p className="advantage-subtext">
                  Registriere dich jetzt, erstelle &amp; speichere deine
                  Gerichte, wähle deine Lieblingsrestaurants und hol dir diese
                  direkt ins Wohnzimmer.
                </p>
              </div>
              <div className="single-advantage">
                <div className="advantage-image-group">
                  <img src={groupIcon} alt="Gruppen-Bestellung-Icon" />
                </div>
                <p className="advantage-headtext">Zusammen schmecken lassen</p>
                <p className="advantage-subtext">
                  Eröffne eine neue Bestellung, lade deine Freunde dazu ein und
                  bearbeitet diese gemeinsam &amp; parallel. Ein abschließender
                  Klick und du bestellst!
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ForUser;
