import React, { Component } from "react";

export class WelcomeGastro extends Component {
  render() {
    let { partnerName, restaurantName } = this.props;
    document.body.style.overflow = "auto";
    return (
      <div className="welcome-gastro">
        <h2 className="gastro-restaurant-name">
          Herzlich Willkommen, {partnerName}!
        </h2>
        <p className="welcome-gastro-partner">{restaurantName}</p>
      </div>
    );
  }
}

export default WelcomeGastro;
