import React, { Component } from 'react';

export class CategoryPreFactoring extends Component {
    render() {
        const {
            number,
            word,
            changePreState,
            prefabricatedState,
            renderFunction,
            addChoosable,
            prefabricatedWord
        } = this.props;
        return (
            <React.Fragment>
                <div className="dish-propertie-check">
                    <p className="weekday_true_false">
                        Soll für diese Kategorie eine vorgefertigte {word}
                        erstellt werden?
                    </p>
                    <p className="label-example">
                        Falls für die Kategorie eine allgemeingültige
                        {word} bestehen kann, welche nicht individuell pro
                        Gericht verändert werden muss, kann dies hier
                        eingetragen werden.
                    </p>
                    <input
                        type="radio"
                        id={`${prefabricatedWord[0]}-catno${number}-true`}
                        name={prefabricatedWord[0]}
                        value="true"
                        onChange={changePreState}
                    />
                    <label
                        className="label_true_false"
                        htmlFor={`${prefabricatedWord[0]}-catno${number}-true`}
                    >
                        {' '}
                        Ja
                    </label>
                    <input
                        type="radio"
                        id={`${prefabricatedWord[0]}-catno${number}-false`}
                        name={prefabricatedWord[0]}
                        onChange={changePreState}
                        value=""
                        defaultChecked
                    />
                    <label
                        className="label_true_false"
                        htmlFor={`${prefabricatedWord[0]}-catno${number}-false`}
                    >
                        {' '}
                        Nein
                    </label>
                    <br />
                </div>
                {prefabricatedState && (
                    <div className="dish-propertie-check">
                        <div className="mx-auto">
                            {renderFunction}
                            <button
                                className="offset-3 dish-btn col-3"
                                id={prefabricatedWord[1]}
                                name="add"
                                onClick={addChoosable}
                            >
                                +
                            </button>
                            <button
                                className="cat-btn col-3"
                                id={prefabricatedWord[1]}
                                name="delete"
                                onClick={addChoosable}
                            >
                                -
                            </button>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default CategoryPreFactoring;
