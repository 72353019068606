import React, { Component } from "react";
import { connect } from "react-redux";
import { logInStore } from "../actions/user";
import "../index.css";
import App from "./landing_page/App";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import ApolloClient from "apollo-client";
import { ApolloProvider, Query } from "react-apollo";
import { InMemoryCache } from "apollo-boost";
import { WebSocketLink } from "apollo-link-ws";
import { split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { getMainDefinition } from "apollo-utilities";

import { showAllRestaurants } from "./queries";

import Navbar from "./navigation/Navbar";
import { ClimbingBoxLoader } from "react-spinners";
import GastroSite from "./gastro_site/GastroSite";
/* import ZurLinde from "./restaurants/ZurLinde"; */
import RestaurantPage from "./restaurant_page/RestaurantPage";
/* import DaCrepanni from "./restaurants/DaCrepanni";
import RudisDoener from "./restaurants/RudisDoener";
import Olympus from "./restaurants/Olympus"; */
import InsertRestaurant from "./insertRestaurantsADMIN/InsertRestaurant";
import AuthContext from "../context/auth-context";
import User from "./reusable/User";
import ActivationComponent from "./onepager/ActivationComponent";
import ResetComponent from "./onepager/ResetComponent";
import AGB from "./footer/AGB";
import Impressum from "./footer/Impressum";
import Datenschutz from "./footer/Datenschutz";
import Footer from "./footer/Footer";
import InsertNew from "./insertRestaurantsADMIN/InsertNew";

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_URL}`,
});

const link = split(({ query }) => {
  const { kind, operation } = getMainDefinition(query);
  return kind === "OperationDefinition" && operation === "subscription";
}, httpLink);

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

class Index extends Component {
  state = {
    data: [{ url: "" }],
    loaded: false,
    token: null,
    userId: null,
    gastroState: null,
    cartContent: 0,
    restaurantNavbar: false,
    showHome: true,
  };

  componentDidMount() {
    const rehydrate = JSON.parse(localStorage.getItem("logInState"));
    if (rehydrate && rehydrate.userId && !rehydrate.gastroState) {
      fetch(`${process.env.REACT_APP_URL}`, {
        method: "POST",
        body: JSON.stringify({
          query: `
            {
              getUserById(_id: "${rehydrate.userId}") {
                firstName
                lastName
                email
                phone
                adress {
                  city
                  zip
                  street
                  houseNr
                }
              }
            }
                    `,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((resData) => {
          if (resData.hasOwnProperty("errors")) {
            return null;
          } else {
            this.props.logInStore(resData.data.getUserById);
          }
          this.setState(rehydrate);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState(rehydrate);
    }
  }

  componentWillUnmount() {
    localStorage(
      "logInState",
      JSON.stringify({
        token: this.state.token,
        userId: this.state.userId,
        gastroState: this.state.gastroState,
        cartContent: this.state.cartContent,
      })
    );
  }

  incrCC = (numb) => {
    this.setState(
      { cartContent: parseInt(this.state.cartContent) + parseInt(numb) },
      () => {
        localStorage.setItem(
          "logInState",
          JSON.stringify({
            token: this.state.token,
            userId: this.state.userId,
            gastroState: this.state.gastroState,
            cartContent: this.state.cartContent,
          })
        );
      }
    );
  };

  decrCC = (numb) => {
    this.setState(
      {
        cartContent: parseInt(this.state.cartContent) - parseInt(numb),
      },
      () => {
        localStorage.setItem(
          "logInState",
          JSON.stringify({
            token: this.state.token,
            userId: this.state.userId,
            gastroState: this.state.gastroState,
            cartContent: this.state.cartContent,
          })
        );
      }
    );
  };

  resetCC = () => {
    this.setState({ cartContent: 0 }, () => {
      localStorage.setItem("logInState", JSON.stringify({ cartContent: 0 }));
    });
  };

  login = (userId, token, tokenExpiration, gastro) => {
    this.setState({ token, userId, gastroState: gastro }, () => {
      localStorage.setItem(
        "logInState",
        JSON.stringify({
          token: this.state.token,
          userId: this.state.userId,
          gastroState: this.state.gastroState,
          cartContent: this.state.cartContent,
        })
      );
    });
  };

  logout = () => {
    this.setState({ token: null, userId: null, gastroState: false }, () => {
      localStorage.setItem(
        "logInState",
        JSON.stringify({
          token: null,
          userId: null,
          gastroState: false,
          cartContent: this.state.cartContent,
        })
      );
    });
  };

  showCart = () => {
    return true;
  };

  toggleComponents = (newState) => {
    this.setState((state, props) => {
      return {
        showHome: newState,
      };
    });
  };

  render() {
    return (
      <div id="root-div">
        <AuthContext.Provider
          value={{
            token: this.state.token,
            userId: this.state.userId,
            cartContent: this.state.cartContent,
            decrCC: this.decrCC,
            incrCC: this.incrCC,
            login: this.login,
            logout: this.logout,
            showCart: this.showCart,
            resetCC: this.resetCC,
          }}
        >
          <Navbar
            gastroSite={this.state.gastroState}
            restaurantNavbar={this.state.restaurantNavbar}
            cartContent={this.state.cartContent}
            toggleComponents={(state) => this.toggleComponents(state)}
          />
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={App} />
              <Route
                path="/activation/:token"
                component={ActivationComponent}
              />
              <Route path="/reset/:token" component={ResetComponent} />
              {/* 
                        <Route
                          exact
                          path="/jaluseadmin"
                          component={InsertNew}
                        /> */}
              <Route path="/agb" component={AGB} exact />
              <Route path="/datenschutz" component={Datenschutz} exact />
              <Route path="/impressum" component={Impressum} exact />
              {/* {data.showAllRestaurants.map((restaurant, i) => {
                          let urlCheck = window.location.href;
                          if (
                            urlCheck.replace("http://localhost:3001/", "") ===
                            restaurant.url ||
                            urlCheck.replace("https://doigastro.de/", "") ===
                            restaurant.url
                          ) {
                            if (this.state.restaurantNavbar !== true) {
                              this.setState({ restaurantNavbar: true });
                            }
                          }
                          return (
                            <Route
                              key={i}
                              path={`/${restaurant.url}`}
                              render={(matchProps) =>
                                this.state.gastroState ? (
                                  <Redirect
                                    from={`/${restaurant.url}`}
                                    to="/gastro"
                                  />
                                ) : (
                                    <RestaurantPage
                                      {...matchProps}
                                      restaurant={restaurant}
                                    />
                                  )
                              }
                            />
                          );
                        })} */}
            </Switch>
          </BrowserRouter>
        </AuthContext.Provider>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { logInStore })(Index);
